import { axios } from "../../shared/axios";
import { getDataFromStore } from "../../store/getStore"
const errorMessage = "Something went wrong. Please try again";


export const getToken = () => {

    let auth = getDataFromStore("Auth");

    if (auth.authDetails.token) {
        return auth.authDetails.token.access_token
    }
    else {
        return null;
    }
};

export const apiFunction = async (url, method, postData, token, extraConfig) => {

    let config = {
        method: method,
        url: url,
        data: postData ? postData : {},
        headers: { institutionId: process.env.REACT_APP_INSTITUTION_ID }
    };
    ;
    let data;
    if (token) {
        let token = getToken();

        config = {
            ...config,
            headers: {
                Authorization: `Bearer ${token}`,
                institutionId: process.env.REACT_APP_INSTITUTION_ID
            }
        };
    }

    if (extraConfig == "blob") {
        config = {
            ...config,
            responseType: 'blob',
        }
    }

    if (extraConfig == "multipart") {
        config.headers = {
            ...config.headers,
            "content-type": "multipart/form-data",
        }
    }


    await axios({ ...config })
        .then((res) => {
            ;
            if (extraConfig == "blob") {
                data = res.data
            }
            else {
                if (res.data) {
                    data = {
                        data: res.data,
                        status: true,
                        message: "Success",
                    };
                } else {
                    data = {
                        status: true,
                        message: "Success",
                    };
                }
            }
        })
        .catch((err) => {
            ;
            ;
            data = {
                ...err.response.data,
                status: false,
            };
        });
    ;
    return data;
};

export const postFormDataAPI = async (url, postData, section) => {
    let token = getToken(section);
    let data;
    const config = {
        headers: {
            "content-type": "multipart/form-data",
            token: `${token}`,
            institutionId: process.env.REACT_APP_INSTITUTION_ID
        },
    };
    await axios
        .post(url, postData, config)
        .then((res) => {
            data = res.data;
        })

        .catch((err) => {
            ;
            ;
            data = {
                ...err.response.data,
                status: false,
            };
        });
    return data;
};
