export const GET_BOARDINGS = '/admin/boarding';
export const GET_DASHBOARD = '/admin/boarding/dashboard';
export const ADD_COMMENT = '/admin/boarding/add-comment';
export const GET_BOARDING_DETAILS = '/admin/boarding/';
export const MOVE_BOARDING_FORM = '/admin/boarding/move';
export const UPDATE_BOARDING_FORM = '/admin/boarding/update';
export const GET_BOARDING_STEPS = '/admin/boarding/steps/get';
export const BOARDING_LIST = '/admin/boarding/list';
export const SCHEDULE_MEETING = '/admin/boarding/schedule-meeting';
export const ACCEPT_CONTRACT = '/admin/boarding/accept-signed-contract';
export const MOVE_MULTIPLE_BOARDING_FORM = '/admin/boarding/move-multiple';
export const SEND_CONTRACT = '/admin/boarding/send-contract';
export const GET_SOP = '/admin/boarding/sop';
export const GET_MAPPING = '/admin/boarding/subject-mapping/';
export const REJECT_CONTRACT = '/admin/boarding/reject-signed-contract';
export const GET_BOARDING_QUESTIONS = '/boarding/boarding-question';
export const GET_BOARDING_STEPS_BY_TYPE = '/admin/boarding/steps-by-type/';
