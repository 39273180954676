import { createApi } from '@reduxjs/toolkit/query/react';
import * as API from '../../apiCalls/urls/setterUser';
import { baseQuery } from '../../apiCalls/rtkBaseQuery';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { uploadPaperMapping } from './services/uploadService';

export const setterUserApi = createApi({
    baseQuery,
    reducerPath: 'setterUserApi',
    tagTypes: ['syllabus', 'Paper', 'question', 'profile', 'certificate', 'mapping', 'Issues'],
    endpoints: (builder) => ({
        // Setter APIs for Answers
        getPaperSyllabusByPaperId: builder.query({
            query: (paperId) => ({
                url: `${API.GET_PAPER_SYLLABUS_BY_PAPER_ID}${paperId}`,
                method: 'GET',
            }),
            providesTags: ['syllabus'],
        }),

        createSyllabus: builder.mutation({
            query: (data) => ({
                url: API.CREATE_SYLLABUS,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['syllabus'],
        }),

        createSyllabus100: builder.mutation({
            query: (data) => ({
                url: API.CREATE_SYLLABUS_100,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['syllabus'],
        }),

        sendSyllabusForReview: builder.mutation({
            query: (paperId) => ({
                url: `${API.SEND_SYLLABUS_FOR_REVIEW}${paperId}`,
                method: 'POST',
            }),
            invalidatesTags: ['syllabus'],
        }),
        sendPaperForReview: builder.mutation({
            query: (paperId) => ({
                url: `${API.SEND_QUESTION_FOR_REVIEW}${paperId}`,
                method: 'POST',
            }),
            invalidatesTags: ['question', 'syllabus'],
        }),
        getQuestionMappingByPaperId: builder.query({
            query: (paperId) => ({
                url: `${API.GET_QUESTION_MAPPING_BY_PAPER_ID}${paperId}`,
                method: 'GET',
            }),
            providesTags: ['question', 'syllabus'],
        }),

        // Query to get mapping lists
        getMappingLists: builder.query({
            query: ({ sessionId, portionId }) => ({
                url: API.GET_MAPPING_LIST,
                params: sessionId ? { sessionId, portionId } : {},
            }),
            providesTags: ['mapping'],
        }),


        // Setter APIs for Questions
        uploadQuestionByPaperId: builder.mutation({
            query: ({ paperId, formData }) => ({
                url: `${API.UPLOAD_QUESTION_BY_PAPER_ID}${paperId}`,
                method: 'POST',
                body: formData,
            }),
            invalidatesTags: ['question', 'syllabus'],
        }),

        // for adding or updating question mapping
        uploadPaperMappingByPaperId: builder.mutation({
            query: ({ paperId, data }) => ({
                url: `${API.CREATE_PAPER_MAPPING_BY_PAPER_ID}${paperId}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['question', 'syllabus'],
        }),

        // for uploading question mapping csv (with custom upload)
        // uploadPaperMappingByPaperId: builder.mutation({
        //     queryFn: async ({ paperId, data }, _queryApi, _extraOptions, baseQuery) => {
        //         try {
        //             await uploadPaperMapping(paperId, data, _queryApi.dispatch); // Call custom upload function
        //             return { data: 'Upload successful' };
        //         } catch (error) {
        //             return { error: { status: 'CUSTOM_ERROR', error: error.message } };
        //         }
        //     },
        //     invalidatesTags: ['question'],
        // }),


        updateQuestionsByPaperId: builder.mutation({
            query: ({ paperId, data }) => ({
                url: `${API.UPDATE_QUESTIONS_BY_PAPER_ID}${paperId}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['question', 'syllabus'],
        }),

        uploadAnswerByPaperId: builder.mutation({
            query: ({ paperId, formData }) => ({
                url: `${API.UPLOAD_ANSWER_BY_PAPER_ID}${paperId}`,
                method: 'POST',
                body: formData,
            }),
            invalidatesTags: ['question'],
        }),

        deleteQuestionMappingById: builder.mutation({
            query: ({ mappingIds }) => ({
                url: `${API.DELETE_QUESTION_MAPPING_BY_ID}`,
                method: 'POST',
                body: mappingIds,
            }),
            // invalidatesTags: ['question'],
        }),



        getPaperDetailsByPaperId: builder.query({
            query: (paperId) => ({
                url: `${API.GET_PAPER_DETAILS_BY_PAPER_ID}${paperId}`,
                method: 'GET',
            }),
            providesTags: ['paper'],
        }),

        paperList: builder.query({
            query: ({ filters, page = 1, items = 10 }) => ({
                url: `${API.PAPER_LIST}?page=${page}&items=${items}`,
                method: 'POST',
                body: filters,
            }),
            providesTags: ['paper'],
            refetchOnMountOrArgChange: true,
            keepUnusedDataFor: 0
        }),
        getSelectedSyllabusByPaperId: builder.query({
            query: (paperId) => ({
                url: `${API.GET_SELECTED_SYLLABUS_BY_PAPER_ID}${paperId}`,
                method: 'GET',
            }),
        }),

        uploadQuestionHtmlByPaperId: builder.mutation({
            query: ({ paperId, html }) => ({
                url: `/setter/paper/upload-html-question/${paperId}`,
                method: 'POST',
                body: html,
            }),
            invalidatesTags: ['question', 'syllabus', 'paper'],
        }),

        uploadAnswerHtmlByPaperId: builder.mutation({
            query: ({ paperId, html }) => ({
                url: `${API.UPLOAD_ANSWER_HTML_BY_PAPER_ID}${paperId}`,
                method: 'POST',
                body: html,
            }),
            invalidatesTags: ['answer', 'syllabus', 'paper'],
        }),

        getAllIssues: builder.query({
            query: ({ paperId, data }) => ({
                url: `/setter/paper/issues/${paperId}`,
                method: 'POST',
                body: data,
            }),
            providesTags: ['Issues']
        }),

        closeIssue: builder.mutation({
            query: (data) => ({
                url: `/setter/paper/close-issue`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Issues']
        }),

        // Query to get counselor profile
        getSetterProfile: builder.query({
            query: () => ({
                url: API.SETTER_PROFILE,
                method: 'GET',
            }),
            providesTags: ['profile'],
        }),

        updateSocialLink: builder.mutation({
            query: (linkData) => ({
                url: API.UPDATE_SOCIAL_LINK,
                method: 'POST',
                body: linkData,
            }),
            invalidatesTags: ['profile'],
        }),

        updateSetterProfile: builder.mutation({
            query: (profileData) => ({
                url: API.UPDATE_SETTER_PROFILE,
                method: 'POST',
                body: profileData,
            }),
            invalidatesTags: ['profile'],
        }),

        // Query to get certificates list
        getCertificatesLists: builder.query({
            query: () => ({
                url: API.GET_CERTIFICATE_LIST,
                method: 'GET',
            }),
            providesTags: ['certificate'],
        }),

        // Query to get mapping lists
        // getMappingLists: builder.query({
        //     query: ({ sessionId }) => ({
        //         url: API.GET_MAPPING_LIST,
        //         params: sessionId ? { sessionId } : {},
        //     }),
        //     providesTags: ['mapping'],
        // }),

        // Mutation to get certificates link
        getCertificatesLink: builder.mutation({
            query: (certificate) => ({
                url: API.GET_CERTIFICATE_LINK,
                method: 'POST',
                body: certificate,
            }),
        }),
        // Mutation to add a certificate
        addCertificate: builder.mutation({
            query: (certificateData) => ({
                url: API.ADD_CERTIFICATE,
                method: 'POST',
                body: certificateData,
            }),
            invalidatesTags: ['certificate'],
        }),

        deleteCertificate: builder.mutation({
            query: (certificateId) => ({
                url: API.DELETE_CERTIFICATE,
                method: 'DELETE',
                body: certificateId
            }),
            invalidatesTags: ['certificate'],
        }),
        updateCertificate: builder.mutation({
            query: (newData) => ({
                url: API.UPDATE_CERTIFICATE,
                method: 'PUT',
                body: newData,
            }),
            invalidatesTags: ['certificate'],
        }),

        // Mutation to add education
        addEducation: builder.mutation({
            query: (educationData) => ({
                url: API.ADD_EDUCATION,
                method: 'POST',
                body: educationData,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to update education
        updateEducation: builder.mutation({
            query: (data) => ({
                url: API.UPDATE_EDUCATION,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to delete education
        deleteEducation: builder.mutation({
            query: (educationId) => ({
                url: API.DELETE_EDUCATION,
                method: 'DELETE',
                body: educationId,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to add experience
        addExperiance: builder.mutation({
            query: (data) => ({
                url: API.ADD_EXPERIENCE,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to update experience
        updateExperiance: builder.mutation({
            query: (data) => ({
                url: API.UPDATE_EXPERIENCE,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to delete experience
        deleteExperiance: builder.mutation({
            query: (expId) => ({
                url: API.DELETE_EXPERIENCE,
                method: 'DELETE',
                body: expId,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to add introductory video
        addIntroductoryVideo: builder.mutation({
            query: (file) => ({
                url: API.ADD_INTRODUCTORY_VIDEO,
                method: 'POST',
                body: file,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to delete introductory video
        deleteIntroductoryVideo: builder.mutation({
            query: (expId) => ({
                url: API.DELETE_INTRODUCTORY_VIDEO,
                method: 'DELETE',
                body: expId,
            }),
            invalidatesTags: ['profile'],
        }),
        createObjectivesByPaperId: builder.mutation({
            query: ({ paperId, data }) => ({
                url: `${API.CREATE_OBJECTIVE_BY_PAPER_ID}${paperId}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['answer', 'syllabus', 'paper'],
        }),
        getDashboard: builder.query({
            query: () => ({
                url: API.SETTER_DASHBOARD,
                method: 'POST',
            }),
        }),

    }),
});

export const {
    // Setter hooks
    useGetPaperSyllabusByPaperIdQuery,
    useCreateSyllabusMutation,
    useCreateSyllabus100Mutation,
    useSendSyllabusForReviewMutation,
    useUploadQuestionByPaperIdMutation,
    useUploadPaperMappingByPaperIdMutation,
    useUpdateQuestionsByPaperIdMutation,
    useUploadAnswerByPaperIdMutation,
    useDeleteQuestionMappingByIdMutation,
    useGetQuestionMappingByPaperIdQuery,
    useGetPaperDetailsByPaperIdQuery,
    usePaperListQuery,
    useGetSelectedSyllabusByPaperIdQuery,
    useUploadAnswerHtmlByPaperIdMutation,
    useUploadQuestionHtmlByPaperIdMutation,

    useGetSetterProfileQuery,
    useUpdateSocialLinkMutation,
    useUpdateSetterProfileMutation,
    useGetCertificatesListsQuery,
    useGetMappingListsQuery,
    useGetCertificatesLinkMutation,
    useAddCertificateMutation,
    useUpdateCertificateMutation,
    useDeleteCertificateMutation,
    useAddEducationMutation,
    useUpdateEducationMutation,
    useDeleteEducationMutation,
    useAddExperianceMutation,
    useUpdateExperianceMutation,
    useDeleteExperianceMutation,
    useAddIntroductoryVideoMutation,
    useDeleteIntroductoryVideoMutation,
    useGetAllIssuesQuery,
    useCloseIssueMutation,
    useSendPaperForReviewMutation,
    useCreateObjectivesByPaperIdMutation,
    useGetDashboardQuery
} = setterUserApi;

