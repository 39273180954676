import React from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import "./multiSelect.css";

const MultiselectBasicDropdown = ({ options, onSelect, onRemove, value, onSearch, placeholder, isMulti }) => {

    const formattedOptions = options.map(option => ({ name: option }));

    const formattedSelectedValues = value.map(val => ({ name: val }));

    return (
        <Multiselect
            className="custom-multiselect"
            displayValue="name"
            onKeyPressFn={() => { }}
            onRemove={onRemove}
            onSearch={onSearch}
            onSelect={onSelect}
            options={formattedOptions}
            showCheckbox={isMulti}
            selectedValues={formattedSelectedValues}
            // placeholder={placeholder}
            singleSelect={!isMulti}
            style={{
                optionContainer: {
                    border: '1px solid black',
                    borderRadius: '4px',
                },
                option: {
                    color: '#005ca8',
                    backgroundColor: 'rgba(0, 92, 168, 0.11)',
                },
                selectedValues: {
                    backgroundColor: 'red',
                    color: 'red'
                },
                searchBox: {
                    backgroundColor: 'rgba(0, 92, 168, 0.11)',
                    color: "#005ca8",
                },
                chips: {
                    background: '#005ca8',
                    color: "white",
                },
            }}
        />
    );
};

export default MultiselectBasicDropdown;
