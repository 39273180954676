import * as actionTypes from './actionTypes';

const initialState = {
  authDetails: [],
  navItems: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LOGIN_DETAILS:
      state = {
        ...state,
        authDetails: action.payload.data,
        navItems: action.payload.menu
      }
      break;
    default:
      state = { ...state };
      break;

  }
  return state;
}

export default reducer;