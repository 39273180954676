import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import classes from "./styles.module.scss";
import { useState } from 'react';

export default function ClickAway({ button, menuItems, children }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };



  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: '' }}>
        <div className={classes.btnClickOnMe} onClick={handleClick}>
          {button}
        </div>
        {open ? (
          <div className={classes.contentClickAway}>
            {children}
          </div>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
}
