import { SET_SIDEBAR_OPEN, SET_ACCORDIAN_DATA_ISOPEN } from './actionTypes';
import { createSlice, createAction } from "@reduxjs/toolkit";

const initialState = {
  IsOpenActiveData: [],
  isOpen: true,
};

const setIsSidebar = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebarOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setAccordionDataIsOpen: (state, action) => {
      state.IsOpenActiveData = action.payload;
    },
    toggleNavIsOpen: (state, action) => {
      const data = action.payload;
      const matchingIndex = state.IsOpenActiveData.findIndex((obj) => obj.name === data.name);
      if (matchingIndex !== -1) {
        state.IsOpenActiveData = state.IsOpenActiveData.map((item, index) => {
          if (index === matchingIndex) {
            return { ...item, open: !item.open };
          } else {
            return { ...item, open: false };
          }
        });
      } else {
        state.IsOpenActiveData = [
          ...state.IsOpenActiveData.map((n) => ({ ...n, open: false })),
          { ...data, open: !data.open },
        ];
      }
    },
  },
});


export const { setSidebarOpen, setAccordionDataIsOpen, toggleNavIsOpen } = setIsSidebar.actions;

export default setIsSidebar.reducer;