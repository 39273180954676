import React from "react";
import classes from "./styles.module.scss";

const InputSelect = ({ emptyValue, selected, align, from, label, type, value, name, placeholder, onChange, autoComplete, readonly, marginTop, options, onBlur, required }) => {

    return (
        <div className={align ? classes.mg20__center : classes.mg20}>
            {label && <label className={from == 'feedback' ? classes.selectLabel : classes.label} htmlFor="input-field">{label} {required ? <i>*</i> : ""}</label>}
            {/* <br /> */}
            <select
                // style={{ marginTop, textAlign: align != 'center' ? "default" : "center", backgroundColor: selected ? "#ffd900" : "initial" }}
                required={required}
                className={from == 'feedback' ? classes.selectStyle : classes.input}
                type={type}
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
            >
                {emptyValue ? <option value="">Select</option> : ""}
                {
                    options.map((c, i) => (
                        <option value={c.id}>{c.name}</option>
                    ))
                }
                {name == 'referredBy' ? <option value="99999">Other</option> : ""}
            </select>
        </div >
    )
}

export default InputSelect;