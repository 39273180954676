export const deleteState = (state, item) => {
    ;
    if (item == "courseId") {
        delete state.syllabusId;
        delete state.groupId
        delete state.syllabusId
        delete state.subjectId
        delete state.sessionId
        delete state.groupId
        delete state.portionId
        delete state.programId
    } else if (item == "syllabusId") {
        delete state.groupId
    } else if (item == "stateId") {
        delete state.cityId
    }
    return state;
}
export const getOptions = (array, type = "type") => {
    let options = []
    if (type != 'subject') {
        array && array?.map((c, i) => {
            options.push({ optionValue: c.id, optionLabel: c.name })
        })
    } else if (type == 'subject') {
        array?.map((o, i) => (
            o.Subjects?.map((a, ia) => {
                options.push({ optionValue: a.id, optionLabel: a.name })
            })
        ))
    }
    return options;
}

export const generatePrepcaUniqueId = (name, mobileNo, id) => {
    const first4CharsOfName = name.replace(/\s+/g, '').substring(0, 4).toUpperCase();
    const last4DigitsOfMobile = mobileNo?.slice(-4) || '';

    return `${first4CharsOfName}${last4DigitsOfMobile}`;
};