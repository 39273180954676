import * as actionTypes from './actionTypes';



const reducer = (state, action) => {

  switch (action.type) {
    case actionTypes.UPDATE_PUBLIC_DATA:
      state = {
        ...state,
        [action.payload.name]: action.payload.data,
      }
      break;
    default:
      state = { ...state };
      break;

  }
  ;
  return state;
}

export default reducer;