import React, { lazy, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import AppRoute from "./route";
import Layout from "../container/layout";
import { setCurrentTheme } from "../store/theme/action";
import { toggleNavIsOpen } from "../store/nav/reducer";
import { useSelector } from "react-redux";
import { generateMenu } from "../store/auth/actions";
import { useLocation, useNavigate } from "react-router-dom";
import KnowledgeTestPage from "../pages/onBoardingUser/KnowledgeTest";
import addAddress from "../container/orderTable/address/addAddress";


// ---------- Routes ---------- //
const Login = lazy(() => import("../pages/login"));

const ViewQuestionPapers = lazy(() => import("../pages/viewQuestionPaper"));
const AddQuestionPaperPage = lazy(() => import("../pages/addQuestion"));
const Logout = lazy(() => import("../pages/logout"));

const ViewOrders = lazy(() => import("../pages/viewOrders"));
const ViewUser = lazy(() => import("../pages/viewUser"));

const ViewOrderDetailsPage = lazy(() => import("../pages/viewOrderDetailsPage"));
const OrderPaperTable = lazy(() => import("../pages/viewOrders/orderPaperTable"));

const ViewCoupons = lazy(() => import("../pages/viewCoupons"));
// const AddCouponPage = lazy(() => import("../pages/viewCoupons/addCoupons"));
const ViewReferrals = lazy(() => import("../pages/viewReferrals"));
const ViewFeedbacks = lazy(() => import("../pages/viewFeedbacks"));
const ViewSupports = lazy(() => import("../pages/viewSupports"));
const ViewReferDetailsPage = lazy(() => import("../pages/viewReferrals/viewReferDetailsPage"));
const ViewFeedbackDetailsPage = lazy(() => import("../pages/viewFeedbacks/viewFeedbackDetailsPage"));
const ViewSupportDetailsPage = lazy(() => import("../pages/viewSupports/viewSupportDetailsPage"));

const ViewSchedules = lazy(() => import("../pages/viewSchedules"));
const AddSchedules = lazy(() => import("../pages/viewSchedules/addSchedules"));
const ViewSyllabus = lazy(() => import("../pages/viewSyllabus"));
const EditSyllabus = lazy(() => import("../pages/viewSyllabus/editSyllabus"));
const EditSchedule = lazy(() => import("../pages/viewSchedules/editSchedule"));

const ViewPlans = lazy(() => import("../pages/viewPlans"));

const BundleFeesPage = lazy(() => import("../pages/viewFees/viewBundleFees"));
const CustomizeFeesPage = lazy(() => import("../pages/viewFees/viewCustomizeFees"));
const ViewCheckers = lazy(() => import("../pages/viewCheckers"));
const ViewStudentsPapers = lazy(() => import("../pages/ViewStudentsPapers"));

//Configurations
//================
const CoursePage = lazy(() => import("../pages/configurationsPage/course"));
const GroupPage = lazy(() => import("../pages/configurationsPage/group"));
const PortionPage = lazy(() => import("../pages/configurationsPage/portion"));
const SubjectPage = lazy(() => import("../pages/configurationsPage/subject"));
const SessionPage = lazy(() => import("../pages/configurationsPage/session"));
const SyllabusPage = lazy(() => import("../pages/configurationsPage/syllabus"));
const ChapterPage = lazy(() => import("../pages/configurationsPage/chapter"));
const PortionToGroupMappingPage = lazy(() => import("../pages/configurationsPage/portionToGroupMapping"));
const ServicesPage = lazy(() => import("../pages/configurationsPage/sservices"));
const GSTPage = lazy(() => import("../pages/configurationsPage/gst"));


//setter panel
const ViewPapersPage = lazy(() => import("../pages/setterPage/viewPapers"));
const UploadQuestionsPage = lazy(() => import("../pages/setterPage/viewPapers/uploadQuestionPage"));
const AddQuestionPage = lazy(() => import("../pages/setterPage/viewPapers/addQuestionPage"));
const QueryPage = lazy(() => import(("../pages/setterPage/viewQuery")));
// const ProfilePage = lazy(() => import("../pages/setterPage/viewProfile"));
const ViewFeedbackPage = lazy(() => import("../pages/setterPage/viewFeedback"));
const ViewDashboardPage = lazy(() => import("../pages/setterPage/viewDashboard"));
const PaymentReconciliationPage = lazy(() => import("../pages/setterPage/viewPaymentReconcilationPage"));
const SubjectWisePaymentTablePage = lazy(() => import("../pages/setterPage/viewPaymentReconcilationPage/subjectWisePaymentTablePage"));

//new setter panel
const ViewSetterPapersPage = lazy(() => import("../pages/setterUserPage/viewPapersPage"));
const ViewSubjectAnalysisReportPage = lazy(() => import("../pages/setterUserPage/viewSubjectAnalysisReportPage"));

const ViewSetterMappingPage = lazy(() => import("../pages/setterUserPage/viewSetterMappingPage"))


const CreatePapersPage = lazy(() => import("../pages/setterUserPage/createPaperPage"));
const ProfilePage = lazy(() => import("../pages/setterUserPage/viewSetterProfilePage"))
const ViewSetterUserDashboardPage = lazy(() => import("../pages/setterUserPage/dashboardPage"))

const setterCertifiatePage = lazy(() => import("../pages/setterUserPage/viewSetterCertificatePage"))
const ViewSetterUserContractPage = lazy(() => import("../pages/setterUserPage/viewContractPage"))
const UploadSetterQuestionsPage = lazy(() => import("../pages/setterPage/viewPapers/uploadQuestionPage"));
const AddSetterQuestionPage = lazy(() => import("../pages/setterPage/viewPapers/addQuestionPage"));
const QuerySetterPage = lazy(() => import(("../pages/setterPage/viewQuery")));
const SetterProfilePage = lazy(() => import("../pages/setterPage/viewProfile"));
const ViewSetterFeedbackPage = lazy(() => import("../pages/setterPage/viewFeedback"));
const ViewSetterDashboardPage = lazy(() => import("../pages/setterPage/viewDashboard"));
const SetterPaymentReconciliationPage = lazy(() => import("../pages/setterPage/viewPaymentReconcilationPage"));
const SetterSubjectWisePaymentTablePage = lazy(() => import("../pages/setterPage/viewPaymentReconcilationPage/subjectWisePaymentTablePage"));

// checker panel
const CheckerViewPapersPage = lazy(() => import("../pages/checkerPage/viewPapers"));
const CheckerViewSubjectPage = lazy(() => import("../pages/checkerPage/viewPapers/viewSubject"));


// counselor panel
const CouncellerViewStudentPage = lazy(() => import("../pages/counsellorPage/viewCounsellingListsPage"));
const ViewCounselingDetailPage = lazy(() => import("../pages/counsellorPage/ViewCounselingDetailPage"));
const ViewCounselorProfilePage = lazy(() => import("../pages/counsellorPage/viewCounselorProfilePage"));
const ViewStudentCertificationPage = lazy(() => import("../pages/counsellorPage/viewCertificatePage"));
const ViewProfilingPage = lazy(() => import("../pages/counsellorPage/ViewProfilingPage"));
const ViewAvailabilityPage = lazy(() => import("../pages/counsellorPage/viewAvailabilityPage"));
const ViewCounselorContractPage = lazy(() => import("../pages/counsellorPage/viewContractPage"));
const ViewCounselorDashboardPage = lazy(() => import("../pages/counsellorPage/viewCounsellorDashboardPage"));
const ViewStudentListPage = lazy(() => import("../pages/counsellorPage/viewStudentListPage"));
const ViewCounselingListPage = lazy(() => import("../pages/counsellorPage/viewCounselingLIstByOrder"));

// Admin Counselor panel
const ViewAdminCounselorProfilePage = lazy(() => import("../pages/counsellorAdminPage/viewCounselorProfilePage"));
const ViewAdminCounselingDetailsPage = lazy(() => import("../pages/counsellorAdminPage/viewCounselingDetailsPage"));
const ViewAdminCounselingListsPage = lazy(() => import("../pages/counsellorAdminPage/viewCounselingListPage"));
const ViewAdminCounselorMappingPage = lazy(() => import("../pages/counsellorAdminPage/viewCounselorMappingPage"));
const ViewAdminCounselorListPage = lazy(() => import("../pages/counsellorAdminPage/viewCounselorListPage"));
const ViewAdminCounselorCertificatePage = lazy(() => import("../pages/counsellorAdminPage/viewCertificatePage"));
const ViewAdminCounselorAvailabilityPage = lazy(() => import("../pages/counsellorAdminPage/viewAvailabilityPage"));
const ViewAdminCounselorOnBoarding = lazy(() => import("../pages/onBoardingAdmin"));
const ViewAdminApplicationOnBoardingList = lazy(() => import("../pages/onBoardingAdmin/viewApplicationPage"));
const ViewAdminStagesOnBoardingList = lazy(() => import("../pages/onBoardingAdmin/viewOnboradingUserStagesPage"));
const ViewCounselorAvailabilityCountPage = lazy(() => import("../pages/counsellorAdminPage/ViewCounselorAvailabilityCountPage"));
const ViewCounselorAllAvailabilityPage = lazy(() => import("../pages/counsellorAdminPage/viewCounselorAllAvailabilityPage"));
const ViewAdminCounselorContractPage = lazy(() => import("../pages/counsellorAdminPage/viewContractPage"));
const ViewAdminCounselorDashboardPage = lazy(() => import("../pages/counsellorAdminPage/viewCounsellorDashboardPage"));
const ViewAdminCounselorFeedbacks = lazy(() => import("../pages/counsellorAdminPage/viewCounselorFeedbacks"));

// Reviewer Panel
const ViewReviewerPapersPage = lazy(() => import("../pages/reviewerUserPage/viewPapersPage"));
const ViewQuestionPaperReviewerPage = lazy(() => import("../pages/reviewerUserPage/createQuestionPapersPage"));
const ViewReviewerProfilePage = lazy(() => import("../pages/reviewerUserPage/viewReviewerProfilePage"))
const ViewReviewerMappingPage = lazy(() => import("../pages/reviewerUserPage/viewReviewerMappingPage"))
const viewReviewerUserDashboardPage = lazy(() => import("../pages/reviewerUserPage/dashboardReviewerUserPage"))
const ViewReviewerUserContractPage = lazy(() => import("../pages/reviewerUserPage/viewContractPage"))


// Admin Setter Panel
const ViewSetterAdminPapersPage = lazy(() => import("../pages/setterAdminPage/viewSetterAdminPapersPage"));
const ViewSetterAdminMappingPage = lazy(() => import("../pages/setterAdminPage/viewSetterAdminMappingPage"));
const ViewSetterListPage = lazy(() => import("../pages/setterAdminPage/viewSetterListPage"));
const AddorEditPaperPage = lazy(() => import("../pages/setterAdminPage/addorEditPaperPage"));
const ViewSetterAdminPaperWisePapersPage = lazy(() => import("../pages/setterAdminPage/viewSetterPapersPage"));
const ViewSetterPaperDetailsPage = lazy(() => import("../pages/setterAdminPage/viewSetterPaperDetailsPage"));
const ViewAdminSetterProfilePage = lazy(() => import("../pages/setterAdminPage/setterProfilePage"));
const ViewAdminSetterDashboardPage = lazy(() => import("../pages/setterAdminPage/setterDashboardAdminPage"));
const ViewAdminSetterCertificatePage = lazy(() => import("../pages/setterAdminPage/viewCertificatePage"));
const ViewAdminSetterContractPage = lazy(() => import("../pages/setterAdminPage/viewContractPage"));
const ViewReviewerAdminPapersPage = lazy(() => import("../pages/reviewerAdminPage/viewReviewerAdminPapersPage"));
const ViewReviewerListPage = lazy(() => import("../pages/reviewerAdminPage/viewReviewerListPage"));
const ViewAdminReviewerProfilePage = lazy(() => import("../pages/reviewerAdminPage/viewReviewerProfilePage"));
const ViewAdminReviewerDashboardPage = lazy(() => import("../pages/reviewerAdminPage/viewReviewerDashboardPage"));
const ViewAdminReviewerCertificatePage = lazy(() => import("../pages/reviewerAdminPage/viewCertificatePage"));
const ViewReviewerAdminPaperWisePapersPage = lazy(() => import("../pages/reviewerAdminPage/viewReviewerPapersPage"));
const ViewReviewerAdminMappingPage = lazy(() => import("../pages/reviewerAdminPage/viewReviewerAdminMappingPage"));
// onBoarding 
const OnBoardingUser = lazy(() => import("../pages/onBoardingUser"));

const openRoutes = [
    // { path: "/", exact: true, component: Login },
    { path: "/*", exact: true, component: Login },
    // { path: "/topbar", exact: true, component: TopBar },
    // { path: "/leftbar", exact: true, component: LeftBar},

];

const testRoutes = [
    { path: "/test", exact: true, component: KnowledgeTestPage },
];

const authRoutes = [
    { path: "/admin/counselors/mapping/:cname", exact: true, component: ViewAdminCounselorMappingPage },
    { path: "/admin/view-counselors", exact: true, component: ViewAdminCounselorListPage },
    { path: "/admin/counselor/profile/:cname", exact: true, component: ViewAdminCounselorProfilePage },
    { path: "/admin/counselors/counselling/:cname/:sname/Counseling%20Detail", exact: true, component: ViewAdminCounselingDetailsPage },
    { path: "/admin/counselors/counselling/:cname", exact: true, component: ViewAdminCounselingListsPage },
    { path: "/admin/counselors/view-counseling", exact: true, component: ViewAdminCounselingListsPage },
    { path: "/admin/counselors/certificates/:cname", exact: true, component: ViewAdminCounselorCertificatePage },
    { path: "/admin/counselors/availability/:cname", exact: true, component: ViewAdminCounselorAvailabilityPage },
    { path: "/admin/counselors/availability-count", exact: true, component: ViewCounselorAvailabilityCountPage },
    { path: "/admin/counselors/all-availability", exact: true, component: ViewCounselorAllAvailabilityPage },
    { path: "/admin/counselors/contract", exact: true, component: ViewAdminCounselorContractPage },
    { path: "/admin/counselor/dashboard", exact: true, component: ViewAdminCounselorDashboardPage },
    { path: "/admin/counselor/view-feedbacks", exact: true, component: ViewAdminCounselorFeedbacks },
    { path: "/logout", exact: true, component: Logout },
    { path: "/", exact: true, component: ViewOrders },
    { path: "/admin/view-question", exact: true, component: ViewQuestionPapers },
    { path: "/admin/add-question", exact: true, component: AddQuestionPaperPage },
    { path: "/admin/add-question/:id", exact: true, component: AddQuestionPaperPage },
    { path: "/admin/view-orders", exact: true, component: ViewOrders },
    { path: "/admin/view-user", exact: true, component: ViewUser },
    { path: "/admin/order-details/:id", exact: true, component: ViewOrderDetailsPage },
    { path: "/admin/order-address/:id", exact: true, component: addAddress },
    { path: "/admin/paper-details/:id", exact: true, component: OrderPaperTable },
    { path: "/admin/view-coupons", exact: true, component: ViewCoupons },
    // { path: "/add-coupons", exact: true, component: AddCouponPage },
    { path: "/view-referrals", exact: true, component: ViewReferrals },
    { path: "/view-feedbacks", exact: true, component: ViewFeedbacks },
    { path: "/view-supports", exact: true, component: ViewSupports },
    { path: "/refer-details", exact: true, component: ViewReferDetailsPage },
    { path: "/feedback-details", exact: true, component: ViewFeedbackDetailsPage },
    { path: "/support-details", exact: true, component: ViewSupportDetailsPage },
    { path: "/admin/view-schedules", exact: true, component: ViewSchedules },
    { path: "/admin/add-schedules", exact: true, component: AddSchedules },
    { path: "/admin/view-syllabus", exact: true, component: ViewSyllabus },
    { path: "/admin/edit-syllabus/:courseId/:syllabusId/:subjectId", exact: true, component: EditSyllabus },
    { path: "/admin/edit-schedules/:courseId/:syllabusId/:sessionId", exact: true, component: EditSchedule },
    { path: "/admin/view-plans", exact: true, component: ViewPlans },
    { path: "/admin/view-bundle-fees", exact: true, component: BundleFeesPage },
    { path: "/admin/view-customize-fees", exact: true, component: CustomizeFeesPage },
    { path: "/admin/view-feedbacks", exact: true, component: ViewFeedbacks },

    { path: "/admin/configurations/add-course", exact: true, component: CoursePage },
    { path: "/admin/configurations/add-group", exact: true, component: GroupPage },
    { path: "/admin/configurations/add-portion", exact: true, component: PortionPage },
    { path: "/admin/configurations/add-subject", exact: true, component: SubjectPage },
    { path: "/admin/configurations/add-session", exact: true, component: SessionPage },
    { path: "/admin/configurations/add-syllabus", exact: true, component: SyllabusPage },
    { path: "/admin/configurations/add-chapter", exact: true, component: ChapterPage },
    { path: "/admin/configurations/portion-to-group-map", exact: true, component: PortionToGroupMappingPage },

    { path: "/admin/configurations/services", exact: true, component: ServicesPage },
    { path: "/admin/configurations/gst", exact: true, component: GSTPage },

    { path: "/admin/view-checkers", exact: true, component: ViewCheckers },
    { path: "/admin/view-students-paper", exact: true, component: ViewStudentsPapers },
    { path: "/admin/view-students-paper/:status", exact: true, component: ViewStudentsPapers },
    { path: "/admin/view-papers/view-subjects/:paperId", exact: true, component: CheckerViewSubjectPage },
    { path: "/admin/view-papers/view-subjects/:status/:paperId", exact: true, component: CheckerViewSubjectPage },
    { path: "/admin/:type/onboarding", exact: true, component: ViewAdminCounselorOnBoarding },
    { path: "/admin/:type/onboarding/application", exact: true, component: ViewAdminApplicationOnBoardingList },
    { path: "/admin/:type/onboarding/stages", exact: true, component: ViewAdminStagesOnBoardingList },

    { path: "/admin/setter/view-papers", exact: true, component: ViewSetterAdminPapersPage },
    { path: "/admin/setter/view-all", exact: true, component: ViewSetterListPage },
    { path: "/admin/edit-papers/:id", exact: true, component: AddorEditPaperPage },
    { path: "/admin/edit-papers", exact: true, component: AddorEditPaperPage },
    { path: "/admin/setter/papers", exact: true, component: ViewSetterAdminPaperWisePapersPage },
    { path: "/admin/:type/create-papers/:page", exact: true, component: ViewSetterPaperDetailsPage },
    { path: "/admin/setter/mapping/:cname", exact: true, component: ViewSetterAdminMappingPage },
    // { path: "/admin/:type/onboarding/stages", exact: true, component: ViewAdminStagesOnBoardingList },
    { path: "/admin/setter/profile/:cname", exact: true, component: ViewAdminSetterProfilePage },
    { path: "/admin/setter/dashboard", exact: true, component: ViewAdminSetterDashboardPage },
    { path: "/admin/setter/certificates/:cname", exact: true, component: ViewAdminSetterCertificatePage },
    { path: "/admin/setter/contract", exact: true, component: ViewAdminSetterContractPage },

    { path: "/admin/reviewer/view-papers", exact: true, component: ViewReviewerAdminPapersPage },
    { path: "/admin/reviewer/profile/:cname", exact: true, component: ViewAdminReviewerProfilePage },
    { path: "/admin/reviewer/dashboard", exact: true, component: ViewAdminReviewerDashboardPage },
    { path: "/admin/reviewer/certificates/:cname", exact: true, component: ViewAdminReviewerCertificatePage },
    { path: "/admin/reviewer/papers", exact: true, component: ViewReviewerAdminPaperWisePapersPage },
    { path: "/admin/reviewer/view-all", exact: true, component: ViewReviewerListPage },
    { path: "/admin/reviewer/mapping/:cname", exact: true, component: ViewReviewerAdminMappingPage },

    //Setter paths
    // { path: "/setter/view-papers", exact: true, component: ViewPapersPage },
    // { path: "/setter/view-papers/upload-questions", exact: true, component: UploadQuestionsPage },
    // { path: "/setter/view-papers/add-questions", exact: true, component: AddQuestionPage },
    // { path: "/setter/view-queries", exact: true, component: QueryPage },
    // { path: "/setter/view-profile", exact: true, component: ProfilePage },
    // { path: "/setter/payment-reconciliation", exact: true, component: PaymentReconciliationPage },
    // { path: "/setter/payment-reconciliation/subject-wise", component: SubjectWisePaymentTablePage },
    // { path: "/setter/feedback", exact: true, component: ViewFeedbackPage },
    // { path: "/setter/dashboard", exact: true, component: ViewDashboardPage },

    //Checker paths
    { path: "/checker/view-papers/", exact: true, component: CheckerViewPapersPage },
    { path: "/checker/view-papers/:status", exact: true, component: CheckerViewPapersPage },
    { path: "/checker/view-papers/view-subjects/:paperId", exact: true, component: CheckerViewSubjectPage },
    { path: "/checker/view-papers/view-subjects/:status/:paperId", exact: true, component: CheckerViewSubjectPage },

    //Counselor paths
    { path: "/counselor/counseling/", exact: true, component: CouncellerViewStudentPage },
    { path: "/counselor/counseling/:status", exact: true, component: CouncellerViewStudentPage },
    { path: "/counselor/view-councelling-student-details/:id", exact: true, component: ViewCounselingDetailPage },
    { path: "/counselor/profile", exact: true, component: ViewCounselorProfilePage },
    { path: "/counselor/certification", exact: true, component: ViewStudentCertificationPage },
    { path: "/counselor/mapping", exact: true, component: ViewProfilingPage },
    { path: "/counselor/availability", exact: true, component: ViewAvailabilityPage },
    { path: "/counselor/contract", exact: true, component: ViewCounselorContractPage },
    { path: "/counselor/dashboard", exact: true, component: ViewCounselorDashboardPage },
    { path: "/:type/onboarding", exact: true, component: OnBoardingUser },
    { path: "/counselor/students", exact: true, component: ViewStudentListPage },
    { path: "/counselor/counseling-status/:orderId", exact: true, component: ViewCounselingListPage },


    //new setter paths
    { path: "/setter/view-papers", exact: true, component: ViewSetterPapersPage },
    { path: "/setter/view-subject-analysis-report", exact: true, component: ViewSubjectAnalysisReportPage },
    { path: "/setter/mapping", exact: true, component: ViewSetterMappingPage },
    { path: "/setter/contract", exact: true, component: ViewSetterUserContractPage },

    // { path: "/setter/create-papers/:page", exact: true, component: CreateSyllabusPage },
    { path: "/setter/create-papers/:page", exact: true, component: CreatePapersPage },
    { path: "/setter/view-papers/upload-questions", exact: true, component: UploadQuestionsPage },
    { path: "/setter/view-papers/add-questions", exact: true, component: AddQuestionPage },
    { path: "/setter/view-queries", exact: true, component: QueryPage },
    { path: "/setter/profile", exact: true, component: ProfilePage },
    { path: "/setter/certification", exact: true, component: setterCertifiatePage },
    { path: "/setter/payment-reconciliation", exact: true, component: PaymentReconciliationPage },
    { path: "/setter/payment-reconciliation/subject-wise", component: SubjectWisePaymentTablePage },
    { path: "/setter/feedback", exact: true, component: ViewFeedbackPage },
    { path: "/setter/dashboard", exact: true, component: ViewSetterUserDashboardPage },

    // reviewer paths
    { path: "/reviewer/dashboard", exact: true, component: viewReviewerUserDashboardPage },
    { path: "/reviewer/view-papers", exact: true, component: ViewReviewerPapersPage },
    // { path: "/reviewer/create-syllabus", exact: true, component: CreateSyllabusReviewerPage },
    { path: "/reviewer/create-papers/:page", exact: true, component: ViewQuestionPaperReviewerPage },
    { path: "/reviewer/profile", exact: true, component: ViewReviewerProfilePage },
    { path: "/reviewer/mapping", exact: true, component: ViewReviewerMappingPage },
    { path: "/reviewer/contract", exact: true, component: ViewReviewerUserContractPage },


];
const PagesRoute = (props) => {
    const [allRoutes, setAllRoutes] = useState([])

    const [currentSection, setCurrentSection] = useState(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()


    useEffect(() => {
        const panelUrl = pathname
        const split = panelUrl.split("/")

        setCurrentSection(split[1])
        if (split[1] === 'admin') {
            setCurrentSection('admin');
            dispatch(setCurrentTheme('admin'))
        }
        if (split[1] === 'setter') {
            setCurrentSection('setter')
            dispatch(setCurrentTheme('setter'))

        }
        if (split[1] === 'checker') {
            setCurrentSection('checker')
            dispatch(setCurrentTheme('checker'))

        }
        if (split[1] === 'counselor') {
            setCurrentSection('counselor')
            dispatch(setCurrentTheme('counselor'))

        } else {
            setCurrentSection(null)
            dispatch(setCurrentTheme(null))

        }
    }, [pathname])



    useEffect(() => {
        let data = localStorage.getItem("prepca-admin-login-data")


        if (data) {
            data = JSON.parse(data)
            const menus = generateMenu(data?.rolesAndPermissions).map((navItem) => {
                return navItem
            });

            // This for root location open default menu
            if (window.location.pathname == '/') {


                const isAlreadyRoleGiven = data?.rolesAndPermissions?.find((r) => r.role === 'counselor')
                if (data?.rolesAndPermissions[0]?.role === "boarding") {
                    if (!isAlreadyRoleGiven) {
                        navigate('/counselor/onboarding');
                        return;
                    } else {
                        navigate('/counselor/counseling');
                    }
                } else if (data?.rolesAndPermissions[0]?.role === "counselor") {
                    navigate('/counselor/counseling');
                } else {
                    navigate("/")
                }
                const currentSection = data?.rolesAndPermissions[0]?.role || null
                switch (currentSection) {
                    case 'superAdmin':
                        dispatch(toggleNavIsOpen({ name: 'Orders' }));
                        navigate("/admin/view-orders")
                        break;
                    case 'setterAdmin':
                        dispatch(toggleNavIsOpen({ name: 'Onboarding' }));
                        navigate("/admin/setter/onboarding")
                        break;
                    case 'reviewerAdmin':
                        dispatch(toggleNavIsOpen({ name: 'Onboarding' }));
                        navigate("/admin/reviewer/onboarding")
                        break;
                    case 'counselorAdmin':
                        dispatch(toggleNavIsOpen({ name: 'Onboarding' }));
                        navigate("/admin/counselor/onboarding")
                        break;
                    case 'counselorBoarding':
                        dispatch(toggleNavIsOpen({ name: 'Onboarding' }));
                        navigate("/counselor/onboarding")
                        break;
                    case 'setterBoarding':
                        dispatch(toggleNavIsOpen({ name: 'Onboarding' }));
                        navigate("/setter/onboarding")
                        break;
                    case 'reviewerBoarding':
                        dispatch(toggleNavIsOpen({ name: 'Onboarding' }));
                        navigate("/reviewer/onboarding")
                        break;
                    case ('counselor'):
                        dispatch(toggleNavIsOpen({ name: 'Dashboard', open: false }));
                        navigate("/counselor/dashboard")
                        break;
                    case ('setter'):
                        dispatch(toggleNavIsOpen({ name: 'Dashboard', open: false }));
                        navigate("/setter/dashboard")
                        break;
                    case ('reviewer'):
                        dispatch(toggleNavIsOpen({ name: 'Dashboard', open: false }));
                        navigate("/reviewer/dashboard")
                        break;
                    case ('boarding'):
                        dispatch(toggleNavIsOpen({ name: 'Students', open: false }));
                        navigate("/counselor/counseling")
                        break;
                    case 'checker':
                        dispatch(toggleNavIsOpen({ name: 'Papers' }));
                        navigate("/checker/view-papers/pending")
                        break;
                    default:
                        break;
                }
            } else { // This for other location open default menu
                const panelUrl = window.location.pathname
                const menuItem = menus && menus.find(item => item.links && item.links.some(link => link.to === panelUrl));
                if (menuItem) {
                    dispatch(toggleNavIsOpen({ name: menuItem.name }));
                } else {
                    const currentSection = data.rolesAndPermissions[0]?.role || null
                    switch (currentSection) {
                        case 'superAdmin':
                            dispatch(toggleNavIsOpen({ name: 'Orders' }));
                            // navigate("/admin/view-orders")
                            break;
                        case 'counselor':
                            dispatch(toggleNavIsOpen({ name: 'Students', open: false }));
                            // navigate("/counselor/counseling")
                            break;
                        case 'checker':
                            dispatch(toggleNavIsOpen({ name: 'Papers' }));
                            // navigate("/checker/view-papers/pending")
                            break;
                        default:
                            break;
                    }

                }
            }
        }
    }, [])

    useEffect(() => {
        if (props.auth.authDetails?.token) {
            setAllRoutes([...authRoutes])
        }
        else {
            setAllRoutes([...openRoutes])
        }

    }, [props.auth.authDetails])


    return (
        <>
            {
                props.auth.authDetails?.token
                    ?
                    <>
                        {pathname !== "/test" ? <Layout type={currentSection}>
                            {allRoutes.map((route, idx) => (
                                <AppRoute path={route.path} component={route.component} key={idx} isAuthProtected={true} />
                            ))}
                        </Layout>
                            :
                            <>
                                {testRoutes.map((route, idx) => (
                                    <AppRoute path={route.path} component={route.component} key={idx} isAuthProtected={true} />
                                ))}
                            </>
                        }
                    </>
                    :
                    <>
                        {openRoutes.map((route, idx) => (
                            <AppRoute path={route.path} component={route.component} key={idx} isAuthProtected={true} />
                        ))}
                    </>
            }
        </>

    );
};


const mapStateToProps = (state) => {
    return {
        auth: state.Auth,
    }
}

export default connect(mapStateToProps, null)(PagesRoute);