import { createApi } from '@reduxjs/toolkit/query/react';
import * as API from '../../apiCalls/urls/onboardingAdmin';
import { baseQuery } from '../../apiCalls/rtkBaseQuery';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const onBoardingAdminApi = createApi({
  baseQuery,
  reducerPath: 'boardingAdminApi',
  tagTypes: ['boardings', 'dashboard', 'comment', 'boardingDetails', 'moveBoardingForm', 'updateBoardingForm', 'boardingSteps', 'boardingList', 'boardingStepsByType'],
  endpoints: (builder) => ({
    getBoardings: builder.query({
      query: () => ({
        url: API.GET_BOARDINGS,
        method: 'GET'
      }),
      providesTags: ['boardings'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0
    }),

    getDashboard: builder.query({
      query: (data) => ({
        url: API.GET_DASHBOARD,
        method: 'POST',
        body: data
      }),
      providesTags: ['dashboard'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0
    }),

    addComment: builder.mutation({
      query: (commentData) => ({
        url: API.ADD_COMMENT,
        method: 'POST',
        body: commentData
      }),
      // invalidatesTags: ['boardingList']
    }),

    getBoardingDetails: builder.query({
      query: (id) => ({
        url: `${API.GET_BOARDING_DETAILS}/${id}`,
        method: 'GET',
      }),
      providesTags: ['boardingDetails'],
    }),

    moveBoardingForm: builder.mutation({
      query: (data) => ({
        url: API.MOVE_BOARDING_FORM,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['boardingList', 'dashboard']
    }),

    updateBoardingForm: builder.mutation({
      query: (data) => ({
        url: API.UPDATE_BOARDING_FORM,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['updateBoardingForm', 'dashboard']
    }),

    getBoardingSteps: builder.query({
      query: () => ({
        url: API.GET_BOARDING_STEPS,
        method: 'GET'
      }),
      providesTags: ['boardingSteps'],
      refetchOnMountOrArgChange: true
    }),
    getBoardingStepsByType: builder.query({
      query: (type) => ({
        url: `${API.GET_BOARDING_STEPS_BY_TYPE}${type}`,
        method: 'GET'
      }),
      providesTags: ['boardingStepsByType'],
      refetchOnMountOrArgChange: true
    }),

    getBoardingList: builder.query({
      query: (data) => {
        const { page, items } = data;
        return {
          url: `${API.BOARDING_LIST}?page=${page}&items=${items}`,
          method: 'POST',
          body: data
        };
      },
      providesTags: ['boardingList'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0

    }),
    scheduleMeeting: builder.mutation({
      query: (data) => ({
        url: API.SCHEDULE_MEETING,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['boardingList', 'dashboard']
    }),
    moveMultipleBoarding: builder.mutation({
      query: (data) => ({
        url: API.MOVE_MULTIPLE_BOARDING_FORM,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['boardingList', 'dashboard']
    }),
    acceptContract: builder.mutation({
      query: (data) => ({
        url: API.ACCEPT_CONTRACT,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['boardingList', 'dashboard']
    }),
    rejectContract: builder.mutation({
      query: (data) => ({
        url: API.REJECT_CONTRACT,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['boardingList', 'dashboard']
    }),
    sendContract: builder.mutation({
      query: (data) => ({
        url: API.SEND_CONTRACT,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['boardingList', 'dashboard']
    }),
    getSOP: builder.query({
      query: (data) => ({
        url: API.GET_SOP,
        method: 'POST',
        body: data
      }),
      providesTags: ['sop'],
    }),
    getSubjectMappingBySessionId: builder.query({
      query: (id) => ({
        url: `${API.GET_MAPPING}${id}`,
        method: 'GET',
      }),

      // providesTags: ['dashboard'],
      // refetchOnMountOrArgChange: true
    }),
    getBoardingQuestions: builder.query({
      query: () => {
        return {
          url: `${API.GET_BOARDING_QUESTIONS}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetBoardingsQuery,
  useGetDashboardQuery,
  useAddCommentMutation,
  useGetBoardingDetailsQuery,
  useMoveBoardingFormMutation,
  useUpdateBoardingFormMutation,
  useGetBoardingStepsQuery,
  useGetBoardingListQuery,
  useScheduleMeetingMutation,
  useMoveMultipleBoardingMutation,
  useAcceptContractMutation,
  useSendContractMutation,
  useGetSOPQuery,
  useGetSubjectMappingBySessionIdQuery,
  useRejectContractMutation,
  useGetBoardingQuestionsQuery,
  useGetBoardingStepsByTypeQuery

} = onBoardingAdminApi;


export const fetchOnBoardingSteps = createAsyncThunk(
  'onBoarding/fetchSteps',
  async () => {
    const response = await onBoardingAdminApi.endpoints.getBoardingSteps();

    return response.data;
  }
);