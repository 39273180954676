import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeConfirmationDialog } from './confirmationDialogSlice';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import PrimaryButton from '../../../components/buttons';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  boxShadow: 24,
  height: 'max-content',
  textAlign: 'center',
  borderRadius: '4px',
};

const CustomConfirmDialog = (props) => {
  const dispatch = useDispatch();
  const {
    openDialog: open,
    title,
    message,
    reason: isReason,
    data,
    confirmationCallback
  } = useSelector((state) => state.confirmationDialog);


  const handleClose = () => {
    props?.setReason("")
    dispatch(closeConfirmationDialog());
  };

  const handleConfirmation = () => {
    confirmationCallback({ ...data, reason: props.reason });
    handleClose();
  };

  const statusConfig = {
    Accept: { backgroundColor: 'green', icon: <CheckCircleIcon sx={{ color: 'white', fontSize: '40px' }} /> },
    Approve: { backgroundColor: 'green', icon: <CheckCircleIcon sx={{ color: 'white', fontSize: '40px' }} /> },
    Reject: { backgroundColor: 'red', icon: <CancelIcon sx={{ color: 'white', fontSize: '40px' }} /> },
    Hold: { backgroundColor: '#ffab1f', icon: <WarningIcon sx={{ color: 'white', fontSize: '40px' }} /> },
    Resume: { backgroundColor: 'blue', icon: <PlayCircleFilledWhiteIcon sx={{ color: 'white', fontSize: '40px' }} /> },
    Default: {
      backgroundColor: 'green', icon: <CheckCircleIcon sx={{ color: 'white', fontSize: '40px' }} />
    },
  };

  const { backgroundColor, icon } = statusConfig[title] || statusConfig.Default;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Stack
          sx={{
            backgroundColor: backgroundColor,
            p: 1,
          }}
          alignItems={'center'}
          display={'flex'}
          justifyContent={'center'}
        >
          {icon}
        </Stack>
        <Stack padding={4}>
          {/* <Typography variant="h6">{title}</Typography> */}
          <DialogContentText sx={{ fontWeight: "bold" }}>{message}</DialogContentText>
          {isReason && (
            <>
              {/* <div style={{ marginTop: '8px' }}>Reason</div> */}
              {/* <textarea
                sx={{
                  border: "1px solid gray",
                  marginTop: "8px",
                  borderRadius: "4px",
                  padding: "8px",
                  boxShadow: 3, // MUI shadow level (0-24)
                  width: '100%',
                  resize: 'none',
                }}
                placeholder='Reason'
                value={props.reason}
                onChange={props.handleChangeReason}
                name="reason"
                id=""
                cols="30"
                rows="5"
              ></textarea> */}
              <Box
                component="textarea"
                sx={{
                  border: "2px solid gray",
                  marginTop: "8px",
                  borderRadius: "4px",
                  padding: "8px",
                  boxShadow: 3,
                  width: '100%',
                  resize: 'none',
                }}
                placeholder='Reason'
                value={props.reason}
                onChange={props.handleChangeReason}
                name="reason"
                cols="30"
                rows="5"
              />
            </>
          )}
          <Grid container gap={2} justifyContent={'center'} mt={2}>
            <Grid item>
              <PrimaryButton
                buttonText="Yes"
                backgroundColor="#005ca8"
                width="100px"
                onClick={handleConfirmation}
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                buttonText="No"
                backgroundColor="#ff0000"
                width="100px"
                onClick={handleClose}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Modal>
  );
};

export default CustomConfirmDialog;
