import { useLocation } from 'react-router-dom';

const useAdminSubRole = () => {
    const { pathname } = useLocation();

    const adminSubRoutes = {
        counselor: ['/admin/counselor', '/admin/counselor'],
        setter: '/admin/setter',
        reviewer: '/admin/reviewer',
        checker: '/admin/checker',
    };

    const getAdminSubRole = () => {
        for (const subRole in adminSubRoutes) {
            const routes = adminSubRoutes[subRole];
            if (Array.isArray(routes)) {
                if (routes.some((route) => pathname.includes(route))) {
                    return subRole.toUpperCase();
                }
            } else if (pathname.includes(routes)) {
                return subRole.toUpperCase();
            }
        }
        return null;
    };

    return getAdminSubRole();
};

export default useAdminSubRole;
