import React from 'react'
import ClickAway from './muiClickAway'
import classes from "./styles.module.scss";
import { Avatar } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';

const Notification = (props) => {

    // functions click
    const handleItemClick = (fn) => {
        // setOpen(false);
        if (fn) {
            fn();
        }
    };

    const notiData = [
        { icon: <FolderIcon fontSize='12px' />, title: "Counseling Applied", subtitle: "Counseling Applied For Financial Reporting" },
        { icon: <FolderIcon fontSize='12px' />, title: "test2", subtitle: "Counseling Applied For Financial Reporting" },
        { icon: <FolderIcon fontSize='12px' />, title: "test3", subtitle: "Counseling Applied For Financial Reporting" },
    ]

    return (
        <ClickAway button={props.button}>
            <div className={classes.noti_container}>
                <div className={classes.heading}>Notifications</div>
                <div className={classes.noti_items_container}>
                    {notiData.map((n, i) => {
                        return (
                            <div onClick={() => { }} key={i} className={classes.noti_items}>
                                <div className={classes.icon}>
                                    <Avatar style={{ width: 35, height: 35 }}>
                                        {n.icon}
                                    </Avatar>
                                </div>
                                <div className={classes.title_container}>
                                    <div className={classes.title}>{n.title}</div>
                                    <div className={classes.subtitle}>{n.subtitle}</div>
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>
            </div>
        </ClickAway>
    )
}

export default Notification