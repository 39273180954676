import React from "react";
import classes from "./styles.module.scss";

const CheckBoxText = (props) => {

    return (
        <div className={classes.checkbox}>
            {
                typeof (props.value) != 'number' ?
                    <><input checked={props.value} id={props?.id} defaultChecked={props.defaultChecked} type="checkbox" value={props?.value} onClick={(e) => props.onClick(e)} /><span>{props.text}</span></>
                    :
                    <><input id={props?.id} defaultChecked={props.defaultChecked} type="checkbox" value={props?.value} onClick={(e) => props.onClick(e)} /><span>{props.text}</span></>

            }

        </div>
    )
}


export default CheckBoxText;