import * as actionTypes from './actionTypes'

const initialState = {
    scheduleData: [],
    progress: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SCHEDULE_TYPE:
            state = {
                ...state,
                scheduleData: action.payload.data,
            }
            break;
        case actionTypes.UPDATE_PROGRESS:
            return {
                ...state,
                progress: action.payload,
            }
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default reducer;
