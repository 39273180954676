import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openDialog: false,
  title: '',
  message: '',
  confirmationCallback: null,
  reason: false,
  data: {}
};

const confirmationDialogSlice = createSlice({
  name: 'confirmationDialog',
  initialState,
  reducers: {
    openConfirmationDialog: (state, action) => {

      const { title, message, confirmationCallback, reason, data } = action.payload;
      state.openDialog = true;
      state.title = title;
      state.message = message;
      state.confirmationCallback = confirmationCallback;
      state.reason = reason
      state.data = data
    },
    closeConfirmationDialog: (state) => {
      state.openDialog = false;
      state.title = '';
      state.message = '';
      state.confirmationCallback = null;
      state.data = {};
      state.reason = ""
    },
  },
});

export const { openConfirmationDialog, closeConfirmationDialog } = confirmationDialogSlice.actions;

export default confirmationDialogSlice.reducer;
