import { useEffect } from 'react';
import { apiLoading, apiLoadingEnd } from '../store/notification/action';


const useApiTopLoading = (loadingStates, dispatch) => {

    useEffect(() => {
        if (loadingStates.some(isLoading => isLoading)) {
            dispatch(apiLoading());
        } else {
            dispatch(apiLoadingEnd());
        }
    }, [loadingStates.some(isLoading => isLoading)]);
};

export default useApiTopLoading;