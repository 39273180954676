import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';
import { counsellorApi } from '../pages/counsellorPage/counsellorApiSlice';

import loadingSlice from './loader/loadingSlice';
import { counsellorAdminApi } from '../pages/counsellorAdminPage/counsellorAdminApiSlice';
import { onBoardingAdminApi } from '../pages/onBoardingAdmin/onBoardingAdminApiSlice';
import onBoardingSlice from './onboarding/onBoardingSlice';
import { onBoardingUserApi } from '../pages/onBoardingUser/onBoardingUserApiSlice';
import confirmationDialogSlice from '../container/onBordingShared/customConfirmMui/confirmationDialogSlice';
import { commonApiSlice } from './common/commonApiSlice';
import { setterUserApi } from '../pages/setterUserPage/setterUserApiSlice';
import uploadSlice from '../pages/setterUserPage/redux/uploadSlice';
import { reviewerUserApi } from '../pages/reviewerUserPage/reviewerUserApiSlice';
import { setterAdminApi } from '../pages/setterAdminPage/setterAdminApiSlice';
import { reviewerAdminApi } from '../pages/reviewerAdminPage/reviewerAdminApiSlice';


const store = configureStore({
  reducer: {
    ...rootReducer,
    [counsellorApi.reducerPath]: counsellorApi.reducer,
    [counsellorAdminApi.reducerPath]: counsellorAdminApi.reducer,
    [onBoardingAdminApi.reducerPath]: onBoardingAdminApi.reducer,
    [onBoardingUserApi.reducerPath]: onBoardingUserApi.reducer,
    [commonApiSlice.reducerPath]: commonApiSlice.reducer,
    [setterUserApi.reducerPath]: setterUserApi.reducer,
    [reviewerUserApi.reducerPath]: reviewerUserApi.reducer,
    [setterAdminApi.reducerPath]: setterAdminApi.reducer,
    [reviewerAdminApi.reducerPath]: reviewerAdminApi.reducer,
    loader: loadingSlice,
    onBoarding: onBoardingSlice,
    confirmationDialog: confirmationDialogSlice,
    upload: uploadSlice
    // counselor : counselorSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk,
      counsellorApi.middleware,
      counsellorAdminApi.middleware,
      onBoardingAdminApi.middleware,
      onBoardingUserApi.middleware,
      commonApiSlice.middleware,
      setterUserApi.middleware,
      reviewerUserApi.middleware,
      setterAdminApi.middleware,
      reviewerAdminApi.middleware

    ),
  devTools: true,
});

export default store;
