import * as actionTypes from './actionTypes';

const initialState = {
    questions: null,
    sessions: [],
    syllabus: [],
    portions: [],
    subjects: [],
    courses: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_QUESTION:
            state = {
                ...state,
                questions: action.payload,
            }
            break;
        case actionTypes.UPDATE_COURSE:
            state = {
                ...state,
                courses: action.payload,
            }
            break;
        case actionTypes.UPDATE_SYLLABUS:
            state = {
                ...state,
                syllabus: action.payload,
            }
            break;
        case actionTypes.UPDATE_SESSION:
            state = {
                ...state,
                sessions: action.payload,
            }
            break;
        case actionTypes.UPDATE_SUBJECT:
            state = {
                ...state,
                subjects: action.payload.subject,
                portions: action.payload.portion,
            }
            break;
        default:
            state = { ...state };
            break;

    }
    ;
    return state;
}

export default reducer;