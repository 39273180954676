export const REVIEWER_LIST = '/admin/users/reviewer/list';
export const PAPER_LIST = '/admin/users/paper/list';
export const PAPER_DETAILS = '/admin/users/paper-details';
export const REVIEWER_PAPER_LIST = '/admin/users/setter-papers/list';
export const UPDATE_PAPER = '/admin/users/update-paper';
export const REVIEWER_PAPER_MAPPING = '/admin/users/mapping';
export const REVIEWER_DASHBOARD = '/admin/users/reviewer/dashboard';
export const UPDATE_REVIEWER = '/admin/users/reviewer/update';

export const GET_PAPER_SYLLABUS = '/admin/users/paper-syllabus';
export const GET_REVIEWER_PAPER_DETAILS = '/admin/users/setter-paper-details';
export const GET_ALL_ISSUES_BY_REVIEWER_PAPER_ID = '/admin/users/paper-issues';
export const GET_QUESTION_MAPPING_BY_PAPER_ID = '/admin/users/mapping';

export const GET_REVIEWER_PROFILE_BY_REVIEWER_ID = '/admin/users/reviewer/details';
export const GET_REVIEWER_MAPPING_BY_REVIEWER_ID = '/admin/users/reviewer-mapping';


// For Setter Profile
export const ADD_EXPERIENCE = '/admin/users/reviewer/experience';
export const UPDATE_EXPERIENCE = '/admin/users/reviewer/experience';
export const DELETE_EXPERIENCE = '/admin/users/reviewer/experience';

export const ADD_EDUCATION = '/admin/users/reviewer/education';
export const UPDATE_EDUCATION = '/admin/users/reviewer/education';
export const DELETE_EDUCATION = '/admin/users/reviewer/education';

export const UPDATE_PROFILE = '/admin/users/reviewer/update-profile';

export const GET_CERTIFICATE_LIST = '/admin/users/reviewer/certificate';
export const ADD_CERTIFICATE = '/admin/users/reviewer/certificate';
export const UPDATE_CERTIFICATE = '/admin/users/reviewer/certificate';
export const DELETE_CERTIFICATE = '/admin/users/reviewer/certificate';
export const GET_CERTIFICATE_LINK = '/admin/users/reviewer/get-certificate-link';

export const ADD_INTRODUCTORY_VIDEO = '/admin/users/reviewer/add-introductory-video';
export const DELETE_INTRODUCTORY_VIDEO = '/admin/users/reviewer/delete-introductory-video';

export const UPDATE_SOCIAL_LINK = '/admin/users/reviewer/social-link';

export const ASSIGN_PAPER_TO_REVIEWER = '/admin/users/reviewer/assign-paper';
export const UPDATE_SETTER_PAPER = '/admin/users/update-setter-paper';

