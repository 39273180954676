import React from "react";
import classes from "./styles.module.scss";
import logo from "../../../assets/images/prepca-logo.png";
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { getDataFromStore } from "../../../store/getStore";
import { useSelector } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from 'react-redux';
import { Avatar, makeStyles } from "@mui/material";
import { setSidebarOpen, toggleNavIsOpen } from "../../../store/nav/reducer";
import ClickAway from "../../../components/muiClickAway";
import { onLogout } from "../../../store/auth/actions";
import { useNavigate } from "react-router-dom";
import usePath from "../../../hooks/usePath";
import Notification from "../../notification";
import MuiMenu from "../../../components/muiMenu";
import { Logout } from "@mui/icons-material";
import { clearOnBoardingStoreData } from "../../../store/onboarding/onBoardingSlice";
import useUserRole from "../../../hooks/useUserRole";
import { FaRegFileAlt } from "react-icons/fa";
import { generatePrepcaUniqueId } from "../../../shared/general";
// import Breadcrumbs from "./Breadcrumbs";




const TopBar = () => {
    const auth = getDataFromStore('Auth')
    const currentTheme = useSelector((state) => state.setCurrentTheme);

    const { profilePath, profileDefaultMenu } = usePath()
    const role = useUserRole()

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const isOpen = useSelector(state => state.setIsSidebar.isOpen);

    const handleHamburgerClick = () => {
        dispatch(setSidebarOpen(!isOpen));
    };

    const CustomButton = () => (
        <div className={classes.profile}>
            <AccountCircleIcon style={{ color: currentTheme.bg3 }} />
            <p style={{ textTransform: "capitalize", color: currentTheme.text1 }}>
                {auth?.authDetails?.admin?.name}
                {role === "checker" && <div style={{ fontSize: "12px", color: currentTheme.text3 }}>
                    ( {generatePrepcaUniqueId(auth?.authDetails?.admin?.name, auth?.authDetails?.admin?.mobileNo, auth?.authDetails?.admin?.id)} )
                </div>}
            </p>
        </div>
    );

    const NotiButton = () => (
        <div className={classes.bell}>
            <NotificationsIcon style={{ color: currentTheme.bg3 }} />
            <div className={classes.bell_dot}>
            </div>
        </div>
    );


    const handleLogout = () => {
        dispatch(onLogout(navigate))
        dispatch(clearOnBoardingStoreData(navigate))
    }

    const handleProfile = () => {
        dispatch(toggleNavIsOpen(profileDefaultMenu));
        navigate(profilePath)
    }


    const menuItems = [
        role !== "admin" && {
            icon: <FaRegFileAlt />,
            content: 'Profile',
            onClick: handleProfile
        },
        {
            icon: <Logout />,
            content: 'Logout',
            onClick: handleLogout
        },
    ].filter(Boolean);


    return (
        <>
            <div className={classes.header} style={{ backgroundColor: currentTheme.bg1 }}>

                <div className={classes.content}>


                    <div className={classes.left}>

                        {/* <img src={logo} alt="logo" /> */}
                        {/* <h4 style={{color:currentTheme.text2}}>Prepca {checkPath[1] == 'checker' ? 'Checker' : 'Admin'} Portal</h4> */}
                        {/* <MenuIcon onClick={handleHamburgerClick} style={{ color: currentTheme.text1 }} fontSize="large" /> */}
                        <div style={isOpen ? { marginLeft: "255px", cursor: "pointer" } : { marginLeft: "0px", cursor: "pointer" }}>
                            <MenuIcon onClick={handleHamburgerClick} style={{ color: currentTheme.text1 }} fontSize="large" />
                        </div>
                    </div>

                    <div className={classes.right}>

                        <Notification button={<NotiButton />} />
                        <MuiMenu menuItems={menuItems} button={<CustomButton />} />
                        {/* <ClickAway menuItems={menuItems} button={<CustomButton />} /> */}
                    </div>

                </div>
                {/* <Breadcrumbs /> */}
            </div >
        </>
    )
}

export default TopBar;