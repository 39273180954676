import { useEffect } from 'react';
// import { apiLoading, apiLoadingEnd } from '../store/notification/action';
import { useMemo } from 'react';
import { apiLoadingOverlay, apiLoadingOverlayEnd } from '../store/loader/loadingSlice';


const useApiLoadingOverlay = (loadingStates, dispatch) => {
  // const memoizedLoadingStates = useMemo(() => loadingStates, [loadingStates]);

  useEffect(() => {
    if (loadingStates.some(isLoading => isLoading)) {
      dispatch(apiLoadingOverlay());
    } else {
      dispatch(apiLoadingOverlayEnd());
    }
  }, [loadingStates.some(isLoading => isLoading)]);
};

export default useApiLoadingOverlay;