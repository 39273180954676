import React from "react";
import classes from "./styles.module.scss";

const InputField = ({ noMargin, disabled, onBlur, label, type, width, height, value, name, placeholder, onChange, autoComplete, readonly, marginTop, marginLeft, required, maxLength, pattern, minLength }) => {

    return (
        <div className={!noMargin ? classes.mg20 : ""}>
            {label && <label className={classes.label} htmlFor="input-field">{label} {required ? <i>*</i> : ""}</label>}
            {/* <br /> */}
            <input
                required={required}
                style={{ marginTop, marginLeft, width, height }}
                className={classes.input}
                type={type}
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                readonly={readonly}
                onBlur={onBlur}
                disabled={disabled}
                maxLength={maxLength}
                max={maxLength}
                pattern={pattern}
                minLength={minLength}
            />
        </div>
    )
}

export default InputField;