import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  message: null,
  error: null,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    apiLoadingOverlay(state) {
      state.loading = true;
    },
    apiLoadingOverlayEnd(state) {
      state.loading = false;
    },
    apiMessage(state, action) {
      state.message = action.payload;
      state.error = null;
    },
    apiErrorMessage(state, action) {
      state.error = action.payload;
      state.message = null;
    },
  },
});

export const {
  apiLoadingOverlay,
  apiLoadingOverlayEnd,
  apiMessage,
  apiErrorMessage,
} = loadingSlice.actions;

export default loadingSlice.reducer;
