import * as actionTypes from './actionTypes';
import { apiFunction } from "../../apiCalls/functions"
import { ADMIN_LOGIN, LOGIN, SETTER_LOGIN, CHECKER_LOGIN, COUNSELLOR_LOGIN, GET_PERMISSIONS, REVIEWER_LOGIN } from "../../apiCalls/urls/login"
import { actionNotifier } from "../../components/ui/toast";
import { navItems } from '../../container/layout/leftBar/json';
import { toggleNavIsOpen } from '../nav/reducer';
import { apiLoadingOverlay, apiLoadingOverlayEnd } from '../loader/loadingSlice';
import { FaUserGraduate, FaCalendarAlt, FaUser, FaMapMarkerAlt, FaListAlt, FaClipboardList, FaRegCalendarCheck, FaRegAddressBook, FaRegNewspaper, FaRegFileAlt, FaRegObjectGroup, FaRegCreditCard, FaCog, FaRegIdBadge, FaRegIdCard, FaRegListAlt } from 'react-icons/fa';
import { LuClipboardSignature } from "react-icons/lu";
import DiscountIcon from '@mui/icons-material/Discount';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
//AllAPIS


// [
//     {
//         "role": "counselor",
//         "permissions": [
//             "counselling:dashboard",
//             "counselling:students",
//             "counselling:availability",
//             "counselling:profile"
//         ]
//     }
// ]

const getTitleIcon = (title) => {
  switch (title) {
    case 'User':
      return <FaRegIdCard />;
    case 'View All Users':
      return <FaRegIdBadge />;
    case 'Orders':
      return <FaRegFileAlt />;
    case 'View All Orders':
      return <FaRegObjectGroup />;
    case 'Question Papers':
      return <FaRegNewspaper />;
    case 'View Question Papers':
      return <FaRegFileAlt />;
    case 'Schedule':
      return <FaRegCalendarCheck />;
    case 'View Schedules':
      return <FaRegFileAlt />;
    case 'Syllabus':
      return <FaRegAddressBook />;
    case 'View Syllabus':
      return <FaRegFileAlt />;
    case 'Plans & Fees':
      return <FaRegCreditCard />;
    case 'View Plans':
      return <FaRegListAlt />;
    case 'View Customize Fees':
      return <LuClipboardSignature />;
    case 'View Bundle Fees':
      return <FaRegFileAlt />;
    case 'Coupons':
      return <DiscountIcon sx={{ fontSize: "17px" }} />;
    case 'View All Coupons':
      return <FaRegFileAlt />;
    case 'Configurations':
      return <FaCog />;
    case 'Course':
      return <FaCog />;
    case 'Session':
      return <FaCog />;
    case 'Group':
      return <FaCog />;
    case 'Subject':
      return <FaCog />;
    case 'Chapter':
      return <FaCog />;
    case 'Portion':
      return <FaCog />;
    case 'Service':
      return <FaCog />;
    case 'GST':
      return <FaCog />;
    case 'Portion to Group Mapping':
      return <FaCog />;
    case 'Checker':
      return <FaRegCalendarCheck />;
    case 'View Checkers':
      return <FaRegFileAlt />;
    case 'Uploaded Answer':
      return <QuestionAnswerIcon sx={{ fontSize: "17px" }} />;
    case 'View Paper':
      return <FaRegFileAlt />;
    case 'Counselors':
      return <FaRegAddressBook />;
    case 'View Counselors':
      return <FaRegFileAlt />;
    case 'View Counselings':
      return <FaRegFileAlt />;
    case 'Setter':
      return <FaRegAddressBook />;
    case 'Reviewer':
      return <FaRegAddressBook />;
    default:
      return null;
  }
};




export const generateMenu = (permissions) => {

  let completeMenu = [];

  let allPermissions = permissions?.map((p) => p.permissions).flat()
  navItems.forEach((d) => {
    if (d.checkValue == 'all')
      completeMenu.push(d)
    // let findIndex = permissions.findIndex(f => f.role == d.checkValue);
    // if (findIndex != "-1") {
    //     completeMenu.push(d)
    // }
    let obj = {}
    let find = allPermissions.find(f => f == d.checkValue);


    if (find) {
      let linkMenu = [];
      d.links.forEach((l) => {
        let findLink = allPermissions.find(f => f === l.checkValue);
        if (findLink) {
          linkMenu.push({ ...l, icon: getTitleIcon(l.title) });
        }
      });
      let obj = {
        name: d.name,
        open: d.open,
        icon: getTitleIcon(d.name),
        links: linkMenu.map(link => ({
          ...link,
          icon: getTitleIcon(link.title)
        }))
      };
      completeMenu.push(obj);
    }
    // find.links.forEach((fd) => {
    //     let find = fd.findIndex(f => f.role == fd.checkValue);
    //     linkMenu.push(fd)

    // })
  })

  return completeMenu
}

export const fetchLoginDetails = async (data) => {
  return await apiFunction(ADMIN_LOGIN, 'post', data, false, false)
}
export const fetchSetterLoginDetails = async (data) => {
  return await apiFunction(SETTER_LOGIN, 'post', data, false, false)
}
export const fetchCheckerLoginDetails = async (data) => {
  return await apiFunction(CHECKER_LOGIN, 'post', data, false, false)
}

export const fetchCounsellorLoginDetails = async (data) => {
  return await apiFunction(COUNSELLOR_LOGIN, 'post', data, false, false)
}

export const fetchOnBoardingUserPermissions = async (type) => {
  return await apiFunction(GET_PERMISSIONS(type), 'get', {}, true, false)
}

export const fetchReviewerLoginDetails = async (data) => {
  return await apiFunction(REVIEWER_LOGIN, 'post', data, false, false)
}



// const { navigateByRoleHomepage } = usePath()

export function onLogin(email, password, type, navigate) {


  return async (dispatch) => {
    dispatch(apiLoadingOverlay())
    let data = {
      "email": email,
      "password": password
    }
    if (type == 'admin') {
      let result = await fetchLoginDetails(data);
      if (result.status) {
        localStorage.setItem("prepca-admin-login-data", JSON.stringify(result.data))
        let menuItem = generateMenu(result.data.rolesAndPermissions)

        dispatch(updateLoginDetails(result.data, menuItem))

        if (result.data.rolesAndPermissions[0].role === "counselorAdmin") {
          navigate('/admin/counselor/onboarding');
          dispatch(toggleNavIsOpen({ name: 'Onboarding', open: false }));
        } else if (result.data.rolesAndPermissions[0].role === "setterAdmin") {
          navigate('/admin/setter/onboarding');
          dispatch(toggleNavIsOpen({ name: 'Onboarding', open: false }));
        } else if (result.data.rolesAndPermissions[0].role === "reviewerAdmin") {
          navigate('/admin/reviewer/onboarding');
          dispatch(toggleNavIsOpen({ name: 'Onboarding', open: false }));
        } else {
          dispatch(toggleNavIsOpen({ name: 'Orders', open: false }));
          navigate('/admin/view-orders');
        }

      }
      else {

        actionNotifier.error("Invalid credentials.")
      }
    } else if (type == 'setter') {
      let result = await fetchSetterLoginDetails(data);
      // let dataLS = localStorage.getItem("prepca-admin-login-data")

      const isAlreadyRoleGiven = result?.data?.rolesAndPermissions?.find((r) => r.role === 'reviewer')
      if (result.status) {
        localStorage.setItem("prepca-admin-login-data", JSON.stringify(result.data))

        let menuItem = generateMenu(result.data.rolesAndPermissions)

        dispatch(updateLoginDetails(result.data, menuItem))
        dispatch(toggleNavIsOpen({ name: 'Students', open: false }));
        if (result?.data?.rolesAndPermissions[0]?.role === "setterBoarding") {
          if (!isAlreadyRoleGiven) {
            navigate('/setter/onboarding');
            dispatch(toggleNavIsOpen({ name: 'Onboarding', open: false }));
          } else {
            navigate('/setter/dashboard');
          }
        } else if (result?.data?.rolesAndPermissions[0]?.role === "setter") {
          navigate('/setter/dashboard');
        } else if (result?.status) {
          navigate("/setter/dashboard")
        } else {
          navigate("/")
        }

        // window.location.reload(true)
      }
    }
    else if (type == 'reviewer') {
      let result = await fetchReviewerLoginDetails(data);
      // let dataLS = localStorage.getItem("prepca-admin-login-data")

      const isAlreadyRoleGiven = result?.data?.rolesAndPermissions?.find((r) => r.role === 'reviewer')

      if (result.status) {
        localStorage.setItem("prepca-admin-login-data", JSON.stringify(result.data))

        let menuItem = generateMenu(result.data.rolesAndPermissions)

        dispatch(updateLoginDetails(result.data, menuItem))
        dispatch(toggleNavIsOpen({ name: 'Students', open: false }));

        if (result?.data?.rolesAndPermissions[0]?.role === "reviewerBoarding") {
          if (!isAlreadyRoleGiven) {
            navigate('/reviewer/onboarding');
            dispatch(toggleNavIsOpen({ name: 'Onboarding', open: false }));
          } else {
            navigate('/reviewer/dashboard');
          }
        } else if (result?.data?.rolesAndPermissions[0]?.role === "reviewer") {
          navigate('/reviewer/dashboard');
        } else if (result?.status) {
          navigate("/reviewer/dashboard")
        } else {
          navigate("/")
        }

        // window.location.reload(true)
      }
    }
    else if (type == 'checker') {
      let result = await fetchCheckerLoginDetails(data);
      if (result.status) {
        localStorage.setItem("prepca-admin-login-data", JSON.stringify(result.data))

        let menuItem = generateMenu(result.data.rolesAndPermissions)

        dispatch(updateLoginDetails(result.data, menuItem))
        navigate('/checker/view-papers/pending');
        // window.location.reload(true)
        dispatch(toggleNavIsOpen({ name: 'Papers', open: true }));

      }
      else {

        actionNotifier.error("Invalid credentials.")
      }
    }
    else if (type == 'counselor') {
      let result = await fetchCounsellorLoginDetails(data);
      // let dataLS = localStorage.getItem("prepca-admin-login-data")

      const isAlreadyRoleGiven = result?.data?.rolesAndPermissions?.find((r) => r.role === 'counselor')
      if (result.status) {


        localStorage.setItem("prepca-admin-login-data", JSON.stringify(result.data))

        let menuItem = generateMenu(result.data.rolesAndPermissions)

        dispatch(updateLoginDetails(result.data, menuItem))

        if (result.data.rolesAndPermissions[0].role === "boarding") {
          if (!isAlreadyRoleGiven) {
            navigate('/counselor/onboarding');
            dispatch(toggleNavIsOpen({ name: 'Onboarding', open: false }));

          } else {
            navigate('/counselor/dashboard');
            dispatch(toggleNavIsOpen({ name: 'Dashboard', open: false }));

          }
        } else if (result.data.rolesAndPermissions[0].role === "counselor") {
          dispatch(toggleNavIsOpen({ name: 'Dashboard', open: false }));
          navigate('/counselor/dashboard');
        } else {
          navigate("/")
        }

        // window.location.reload(true)
      }

    }
    else {

      actionNotifier.error("Invalid credentials.")
    }


    dispatch(apiLoadingOverlayEnd())
  }
}


export const onLogout = (navigate) => {
  return async (dispatch) => {

    dispatch(apiLoadingOverlay())
    localStorage.removeItem("prepca-admin-login-data")
    dispatch(updateLoginDetails([], []))
    navigate("/")
    setTimeout(() => {
      dispatch(apiLoadingOverlayEnd())
    }, 200)
  }
}


// ACTIONS
export const updateLoginDetails = (data, menu) => {
  return {
    type: actionTypes.UPDATE_LOGIN_DETAILS,
    payload: { data, menu }
  };
}

export const importAdminDataFromLocal = () => {
  return (dispatch) => {
    let data = localStorage.getItem("prepca-admin-login-data")

    if (data) {
      data = JSON.parse(data)
      let menuItem = generateMenu(data.rolesAndPermissions)


      dispatch(updateLoginDetails(data, menuItem))
    }
  }
}
