import Auth from "./auth/reducer";
import Notification from "./notification/reducer";
import ViewOrders from "../pages/viewOrders/reducer";
import ViewOrdersDetails from "../pages/viewOrderDetailsPage/reducer";
import AddQuestions from "../pages/addQuestion/reducer";
import QuestionPaper from "../pages/viewQuestionPaper/reducer";
import PublicDataDetails from "./publicData/reducer";
import ScheduleData from "../pages/viewSchedules/reducer"
import SyllabusData from "../pages/viewSyllabus/reducer"
import PlanDetails from "../pages/viewPlans/reducer"
import FeeData from "../pages/viewFees/reducer"
import CouponData from "../pages/viewCoupons/reducer"
import AddCourses from "../pages/configurationsPage/course/reducer";
import ViewSubject from "../pages/configurationsPage/subject/reducer"
import ViewSyllabus from "../pages/configurationsPage/syllabus/reducer"
import ViewChapter from "../pages/configurationsPage/chapter/reducer"
import ViewPortion from "../pages/configurationsPage/portion/reducer"
import ViewGroup from "../pages/configurationsPage/group/reducer"
import ViewSession from "../pages/configurationsPage/session/reducer"
import ViewGst from "../pages/configurationsPage/gst/reducer"
import ViewService from "../pages/configurationsPage/sservices/reducer"
import UserReducer from "../pages/viewUser/reducer"
import AnswerReducer from "../pages/ViewStudentsPapers/reducer"
import CheckerReducer from "../pages/viewCheckers/reducer"
import CheckerAnswerReducer from "../pages/checkerPage/viewPapers/reducer"
import setCurrentTheme from "./theme/reducer"
import setIsSidebar from "./nav/reducer";

const rootReducer = {
  Notification,
  Auth,
  ViewOrders,
  ViewOrdersDetails,
  AddQuestions,
  QuestionPaper,
  PublicDataDetails,
  ScheduleData,
  SyllabusData,
  PlanDetails,
  FeeData,
  CouponData,
  AddCourses,
  ViewSubject,
  ViewSyllabus,
  ViewChapter,
  ViewPortion,
  ViewGroup,
  ViewSession,
  ViewGst,
  ViewService,
  UserReducer,
  AnswerReducer,
  CheckerReducer,
  CheckerAnswerReducer,
  setCurrentTheme,
  setIsSidebar
};

export default rootReducer;
