import React, { useEffect, useRef, useState } from "react";
import classes from "./styles.module.scss";
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FileDownloadIcon from '@mui/icons-material/AddCircle';
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../container/layout/topBar/Breadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CustomizedSwitches from "../switchButton";
import Filter from "../filter";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import MuiSelect from "../muiSelect";
import { isUndefined } from "../../shared/isNullEmptyUndefined";
import { useGetSessionsQuery } from "../../store/common/commonApiSlice";
import { Box, Button, TextField } from "@mui/material";

const Heading = (props) => {
    const navigate = useNavigate()

    const SwitchBtn = () => {
        return (
            <div>
                {/* <label className={`${classes.switch}`}>
                    <input type="checkbox" />
                    <span className={`${classes.slider} ${classes.round}`}></span>
                </label> */}
                <CustomizedSwitches />
            </div>
        );
    };

    const [isOpenFilters, setIsOpenFilters] = useState(false)

    const handleChangeFilter = (evt, name) => {
        if (name === "sessionId") {
            props.setSessionId(evt.target.value)
        } else if (name === "counsellorId") {
            props.setCounsellorId(evt.target.value)
        }
    }

    const { data: sessionData } = useGetSessionsQuery()



    const handleApplyFilter = () => {
        if (props.dateRange.startDate && props.dateRange.endDate) {
            console.log('Date Range:', {
                startDate: props.dateRange.startDate.toISOString(),
                endDate: props.dateRange.endDate.toISOString(),
            });
        } else {
            ;
        }
    };

    const endDateRef = useRef(null);

    const handleStartDateChange = (e) => {
        props.setDateRange((prev) => ({ ...prev, startDate: e.target.value }));
        if (endDateRef.current) {
            endDateRef.current.focus();
            endDateRef.current.click();
        }
    };

    const handleEndDateChange = (e) => {
        props.setDateRange((prev) => ({ ...prev, endDate: e.target.value }));
        // if (props.dateRange.startDate && e.target.value) {

        // }
    };


    return (
        <div className={classes.heading} style={props.style}>
            <div className={classes.heading_content}>
                <div className={classes.head_name}>
                    {props.backButton ? <ArrowBackIcon onClick={() => navigate(-1)} /> : null}
                    <span>{props.text}</span>
                </div>
                <div className={classes.action}>
                    {
                        props?.exportToCSV ?
                            <div className={classes.csv}
                                onClick={props?.exportToCSV}
                            >
                                <FileDownloadIcon />
                                <span>Export CSV</span>
                            </div> : ""
                    }

                    {
                        props?.path !== "#" && props?.path !== '/' ?
                            <a href={props?.path} className={classes.add}>
                                <button className={classes.btn}>
                                    <AddCircleIcon /> &nbsp;
                                    <span>{props.btn_name}</span>
                                </button>
                            </a>
                            : ""
                    }
                    {
                        props.onSearch ?
                            <div className={classes.search}>
                                <input type="search"
                                    placeholder="Search"
                                    name="search"
                                    value={props.search}
                                    onChange={props.onSearch}
                                />
                                <SearchIcon className={classes.search_icon} />
                                {/* <ArrowDropDownIcon className={classes.down} /> */}
                            </div> : ""
                    }

                </div>
            </div>
            <div className={classes.breadcrumb_actions}>
                <Breadcrumbs />

                <div className={classes.btn_container}>


                    {props?.dateRange && <Box display="flex" alignItems="center" gap={0.5}>
                        {/* Start Date Input */}
                        <TextField
                            type="date"
                            value={props?.dateRange?.startDate}
                            onChange={handleStartDateChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                style: {
                                    fontSize: "12px",
                                    padding: "6px",
                                },
                            }}
                            size="small"
                            placeholder="Start Date"
                            sx={{
                                width: "120px",
                                "& .MuiInputBase-root": {
                                    height: "32px",
                                },
                            }}
                        />

                        <div>-</div>
                        <TextField
                            type="date"
                            value={props?.dateRange?.endDate}
                            onChange={handleEndDateChange}

                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                style: {
                                    fontSize: "12px",
                                    padding: "6px",
                                },
                            }}
                            size="small"
                            placeholder="End Date"
                            inputRef={endDateRef}
                            sx={{
                                width: "120px",
                                "& .MuiInputBase-root": {
                                    height: "32px",
                                },
                            }}
                            disabled={!props?.dateRange?.startDate}
                        />

                    </Box>}


                    {!isUndefined(props?.sessionId) ?
                        <MuiSelect
                            firstValue={true} options={sessionData?.data || []}
                            value={props?.sessionId || process.env.REACT_APP_CURRENT_SESSION}
                            onChange={(e) => handleChangeFilter(e, "sessionId")}
                        /> : null
                    }
                    {!isUndefined(props?.counsellorId) ?
                        <MuiSelect
                            firstValue={true} options={props?.counselorList?.rows || []}
                            value={props?.counsellorId}
                            onChange={(e) => handleChangeFilter(e, "counsellorId")}
                        /> : null
                    }

                    {
                        props?.upload ?
                            <div className={classes.csv}
                                onClick={() => props?.upload(true)}
                            >
                                <FileUploadIcon />
                                <span>Upload Counseling</span>
                            </div> : ""
                    }
                    {props.isGraphShow && <div className={classes.btn_item_container}>
                        Graph
                        <SwitchBtn />
                    </div>}
                    {props.isFilterShow && <div onClick={() => setIsOpenFilters(true)} className={classes.btn_item_container}>
                        <FilterAltIcon fontSize='small' />
                        Filter
                    </div>}
                </div>
            </div>
            <Filter handleApplyFilter={props.handleApplyFilter} reset={props.clearFilter} filterState={props.filterState} updateRecordFilter={props.updateRecordFilter} close={() => setIsOpenFilters(false)} open={isOpenFilters} />

        </div>
    )
}

export default Heading;