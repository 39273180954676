import { createApi } from '@reduxjs/toolkit/query/react';
import * as API from '../../apiCalls/urls/counsellorAdmin';
import { baseQuery } from '../../apiCalls/rtkBaseQuery';



export const counsellorAdminApi = createApi({
  baseQuery,
  reducerPath: 'counsellorAdminApi',
  tagTypes: ['counselorListAdmin', 'counselorDetailsAdmin', 'grades', 'counselorMappingAdmin',
    'counselingListAdmin', 'certificate', 'availability', 'counsellingCount'],
  endpoints: (builder) => ({

    getCounselorList: builder.query({
      query: ({ page = 1, items = 10, filters }) => ({
        url: `${API.COUNSELOR_LIST}?page=${page}&items=${items}`,
        method: 'POST',
        body: filters || {}
      }),
      providesTags: ['counselorListAdmin'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0
    }),

    getCounselorDetails: builder.query({
      query: ({ type, cId }) => ({
        url: `${API.COUNSELOR_DETAILS(type)}/${cId}`,
        method: 'GET',
      }),
      providesTags: ['counselorDetailsAdmin'],
    }),
    getCounselorMappings: builder.query({
      query: ({ id, sessionId }) => ({
        url: `${API.COUNSELOR_MAPPING}/${id}`,
        method: 'GET',
        params: sessionId !== null && sessionId !== undefined ? { sessionId } : {},
      }),
      providesTags: ['counselorMappingAdmin'],
    }),

    updateCounselor: builder.mutation({
      query: ({ data, id }) => ({
        url: `${API.UPDATE_COUNSELOR}/${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['counselorDetailsAdmin'],
    }),

    createCounselor: builder.mutation({
      query: (data) => ({
        url: API.CREATE_COUNSELOR,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['counselorListAdmin'],
    }),

    updateCounselorContract: builder.mutation({
      query: (data) => ({
        url: API.UPDATE_COUNSELOR_CONTRACT,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['counselorDetailsAdmin'],
    }),

    addCounselorMapping: builder.mutation({
      query: ({ data, id }) => ({
        url: API.ADD_COUNSELOR_MAPPING + "/" + id,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['counselorMappingAdmin'],
    }),

    removeCounselorMapping: builder.mutation({
      query: ({ data, id }) => ({
        url: API.REMOVE_COUNSELOR_MAPPING + "/" + id,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['counselorMappingAdmin'],
    }),

    getGrades: builder.query({
      query: () => ({
        url: API.GET_GRADES,
        method: 'GET',
      }),
      providesTags: ['grades'],
    }),
    getCounselingList: builder.query({
      query: ({ page = 1, items = 10, filters }) => ({
        url: `${API.COUNSELING_LIST}?page=${page}&items=${items}`,
        method: 'POST',
        body: filters || {}
      }),
      providesTags: (result, error, { page, items, filters }) => [
        { type: 'counselingListAdmin', page, items, filters },
      ],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0
    }),
    getCounselingDetails: builder.query({
      query: (id) => ({
        url: `${API.GET_COUNSELING_DETAILS}/${id}`,
        method: 'GET',
      }),
    }),

    addExperiance: builder.mutation({
      query: ({ data, cId }) => ({
        url: API.ADD_EXPERIENCE + "/" + cId,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['counselorDetailsAdmin'],
    }),

    updateExperiance: builder.mutation({
      query: ({ data, cId }) => ({
        url: API.UPDATE_EXPERIENCE + "/" + cId,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['counselorDetailsAdmin'],
    }),

    deleteExperiance: builder.mutation({
      query: ({ expId, cId }) => ({
        url: API.DELETE_EXPERIENCE + "/" + cId,
        method: 'DELETE',
        body: expId
      }),
      invalidatesTags: ['counselorDetailsAdmin'],
    }),

    addEducation: builder.mutation({
      query: ({ data, cId }) => ({
        url: API.ADD_EDUCATION + "/" + cId,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['counselorDetailsAdmin'],
    }),

    updateEducation: builder.mutation({
      query: ({ data, cId }) => ({
        url: API.UPDATE_EDUCATION + "/" + cId,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['counselorDetailsAdmin'],
    }),

    deleteEducation: builder.mutation({
      query: ({ eduId, cId }) => ({
        url: API.DELETE_EDUCATION,
        method: 'DELETE',
        body: eduId
      }),
      invalidatesTags: ['counselorDetailsAdmin'],
    }),

    updateCounselorProfile: builder.mutation({
      query: ({ preference, data, cId }) => {
        return {
          url: API.UPDATE_COUNSELOR_PROFILE + "/" + cId,
          method: 'POST',
          body: !data ? { preference: preference } : data,
        }
      },
      invalidatesTags: ['counselorDetailsAdmin'],
    }),

    getCertificatesLists: builder.query({
      query: (cId) => ({
        url: API.GET_CERTIFICATE_LIST + "/" + cId,
        method: 'GET',
      }),
      providesTags: ['certificate'],
    }),

    deleteCertificate: builder.mutation({
      query: ({ certiId, cId }) => ({
        url: API.DELETE_CERTIFICATE + "/" + cId,
        method: 'DELETE',
        body: certiId
      }),
      invalidatesTags: ['certificate'],
    }),

    addCertificate: builder.mutation({
      query: ({ data, cId }) => ({
        url: API.ADD_CERTIFICATE + "/" + cId,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['certificate'],
    }),
    updateCertificate: builder.mutation({
      query: ({ data, cId }) => ({
        url: API.UPDATE_CERTIFICATE + "/" + cId,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['certificate'],
    }),

    getCertificatesLink: builder.mutation({
      query: (certificate) => ({
        url: API.GET_CERTIFICATE_LINK,
        method: 'POST',
        body: certificate
      }),
    }),
    addIntroductoryVideo: builder.mutation({
      query: ({ file, cId }) => ({
        url: API.ADD_INTRODUCTORY_VIDEO + "/" + cId,
        method: 'POST',
        body: file,
      }),
      invalidatesTags: ['counselorDetailsAdmin'],
    }),
    deleteIntroductoryVideo: builder.mutation({
      query: ({ cId }) => ({
        url: API.DELETE_INTRODUCTORY_VIDEO + "/" + cId,
        method: 'DELETE',
        // body: introVId
      }),
      invalidatesTags: ['counselorDetailsAdmin'],
    }),
    updateSocialLink: builder.mutation({
      query: ({ linkData, cId }) => ({
        url: API.UPDATE_SOCIAL_LINK + "/" + cId,
        method: 'POST',
        body: linkData,
      }),
      invalidatesTags: ['counselorDetailsAdmin'],
    }),
    getCounsellingCount: builder.query({
      query: (data) => ({
        url: data ? `${API.GET_COUNSELLOR_COUNT}` : API.GET_COUNSELLOR_COUNT,
        method: 'POST',
        body: data,
      }),
      providesTags: ['counsellingCount'],
      keepUnusedDataFor: 0
    }),

    getCounsellorAvailability: builder.query({
      query: (data) => ({
        url: API.COUNSELLOR_AVAILABILITY,
        method: 'POST',
        body: { counselorId: Number(data) }
      }),
      providesTags: ['availability'],
    }),

    addAvailability: builder.mutation({
      query: ({ data }) => ({
        url: API.ADD_AVAILABILITY,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['availability'],
    }),

    // updateAvailability: builder.mutation({
    //   query: ({data, cId}) => ({
    //     url: UPDATE_AVAILABILITY + "/" + cId,
    //     method: 'POST',
    //     body: data,
    //   }),
    // }),

    getCounsellorDropdown: builder.mutation({
      query: ({ data }) => ({
        url: API.GET_COUNSELLOR_DROPDOWN,
        method: 'POST',
        body: data,
      }),
      // providesTags: ['slots'],
    }),

    getCounsellorSlots: builder.mutation({
      query: ({ cId, counsellingId }) => ({
        url: API.GET_COUNSELLOR_AVAILABILITY_SLOT + "/" + cId + "/" + counsellingId,
        method: 'GET',
      }),
      // providesTags: ['availability'],
    }),
    updateCounselling: builder.mutation({
      query: ({ data, counsellingId }) => ({
        url: API.UPDATE_COUNSELLING + "/" + counsellingId,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['counsellingCount', 'counselorListAdmin'],
    }),
    assignCounselling: builder.mutation({
      query: (data) => ({
        url: API.ASSIGN_COUNSELLING,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['counselingListAdmin'],
    }),
    cancelCounselling: builder.mutation({
      query: ({ data, counsellingId }) => ({
        url: API.CANCEL_COUNSELLING + "/" + counsellingId,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['counselingListAdmin'],
    }),
    acceptRescheduledCounseling: builder.mutation({
      query: (counselingId) => ({
        url: API.ACCEPT_RESCHEDULED_COUNSELING + counselingId,
        method: 'POST',
      }),
      invalidatesTags: ['counselling', "counsellingCount"],
    }),
    getAvailabilitiesCounts: builder.mutation({
      query: ({ data }) => ({
        url: API.GET_AVAILABILITIES_COUNTS,
        method: 'POST',
        body: data
      }),
      // keepUnusedDataFor: 0
    }),
    getAvailabilitiesSlotLists: builder.mutation({
      query: ({ data, filters }) => {
        const { page, items } = filters;
        return {
          url: `${API.GET_AVAILABILITIES_SLOTS_LIST}?page=${page}&items=${items}`,
          method: 'POST',
          body: data
        };
      },
      keepUnusedDataFor: 0
    }),
    getDashboard: builder.query({
      query: (filter) => ({
        url: API.GET_DASHBOARD,
        method: 'POST',
        body: filter
      }),
      keepUnusedDataFor: 0
    }),
    exportCsv: builder.mutation({
      query: (filter) => ({
        url: API.EXPORT_CSV,
        method: 'POST',
        body: filter
      }),
      keepUnusedDataFor: 0
    }),
  }),
});

export const {
  useGetCounselorListQuery,
  useGetCounselorDetailsQuery,
  useUpdateCounselorMutation,
  useAddCounselorMappingMutation,
  useCreateCounselorMutation,
  useGetGradesQuery,
  useRemoveCounselorMappingMutation,
  useLazyGetCounselorDetailsQuery,
  useGetCounselorMappingsQuery,
  useGetCounselingListQuery,
  useGetCounselingDetailsQuery,
  useAddExperianceMutation,
  useUpdateExperianceMutation,
  useDeleteExperianceMutation,
  useAddEducationMutation,
  useUpdateEducationMutation,
  useDeleteEducationMutation,
  useUpdateCounselorProfileMutation,
  useGetCertificatesListsQuery,
  useDeleteCertificateMutation,
  useAddCertificateMutation,
  useUpdateCertificateMutation,
  useGetCertificatesLinkMutation,
  useAddIntroductoryVideoMutation,
  useDeleteIntroductoryVideoMutation,
  useUpdateSocialLinkMutation,
  useGetCounsellingCountQuery,
  useGetCounsellorAvailabilityQuery,
  useAddAvailabilityMutation,
  // useUpdateAvailabilityMutation,
  useGetCounsellorDropdownMutation,
  useGetCounsellorSlotsMutation,
  useUpdateCounsellingMutation,
  useAssignCounsellingMutation,
  useAcceptRescheduledCounselingMutation,
  useGetAvailabilitiesCountsMutation,
  useGetAvailabilitiesSlotListsMutation,
  useGetDashboardQuery,
  useExportCsvMutation
} = counsellorAdminApi;

export const { endpoints } = counsellorAdminApi;
export const { getCounselingList } = endpoints;