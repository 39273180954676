import { createSlice } from "@reduxjs/toolkit";
import { fetchOnBoardingSteps } from "../../pages/onBoardingAdmin/onBoardingAdminApiSlice"; // Assuming fetchOnBoardingSteps is exported

const initialState = {
  steps: [],
  BoardingDetails:[], 
  userType: '',
  loading: false,
  error: null,
  currentStep: [],
  isVisitedBefore: localStorage.getItem("prepca-onborading-isVisitedBefore") ? true : false
};

const onBoardingSlice = createSlice({
  name: 'onBoarding',
  initialState,
  reducers: {
    setSteps: (state, action) => {
      state.steps = action.payload.steps;
      state.userType = action.payload.userType;
    },
    setBoardingDetails: (state, action) => {
      state.BoardingDetails = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setIsVisitedBefore: (state, action) => {
      state.isVisitedBefore = action.payload;
    },
    clearOnBoardingStoreData: (state, action) => {
      state = initialState;
    },
  }
});

export const { setSteps, setBoardingDetails, setCurrentStep, setIsVisitedBefore, clearOnBoardingStoreData } = onBoardingSlice.actions;

export const selectOnBoardingSteps = (state) => state.onBoarding.steps;
export const selectOnBoardingDetails = (state) => state.onBoarding.BoardingDetails;
export const selectCurrentStep = (state) => state.onBoarding.currentStep;
export const selectUserType = (state) => state.onBoarding.userType;
export const selectOnBoardingLoading = (state) => state.onBoarding.loading;
export const selectOnBoardingError = (state) => state.onBoarding.error;
export const selectIsVisitedBefore = (state) => state.onBoarding.isVisitedBefore;



export const { useGetOnBoardingStepsQuery } = fetchOnBoardingSteps;

export default onBoardingSlice.reducer;
