export const GET_PAPER_SYLLABUS_BY_PAPER_ID = '/setter/paper/syllabus/';
export const CREATE_SYLLABUS = '/setter/paper/create-syllabus';
export const CREATE_SYLLABUS_100 = '/setter/paper/create-syllabus-100';
export const SEND_SYLLABUS_FOR_REVIEW = '/setter/paper/send-syllabus-for-review/';
export const SEND_QUESTION_FOR_REVIEW = '/setter/paper/send-paper-for-review/';

export const UPLOAD_QUESTION_BY_PAPER_ID = '/setter/paper/upload-question/';
export const CREATE_PAPER_MAPPING_BY_PAPER_ID = '/setter/paper/add-question-mapping/';
export const UPDATE_QUESTIONS_BY_PAPER_ID = '/setter/paper/update-question/';
export const UPLOAD_ANSWER_BY_PAPER_ID = '/setter/paper/upload-answer/';
export const DELETE_QUESTION_MAPPING_BY_ID = '/setter/paper/delete-questions';
export const GET_QUESTION_MAPPING_BY_PAPER_ID = '/setter/paper/mapping/';
export const GET_PAPER_DETAILS_BY_PAPER_ID = '/setter/paper/details/';
export const PAPER_LIST = '/setter/paper/list';
export const GET_SELECTED_SYLLABUS_BY_PAPER_ID = '/setter/paper/selected-syllabus/';
export const UPLOAD_PAPER_MAPPING_BY_PAPER_ID = '/setter/paper/upload-question-mapping-csv/';

export const UPLOAD_QUESTION_HTML_BY_PAPER_ID = '/setter/paper/upload-html-question/';
export const UPLOAD_ANSWER_HTML_BY_PAPER_ID = '/setter/paper/upload-html-answer/';
export const CREATE_OBJECTIVE_BY_PAPER_ID = '/setter/paper/add-question-mapping/';


export const SETTER_PROFILE = '/setter/auth/profile';
export const UPDATE_SOCIAL_LINK = '/setter/auth/social-link';
export const UPDATE_SETTER_PROFILE = '/setter/auth/update-profile';

export const GET_CERTIFICATE_LINK = '/setter/auth/get-certificate-link';
export const ADD_CERTIFICATE = '/setter/auth/certificate';
export const ADD_EDUCATION = '/setter/auth/education';
export const UPDATE_EDUCATION = '/setter/auth/education';
export const DELETE_EDUCATION = '/setter/auth/education';
export const UPDATE_CERTIFICATE = '/setter/auth/certificate';
export const DELETE_CERTIFICATE = '/setter/auth/certificate';

export const ADD_INTRODUCTORY_VIDEO = '/setter/auth/add-introductory-video';
export const DELETE_INTRODUCTORY_VIDEO = '/setter/auth/delete-introductory-video';

export const GET_CERTIFICATE_LIST = '/setter/auth/certificate';

export const ADD_EXPERIENCE = '/setter/auth/experience';
export const UPDATE_EXPERIENCE = '/setter/auth/experience';
export const DELETE_EXPERIENCE = '/setter/auth/experience';

export const GET_MAPPING_LIST = '/setter/auth/mapping';
export const SETTER_DASHBOARD = '/setter/paper/dashboard';