import React, { useState, useEffect } from "react";
import classes from "./styles.module.scss";
import { Grid, MenuItem, Select } from '@mui/material/';
const MuiSelect = (props) => {
    return (
        <div className={`${""} ${classes.syllabusSelect}`}>
            {props.label ? <><label style={{ color: props.ltextColor }} >{props.label}</label><br /></> : null}
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.value || " "}
                className={`${classes.mui_select} ${props.color === 'grey' ? classes.mui_select_grey : classes.mui_select_white}`}
                onChange={props.onChange}

            >
                {props.firstValue ? <MenuItem value={" "}><em>Please Select</em></MenuItem> : null}
                {props.options.map((c, i) => (
                    <MenuItem key={i} value={c.id}>
                        {c.name || c.label}
                    </MenuItem>
                ))}
            </Select>
        </div >
    );
};
export default MuiSelect;
