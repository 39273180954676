import React, { useEffect } from "react";
import classes from "./styles.module.scss";
import TopBar from "./topBar";
import LeftBar from "./leftBar";
import { setSidebarOpen } from "../../store/nav/reducer";
import { useDispatch, useSelector } from "react-redux";
import LeftBarIcons from "./leftBarIcons";

const Layout = (props) => {
    const dispatch = useDispatch()

    const isOpen = useSelector(state => state.setIsSidebar.isOpen);

    useEffect(() => {
        isOpen ? document.body.classList.add(classes.overflow_hidden) : document.body.classList.remove(classes.overflow_hidden);
        return () => {
            document.body.classList.remove(classes.overflow_hidden)
        }
    }, [isOpen])

    const handleOverlayClick = () => {
        dispatch(setSidebarOpen(!isOpen));
    };

    return (
        <>
            {isOpen ? <div onClick={handleOverlayClick} className={`${classes.nav_mobile_overlay}`}></div> : null}
            <TopBar />
            {isOpen ? <LeftBar type={props.type}>
                <div className={classes.contentTable}>
                    {props.children}
                </div>
            </LeftBar>
                :
                <LeftBarIcons type={props.type}>
                    <div className={classes.contentTable}>
                        {props.children}
                    </div>

                </LeftBarIcons>}
        </>
    )
}

export default Layout;