import * as actionTypes from './actionTypes'
const initialState = {
    filters: {
        "search": "",
        "filters": {
            // "subjectType": "elective",
            // "parentId": 4,
            // "groupId": 1
        },
        "sort": {
            "column": "id",
            "order": "desc"
        }
    },
    page: 1,
    count: 0,
    items: 10,
    allData: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SERVICE:
            state = {
                ...state,
                allData: action.payload.data
            }
            break;
        case actionTypes.UPDATE_FILTER:
            state = {
                ...state,
                filters: action.payload,
            }
            break;
        default:
            state = { ...state };
            break;

    }
    ;
    return state;
}

export default reducer;