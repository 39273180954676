import React, { useState, useEffect } from "react";
import { Grid, StepLabel } from '@mui/material/';
import InputSelect from "../../../../components/inputSelect";
import TextArea from "../../../../components/textArea";
// import CustomCheckbox from "../../customizePlanComponents/customCheckbox";
// import PageHeading from "../../pageHeading";
import classes from './styles.module.scss';
import { onLoad, fetchState, onSaveAddress, onEditAddress, fetchStateDistrict, fetchDistrictCity } from "../../.././../pages/checkout/actions";
import { connect, useSelector } from "react-redux";
import InputField from "../../../../components/inputField";
import PrimaryButton from "../../../../components/primaryButton";
import { apiLoading, apiLoadingEnd } from "../../../../store/notification/action";
import { actionNotifier } from "../../../../components/ui/toast";
import { useLocation, useParams } from "react-router-dom";
import Heading from "../../../../components/header";

const AddAddress = (props) => {

    const location = useLocation();
    const profileData = location.state?.profileData;
    const { id: orderId } = useParams()

    const [state, setState] = useState({
        studentFirstName: null,
        studentMiddleName: null,
        studentLastName: null,
        phone: null,
        address: null,
        cityId: null,
        districtId: null,
        pincode: null,
        state: null,
        email: null
    })
    const [uState, setUState] = useState([])
    const [uStateDistrict, setUStateDistrict] = useState([])
    const [uStateDistrictCity, setUStateDistrictCity] = useState([])

    useEffect(() => {
        fetchInitialData()
    }, [])
    const fetchInitialData = async () => {
        props.apiLoading()
        let res = await fetchState()
        if (res.status) {
            setUState(res.data)
        }
        props.apiLoadingEnd()

    }
    const fetchDistrict = async (e) => {
        let res = await fetchStateDistrict(e)
        if (res.status) {
            setUStateDistrict(res.data)
        }
    }
    const fetchCity = async (e) => {
        let res = await fetchDistrictCity(e)
        if (res.status) {
            setUStateDistrictCity(res.data)
        }
    }



    useEffect(() => {
        if (props.form_data) {
            setState({
                name: props?.form_data?.name,
                phone: props?.form_data?.phone.replace(/\D/g, '').slice(-10),
                address: props?.form_data?.address,
                cityId: props?.form_data?.cityId,
                districtId: props?.form_data?.districtId,
                pincode: props?.form_data?.pincode,
                state: props?.form_data?.District.stateId
            })
        } else {
            setState({
                studentFirstName: profileData.studentFirstName,
                studentMiddleName: profileData.studentMiddleName,
                studentLastName: profileData.studentLastName,
                phone: profileData?.mobileNo?.replace(/\D/g, '').slice(-10),
                address: profileData.address,
                cityId: profileData.cityId || null,
                districtId: profileData.districtId,
                pincode: profileData.pincode,
                state: profileData.stateId,
                email: profileData.email
            })
        }
    }, [props?.form_data, profileData]);

    useEffect(() => {
        // console.log(state?.state);
        if (state?.state) {
            fetchDistrict(Number(state?.state));
        }
    }, [state?.state]);

    useEffect(() => {
        // console.log(state?.districtId);
        if (state?.districtId) {
            fetchCity(Number(state?.districtId));
        }
    }, [state?.districtId]);

    const handleChange = (name, value) => {
        if (name == 'phone') {
            const maxLength = 10;
            if (value.length > maxLength) {
                value = value.slice(0, maxLength);
            }
        }
        if (name == 'pincode') {

            if (/[^0-9]/.test(value)) {
                value = value.replace(/[^0-9]/g, '');
            }
            const maxLength = 6;
            if (value.length >= maxLength) {
                value = value.slice(0, maxLength);
            }
        }

        let _state = { ...state };
        _state[name] = value;
        setState(_state)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        //props.isShown(false)        
        const regex = /^([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\s[0-9]{3})$/;

        if (!regex.test(state.pincode)) {
            actionNotifier.error("Not a valid pincode");
            return;
        }
        console.log(orderId)
        // if (state.pincode)
        //     if (props.form_data?.id) {
        //         props.onEditAddress(props.form_data?.id, state, props.isShown)
        //     }
        //     else {
        props.onEditAddress(state, orderId);
        // }
        props.onLoad()


    }


    return (
        <div className={classes.formContainer}>
            <Heading backButton={true} text="Go To Orders" path="#" />
            <form autoComplete="off" onSubmit={handleSubmit}>
                {/* <PageHeading head={<><i onClick={() => props.isShown()} className="fa fa-arrow-left"></i> Add Address</>} /> */}
                <Grid mt={1} container item xs={12} sm={12} spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <InputField
                            // disabled={true}
                            required={true}
                            type="text"
                            value={state.studentFirstName}
                            name="studentFirstName"
                            placeholder="Enter First Name"
                            label="First Name"
                            noMargin={true}
                            onChange={(e) => handleChange("studentFirstName", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputField
                            // disabled={true}
                            required={true}
                            type="text"
                            value={state.studentMiddleName}
                            name="studentMiddleName"
                            placeholder="Enter Middle Name"
                            label="Middle Name"
                            noMargin={true}
                            onChange={(e) => handleChange("studentMiddleName", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputField
                            // disabled={true}
                            required={true}
                            type="text"
                            value={state.studentLastName}
                            name="studentLastName"
                            placeholder="Enter Last Name"
                            label="Last Name"
                            noMargin={true}
                            onChange={(e) => handleChange("studentLastName", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputField
                            // disabled={true}
                            type="number"
                            required={true}
                            value={state.phone}
                            name="phone"
                            placeholder="Enter Phone Number"
                            label="Phone Number"
                            noMargin={true}
                            onChange={(e) => handleChange("phone", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputField
                            // disabled={true}
                            type="text"
                            required={true}
                            value={state.email}
                            name="email"
                            placeholder="Enter Email Id"
                            label="Email Id"
                            noMargin={true}
                            onChange={(e) => handleChange("email", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputSelect
                            emptyValue={true}
                            required={true}
                            value={state?.state}
                            options={uState}
                            name="state"
                            placeholder="Enter State"
                            label="State"
                            noMargin={true}
                            onChange={(e) => handleChange("state", e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <InputSelect
                            emptyValue={true}
                            required={true}
                            value={state?.districtId}
                            options={uStateDistrict}
                            name="districtId"
                            placeholder="Enter City"
                            label="District"
                            noMargin={true}
                            onChange={(e) => handleChange("districtId", e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <InputSelect
                            emptyValue={true}
                            required={true}
                            options={uStateDistrictCity}
                            value={state?.cityId}
                            name="cityId"
                            placeholder="Enter Area"
                            label="Area"
                            noMargin={true}
                            onChange={(e) => handleChange("cityId", e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <InputField
                            emptyValue={true}
                            required={true}
                            type="text"
                            value={state?.pincode}
                            name="pincode"
                            placeholder="Enter Pincode"
                            label="Pincode"
                            noMargin={true}
                            maxLength={6}
                            onChange={(e) => handleChange("pincode", e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} spacing={2}>
                    <Grid item sm={12}>
                        <TextArea
                            required={true}
                            value={state.address}
                            name="address"
                            placeholder="Enter Complete Address"
                            label="Complete Address"
                            onChange={(e) => handleChange("address", e.target.value)}
                            noMargin={true}
                        />
                    </Grid>
                </Grid>

                <br />
                {/* <CustomCheckbox text="Same As Billing Address" /> */}
                <PrimaryButton
                    marginTop="20px"
                    buttonText={"Save Address"}
                    fontSize="12px"
                    width="50%"
                    color="#fff"
                    backgroundColor="#005ca8"
                />
            </form>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        address: state.Address,
        auth: state.Auth
    }
}
const mapDispatchToProps = { onLoad, onSaveAddress, onEditAddress, apiLoading, apiLoadingEnd }
export default connect(mapStateToProps, mapDispatchToProps)(AddAddress);
