import * as actionTypes from './actionTypes';

const initialState = {
    filters: {
        "search": "",
        "filters": {
            // "questionId": 25,
            // "studentId": 1,
            // "checkerId": 1,
            // "answerState": "completed"
        },
        "rangeFilters": {

        },
        "sort": {
            "column": "id",
            "order": "asc"
        },
        "additionalFilters": {
            // "subjectId": 1,
            // "sessionId": 1,
            // "portionId": 1
        }

    },
    page: 1,
    count: 0,
    items: 10,
    allData: [],
    singleData: [],
    paperCount: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ANSWER_LIST:
            state = {
                ...state,
                allData: action.payload.data,
                count: action.payload.count
            }
            break;
        case actionTypes.UPDATE_SINGLE_ANSWER_INFO:
            state = {
                ...state,
                singleData: action.payload.data
            }
            break;
        case actionTypes.UPDATE_FILTER:
            state = {
                ...state,
                filters: action.payload,
            }
            break;
        case actionTypes.UPDATE_REDUCER:
            state = {
                ...state,
                [action.payload.item]: action.payload.data,
            }
            break;
        default:
            state = { ...state };
            break;

    }
    ;
    return state;
}

export default reducer;