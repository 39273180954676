import React from 'react';
import { useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { toggleNavIsOpen } from '../../../store/nav/reducer';
import { useDispatch } from 'react-redux';
import usePath from '../../../hooks/usePath';

const Breadcrumbs = () => {
    const dispatch = useDispatch()

    const { homePath, defaultMenu, paths } = usePath()

    const handleDefaultMenuOpen = () => {
        dispatch(toggleNavIsOpen(defaultMenu));
    }

    return (
        <div style={{ display: "flex", fontSize: "13px", fontWeight: "500" }}>
            <Link onClick={handleDefaultMenuOpen} to={homePath}> Home </Link>
            {paths.map((value, index) => {
                // const routeTo = `/${paths.slice(0, index + 1).join('>')}`;
                const isLast = index === paths.length - 1;
                return (
                    <div style={{ display: "flex", alignItems: "center" }} key={value}>
                        <div style={{ padding: "0px 6px" }}>{'>'}</div>
                        {isLast ? <div>{decodeURI(value)}</div> : <div>{decodeURI(value)}</div>}
                        {/* {!isLast && ''} */}
                    </div>
                );
            })}
        </div>
    );
};

export default Breadcrumbs;
