import { actionNotifier } from "../components/ui/toast";

export const getReason = (status) => {
    const reasons = status?.slice()?.reverse()?.find((s, i) => s?.status === "CANCELLED_BY_STUDENT" || s?.status === "RESCHEDULED_BY_STUDENT" || s?.status === "RESCHEDULED_BY_COUNSELOR")
    // const reasons = status?.slice()?.reverse()?.find((s, i) => s?.status === activeTab)
    return reasons ? reasons?.extra?.reason : null
}

export const camelToSentenceCase = (str) => {
    const result = str.replace(/([a-z])([A-Z])/g, "$1 $2");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
};

export const convertToTitleCase = (str) => {
    return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

export const convertSnakeCaseToUpperCase = (str) => {
    return str
        .split('_')
        .join(' ')
        .toUpperCase();
};

export const getMeetingTime = (data) => {
    const lastUpdatedMeeting = data?.slice()?.reverse()?.find((s, i) => s?.status === "UPDATED")
    // const lastUpdatedMeeting = false
    const appliedMeeting = data?.slice()?.reverse()?.find((s, i) => s?.status === "APPLIED")

    return lastUpdatedMeeting?.extra?.onDateTime
        ? { time: lastUpdatedMeeting?.extra?.onDateTime, type: "U" }
        : { time: appliedMeeting?.extra?.onDateTime, type: "A" };
};

export const throwError = (error, msg) => {
    if (error?.data?.statusCode === 400) {
        actionNotifier.error(`${error.data.message}`)
    } else {
        actionNotifier.error(`${msg}`)
    }
};