import * as actionTypes from './actionTypes';

const initialState = {
    filters: {
        "search": "",
        "filters": {
            // "studentId": 0,
            // "couponId": 0,
            // "centreInCityId": 0,
            // "testPreferenceId": 0,
            // "referralId": 0,
            // "sessionId": 0,
            // "syllabusId": 0,
            // "isActive": true,
            // "isCancelled": true,
            "isDeleted": false
        },
        "rangeFilters": {
            // "orderedBetween": ["2022-09-13T17:19:47.019Z", "2022-09-14T07:35:34.282Z"],
            // "cancelledBetween": ["2022-09-13T17:19:47.019Z", "2022-09-14T07:35:34.282Z"],
            // "paidBetween": ["2022-09-13T17:19:47.019Z", "2022-09-14T07:35:34.282Z"],
            // "deletedBetween": ["2022-09-13T17:19:47.019Z", "2022-09-14T07:35:34.282Z"]
        },
        "sort": {
            "column": "id",
            "order": "desc"
        },
        "additionalFilters": {
            // "planId": 1,
            // "subjectId": 10,
            // "courseId": 1
        }
    },
    page: 1,
    count: 0,
    items: 10,
    allData: [],
    orderPaper: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ORDER:
            state = {
                ...state,
                allData: action.payload.data,
                count: action.payload.count
            }
            break;
        case actionTypes.UPDATE_FILTER:
            state = {
                ...state,
                filters: action.payload,
            }
            break;
        case actionTypes.UPDATE_ORDER_PAPER:
            state = {
                ...state,
                orderPaper: action.payload,
            }
            break;
        case actionTypes.UPDATE_REDUCER:
            state = {
                ...state,
                [action.payload.item]: action.payload.data,
            }
            break;
        default:
            state = { ...state };
            break;

    }
    ;
    return state;
}

export default reducer;