// src/themes.js
export const adminTheme = {
  bg1: '#005ca8', //final
  bg2: '#f8f8f9', //final
  bg3: '#ffffff',
  bg4: '#6abcff86',
  bg5: "#a0d4ff86",
  bg6: "#F8F8F8",
  text1: '#ffffff',  //final
  text2: '#000000',
  text3: '#F1F1F1',
  text4: '#000000', //final
  fill1: '#9096ac',
  fill2: '#005ca8',

};

export const setterTheme = {
  bg1: '#193312',  //final
  bg2: '#eaedea',  //final
  bg3: '#ffffff',
  bg4: '#e7ebe2',
  bg5: '#eee197', //active submenu
  bg6: "#FFF6F6",
  text1: 'white',  //final
  text2: '#193312',
  text3: '#acb5aa',
  text4: '#000000', //final 
  fill1: '#ffffff',
  fill2: '#ffffff'
};

export const checkerTheme = {
  bg1: '#E4CC37',  //final
  bg2: '#eaedea',  //final
  bg3: '#ffffff',
  bg4: '#ecdc83',
  bg5: '#eee197', //active submenu
  bg6: "#F8F8F8", //submenu bg
  text1: '#ffffff',  //final
  text2: '#000000',
  text3: 'blue',
  text4: '#000000', //final
  fill1: '#ffffff',
  fill2: '#ffffff'
};

export const counsellorTheme = {
  bg1: '#193312',  //final For Header BG
  bg2: '#eaedea',  //final for MAIN bg
  bg3: '#ffffff', // Nav SideBar Card Color
  bg4: "#aedea1",  //Hover On Navmenu
  bg5: '#ceebc6', //active submenu
  bg6: "#F8F8F8", //submenu bg
  text1: '#ffffff',  //final for Header Text
  text2: '#000000',  //final for Navmenu Text
  text3: '#aedea1',// final for profile unique Id
  text4: '#000000',
  fill1: 'red',
  fill2: 'red'
};
