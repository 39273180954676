export const COUNSELLOR_PROFILE = '/counselor/auth/profile';
export const UPDATE_SOCIAL_LINK = '/counselor/auth/social-link';
export const UPDATE_COUNSELOR_PROFILE = '/counselor/auth/update-profile';
export const GET_CERTIFICATE_LINK = '/counselor/auth/get-certificate-link';
export const ADD_CERTIFICATE = '/counselor/auth/certificate';
export const ADD_EDUCATION = '/counselor/auth/education';
export const UPDATE_EDUCATION = '/counselor/auth/education';
export const DELETE_EDUCATION = '/counselor/auth/education';
export const UPDATE_CERTIFICATE = '/counselor/auth/certificate';
export const DELETE_CERTIFICATE = '/counselor/auth/certificate';
export const REJECT_COUNSELING = '/counselor/reject-counseling/';
export const ACCEPT_COUNSELING = '/counselor/accept-counseling/';
export const RESCHEDULE_COUNSELING = '/counselor/reschedule-counseling';
export const AVAILABILITY_LIST = '/counselor/availability/list';
export const UPDATE_AVAILABILITY = '/counselor/availability/update';
export const COUNSELLOR_LIST = '/counselor/list';
export const COUNSELLOR_AVAILABILITY = '/counselor/availability/list';
export const ADD_INTRODUCTORY_VIDEO = '/counselor/auth/add-introductory-video';
export const DELETE_INTRODUCTORY_VIDEO = '/counselor/auth/delete-introductory-video';

export const ADD_AVAILABILITY = '/counselor/availability/update';
export const GET_CERTIFICATE_LIST = '/counselor/auth/certificate';
export const GET_COUNSELING_DETAILS = '/counselor/counseling-details/';
export const GET_MAPPING_LIST = '/counselor/auth/mapping';

export const ADD_EXPERIENCE = '/counselor/auth/experience';
export const UPDATE_EXPERIENCE = '/counselor/auth/experience';
export const DELETE_EXPERIENCE = '/counselor/auth/experience';

export const GET_COUNSELLOR_COUNT = '/counselor/counseling/count';
export const CANCEL_COUNSELING = '/counselor/cancel-counseling/';

export const GET_DASHBOARD = '/counselor/dashboard';
export const GET_STUDENT_LIST = '/counselor/student-list';
export const GET_STUDENT_COUNSELING_LIST_BY_ORDERID = '/counselor/student-pending-counseling';
export const GET_STUDENT_COUNSELING_LIST_COUNT_BY_ORDERID = '/counselor/student-pending-counseling/count';



