export const isUndefined = (value) => {
    return value === undefined;
};

// replace value with undefined if value Is ""
export const sanitizeArrayOfObjects = (array) => {
    const sanitizeValue = (value) => value === "" ? null : value;

    return array.map(obj => {
        return Object.keys(obj).reduce((acc, key) => {
            if (Array.isArray(obj[key])) {
                acc[key] = sanitizeArrayOfObjects(obj[key]);
            } else {
                acc[key] = sanitizeValue(obj[key]);
            }
            return acc;
        }, { ...obj });
    });
};

export const sanitizeObject = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        acc[key] = value === "" ? null : value;
        return acc;
    }, {});
};