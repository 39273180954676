import React from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { useSearchParams } from 'react-router-dom';
import "./multiSelect.css"

const MultiselectReactDropdown = ({ options, onSelect, onRemove, value, onSearch, placeholder }) => {

    const [searchParams] = useSearchParams();
    const cIdArray = searchParams.getAll('cId').map(Number);
    const counselorIdsArray = searchParams.getAll('counselorIds').map(Number);
    const counselorIds = [...new Set([...cIdArray, ...counselorIdsArray])];


    const selectedValues = counselorIds.map(val => options?.find(option => option.id === val)).filter(Boolean);
    return (
        <Multiselect
            displayValue="name"
            hideSelectedList
            onKeyPressFn={function noRefCheck() { }}
            onRemove={onRemove}
            onSearch={onSearch}
            onSelect={onSelect}
            options={options}
            showCheckbox
            selectedValues={selectedValues}
            // placeholder={placeholder}
            placeholder={
                selectedValues.length <= 2
                    ? selectedValues.map((counselor) => counselor.name).join(', ')
                    : placeholder
            }
            className={'custom-placeholder'}
            style={{
                optionContainer: {
                    border: '1px solid black',
                    borderRadius: '4px',
                },
                option: {
                    color: '#005ca8',
                    backgroundColor: 'rgba(0, 92, 168, 0.11)',
                },
                // selectedValues: {
                //     backgroundColor: 'red',
                //     color: 'blue'
                // },
                searchBox: {
                    backgroundColor: 'rgba(0, 92, 168, 0.11)',
                    color: "#005ca8",
                    "::placeholder": {
                        color: '#005ca8'
                    },
                    placeholderColor: selectedValues.length > 0 ? "blue" : "black"

                },
            }}

        />
    );
};

export default MultiselectReactDropdown;
