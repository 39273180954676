import { connect, useDispatch } from "react-redux";
import classes from "./styles.module.scss";
import React from 'react'
import { importAdminDataFromLocal } from "../../../store/auth/actions";
import SideItem from "./sideItems";
import { toggleNavIsOpen } from "../../../store/nav/reducer";

const LeftBarIcons = (props) => {

    const dispatch = useDispatch()
    const setNavIsOpen = (data) => {
        dispatch(toggleNavIsOpen(data));
    }

    return (
        <div>

            <div className={classes.container}>
                <div className={`${classes.sidebar}`}>
                    <div className={classes.card} >
                        <div className={classes.card_head}>
                        </div>
                        <div className={classes.card_body}>
                            <div className={classes.left_bar}>
                                {/* <div className={classes.content}>
                                    {props?.auth?.navItems?.map((navItem, index) => {
                                        return (
                                            <SideItem
                                                type={props?.type}
                                                key={index}
                                                to={navItem.to}
                                                items={navItem}
                                                setNavIsOpen={setNavIsOpen}
                                            />
                                        );
                                    })}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.right_bar}>{props.children}</div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.Auth,
    };
};

const mapDispatchToProps = { importAdminDataFromLocal };

export default connect(mapStateToProps, mapDispatchToProps)(LeftBarIcons);