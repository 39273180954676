// import axios from 'axios';
import { setUploadProgress, setUploadComplete, setUploadFailed } from '../redux/uploadSlice';
import * as API from '../../../apiCalls/urls/setterUser';
import { axios } from '../../../shared/axios';
import { getToken } from '../../../apiCalls/rtkBaseQuery';
import { throwError } from '../../../shared/counsellorFunction';
import { actionNotifier } from '../../../components/ui/toast';

export const uploadPaperMapping = async (paperId, data, dispatch, setSelectedFileName) => {
    try {
        const response = await axios.post(`${API.UPLOAD_PAPER_MAPPING_BY_PAPER_ID}${paperId}`, data, {
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                dispatch(setUploadProgress(progress));
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${getToken()}`,
                institutionId: process.env.REACT_APP_INSTITUTION_ID
            },
        });

        dispatch(setUploadComplete());
        setSelectedFileName("")
        actionNotifier.success("Paper Mapping Uploaded Successfully");
        return response;
    } catch (error) {

        dispatch(setUploadFailed());
        setSelectedFileName("")
        throwError(error, "Paper Mapping Upload Failed");
    }
};


export const uploadPaperQuestions = async (paperId, data, dispatch, setSelectedFileName) => {
    try {
        const response = await axios.post(`${API.UPLOAD_QUESTION_BY_PAPER_ID}${paperId}`, data, {
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                dispatch(setUploadProgress(progress));
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${getToken()}`,
                institutionId: process.env.REACT_APP_INSTITUTION_ID
            },
        });

        dispatch(setUploadComplete());
        setSelectedFileName("")
        actionNotifier.success("Paper Questions Uploaded Successfully");
        return response;
    } catch (error) {

        dispatch(setUploadFailed());
        setSelectedFileName("")
        throwError(error, "Paper Mapping Upload Failed");
    }
};

export const uploadPaperAnswers = async (paperId, data, dispatch, setSelectedFileName) => {
    try {
        const response = await axios.post(`${API.UPLOAD_ANSWER_BY_PAPER_ID}${paperId}`, data, {
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                dispatch(setUploadProgress(progress));
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${getToken()}`,
                institutionId: process.env.REACT_APP_INSTITUTION_ID
            },
        });

        dispatch(setUploadComplete());
        setSelectedFileName("")
        actionNotifier.success("Paper Answers Uploaded Successfully");
        return response;
    } catch (error) {

        dispatch(setUploadFailed());
        setSelectedFileName("")
        throwError(error, "Paper Mapping Upload Failed");
    }
};