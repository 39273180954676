import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import Routes from "./routes"
import { fetchOnBoardingUserPermissions, generateMenu, importAdminDataFromLocal, updateLoginDetails } from "./store/auth/actions";
import Loader from "./components/ui/overlay"
import Toast from "./components/ui/toast";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { setCurrentTheme } from "./store/theme/action";
import TopLoader from "./components/toploader";
import CustomConfirmDialogMui from "./container/onBordingShared/customConfirmMui";
import usePath from "./hooks/usePath";


function App(props) {
    const dispatch = useDispatch();
    const { pathnames } = usePath()
    const loading = useSelector((state) => state.loader.loading);

    // useEffect(() => {
    //     ;
    //     dispatch(setCurrentTheme())
    // },[])
    ;
    useEffect(() => {
        props.importAdminDataFromLocal();
    }, [])

    useEffect(() => {
        let data = localStorage.getItem("prepca-admin-login-data")
        const isAlreadyRoleGiven = JSON.parse(data)?.rolesAndPermissions?.find((r) => r.role === 'counselor' || r.role === 'setter' || r.role === 'reviewer')

        const getPermission = async () => {
            const resp = await fetchOnBoardingUserPermissions(pathnames[0])
            const updatedData = { ...JSON.parse(data), rolesAndPermissions: resp.data }
            if (updatedData) {
                data = JSON.parse(data)
                let menuItem = generateMenu(resp.data)


                dispatch(updateLoginDetails(data, menuItem))
                localStorage.setItem("prepca-admin-login-data", JSON.stringify(updatedData))
            }
        }
        if ((pathnames[0] === 'counselor' || pathnames[0] === 'setter' || pathnames[0] === 'reviewer') && !isAlreadyRoleGiven) {
            getPermission()
        }
    }, [])

    return (
        <>
            <div className="App">
                <Routes />
                <Toast />

                {loading ? <Loader active={loading} /> : null} {/* loads while mutation */}
                <TopLoader loading={props.notification.loading} />

            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        notification: state.Notification,
    }
}

const mapDispatchToProps = { importAdminDataFromLocal }

export default connect(mapStateToProps, mapDispatchToProps)(App);