import React from "react";
import classes from "./styles.module.scss";

const TextArea = ({ noMargin, label, type, value, name, placeholder, onChange, autoComplete, readonly, marginTop, required }) => {
    //console.log(value)
    return (
        <div className={!noMargin ? classes.mg20 : ""}>
            {label && <label className={classes.label} htmlFor="input-field">{label} {required ? <i>*</i> : ""}</label>}
            {/* <br /> */}
            <textArea
                style={{ marginTop }}
                readonly={readonly}
                required={required}
                className={classes.input}
                type={type}
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
            >
                {value}
            </textArea>
        </div>
    )
}

export default TextArea;