import { adminTheme, setterTheme, checkerTheme, counsellorTheme } from "./color";

// const initialState = 'admin_theme';
const initialState = adminTheme;

const setCurrentTheme = (state = initialState, action) => {

    switch (action.type) {
        case "admin": return state = adminTheme;
        case "setter": return state = setterTheme;
        case "checker": return state = checkerTheme;
        case "counselor": return state = counsellorTheme;
        default: return state;
    }
}

export default setCurrentTheme;