import * as actionTypes from './actionTypes';

const initialState = {
    filters: {
        "search": "",
        "filters": {
            // "id": 1,
            // "firstName": "",
            // "middleName": "",
            // "lastName": "",
            // "email": "",
            // "mobileNo": "",
            // "genderId": 2,
            // "cityId": 1,
            // "districtId": 1
        },
        "rangeFilters": {
            //"createdBetween": ["2022-09-13T17:19:47.019Z", "2024-09-14T07:35:34.282Z"]
        },

        sort: { column: "id", order: "desc" },
        "additionalFilters": {

        }
    },
    page: 1,
    count: 0,
    items: 10,
    allData: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_USER:
            state = {
                ...state,
                allData: action.payload.data,
                count: action.payload.count
            }
            break;
        case actionTypes.UPDATE_FILTER:
            state = {
                ...state,
                filters: action.payload,
            }
            break;
        case actionTypes.UPDATE_REDUCER:
            state = {
                ...state,
                [action.payload.item]: action.payload.data,
            }
            break;
        default:
            state = { ...state };
            break;

    }
    ;
    return state;
}

export default reducer;