export const colors = [
    {
        "admin": {
            "bgColor": "#005ca8",
            "menuColor": "#002f56",
            "hColor": "#757a8d",
            "aColor": "#a0d4ff86"
        },

        "setter": {
            "bgColor": "green",
            "menuColor": "lightgreen",
            "hColor": "lime",
            "aColor": ""
        },
        "checker": {
            "bgColor": "teal",
            "menuColor": "lightgreen",
            "hColor": "lime",
            "aColor": ""
        }
    }
]

export const navItems = [
    {
        checkValue: 'users:view_all',
        name: "User",
        links: [
            { title: "View All Users", to: "/admin/view-user", type: "setter", checkValue: 'users:view_all' },
        ],
        open: false,
    },
    {
        checkValue: 'orders:view_all',
        name: "Orders",
        links: [
            { title: "View All Orders", to: "/admin/view-orders", type: "setter", checkValue: 'orders:view_all' },
        ],
        open: false,
    },
    {
        checkValue: 'question_papers:view_all',
        name: "Question Papers",
        links: [
            { title: "View Question Papers", to: "/admin/view-question", checkValue: 'question_papers:view_all' },
        ],
        open: false,
    },
    // {
    //     name: "Referrals",
    //     links: [
    //         {title: "View Referrals", to: "/view-referrals"}
    //     ],
    //     open: false,
    // },
    // {
    //     name: "Feedback",
    //     links: [
    //         {title: "View Feedbacks", to: "/view-feedbacks"}
    //     ],
    //     open: false,
    // },
    {
        checkValue: 'schedule:view_all',
        name: "Schedule",
        links: [
            { title: "View Schedules", to: "/admin/view-schedules", checkValue: 'schedule:view_all' }
        ],
        open: false,
    },
    {
        checkValue: 'syllabus:view_all',
        name: "Syllabus",
        links: [
            { title: "View Syllabus", to: "/admin/view-syllabus", checkValue: 'syllabus:view_all' }
        ],
        open: false,
    },
    {
        checkValue: 'plans:view_all',
        name: "Plans & Fees",
        links: [
            { title: "View Plans", to: "/admin/view-plans", checkValue: "plans:view_all" },
            { title: "View Customize Fees", to: "/admin/view-customize-fees", checkValue: 'custom_fees:view_all' },
            { title: "View Bundle Fees", to: "/admin/view-bundle-fees", checkValue: 'bundle_fees:view_all' }
        ],
        open: false,
    },
    {
        checkValue: 'coupons:view_all',
        name: "Coupons",
        links: [
            { title: "View All Coupons", to: "/admin/view-coupons", checkValue: 'coupons:view_all' },
        ],
        open: false,
    },
    {
        checkValue: "conf_course:add",
        name: "Configurations",
        links: [
            { title: "Course", to: "/admin/configurations/add-course", checkValue: "conf_course:add" },
            { title: "Session", to: "/admin/configurations/add-session", checkValue: "conf_session:add" },
            { title: "Syllabus", to: "/admin/configurations/add-syllabus", checkValue: "conf_syllabus:add" },
            { title: "Group", to: "/admin/configurations/add-group", checkValue: "conf_group:add" },
            { title: "Subject", to: "/admin/configurations/add-subject", checkValue: "conf_subject:add" },
            { title: "Chapter", to: "/admin/configurations/add-chapter", checkValue: "conf_chapter:add" },
            { title: "Portion", to: "/admin/configurations/add-portion", checkValue: "conf_portion:add" },
            { title: "Service", to: "/admin/configurations/services", checkValue: "conf_service:add" },
            { title: "GST", to: "/admin/configurations/gst", checkValue: "conf_gst:add" },
            { title: "Portion to Group Mapping", to: "/admin/configurations/portion-to-group-map", checkValue: "conf_p2g:add" },
        ]
    },
    {
        checkValue: 'checkers:view_all',
        name: "Checker",
        links: [
            { title: "View Checkers", to: "/admin/view-checkers", checkValue: 'checkers:view_all' },
        ],
        open: false,
    },
    {
        checkValue: 'papers:view_all',
        name: "Uploaded Answer",
        links: [
            { title: "View Paper", to: "/admin/view-students-paper/new", checkValue: 'papers:view_all' },
            { title: "View Feedback", to: "/admin/view-feedbacks", checkValue: 'papers:view_all' },
        ],
        open: false,
    },
    {
        checkValue: 'counsellors:view_all',
        name: "Counselors",
        links: [
            { title: "Dashboard", to: "/admin/counselor/dashboard", checkValue: "counselings:view_all" },
            { title: "View Onboardings", to: "/admin/counselor/onboarding", checkValue: "counselings:view_all" },
            { title: "View Applications", to: "/admin/counselor/onboarding/application", checkValue: "counsellors:view_all" },
            { title: "View Availability", to: "/admin/counselors/availability-count?available=true", checkValue: "counselings:view_all" },
            { title: "View Counselors", to: "/admin/view-counselors", checkValue: "counsellors:view_all" },
            { title: "View Counselings", to: "/admin/counselors/view-counseling", checkValue: "counselings:view_all" },
            { title: "View Feedback", to: "/admin/counselor/view-feedbacks", checkValue: "counselings:view_all" },
        ],
        open: false,
    },
    {
        checkValue: "",
        name: "Papers (S)",
        links: [
            { title: "View Papers", to: "/setter/view-papers", checkValue: "" }
        ]
    },
    {
        checkValue: "",
        name: "Query",
        links: [
            { title: "View Queries", to: "/setter/view-queries", checkValue: "" }
        ]
    },
    {
        checkValue: "",
        name: "Profile",
        links: [
            { title: "View Profile", to: "/setter/view-profile", checkValue: "" }
        ]
    },
    {
        checkValue: "",
        name: "Feedback",
        links: [
            { title: "View Feedbacks", to: "/setter/feedback", checkValue: "" }
        ]
    },
    {
        checkValue: "",
        name: "Dashboard",
        links: [
            { title: "View Dashboard", to: "/setter/dashboard", checkValue: "" }
        ]
    },
    {
        checkValue: "",
        name: "Payment Reconciliation",
        links: [
            { title: "Payment Reconciliation", to: "/setter/payment-reconciliation", checkValue: "" }
        ]
    },
    {
        checkValue: "answers:view_answers",
        name: "Papers",
        links: [
            { title: "View Papers", to: "/checker/view-papers/pending", checkValue: "answers:view_answers" }
        ]
    },
    {
        checkValue: "data_checker",
        name: "Support",
        links: [
            { title: "Support", to: "https://support.prepca.com/", checkValue: "data_checker" }
        ],
        open: false
    },
    {
        checkValue: "counselling:dashboard",
        name: "Dashboard",
        links: [
            { title: "Dashboard", to: "/counselor/dashboard", checkValue: "counselling:dashboard" }
        ],
        open: false
    },
    {
        checkValue: "counselling:students",
        name: "Students",
        links: [
            { title: "Students", to: "/counselor/students", checkValue: "counselling:students" }
        ],
        open: false
    },
    {
        checkValue: "counselling:students",
        name: "Counselings",
        links: [
            { title: "Counselings", to: "/counselor/counseling", checkValue: "counselling:students" }
        ],
        open: false
    },
    {
        checkValue: "counselling:availability",
        name: "Availability",
        links: [
            { title: "Availability", to: "/counselor/availability", checkValue: "counselling:availability" }
        ],
        open: false
    },
    {
        checkValue: "counselling:profile",
        name: "Profile",
        links: [
            { title: "Profile", to: "/counselor/profile", checkValue: "counselling:profile" }
        ],
        open: false
    },
    {
        checkValue: "counselling:mapping",
        name: "Mapping",
        links: [
            { title: "Mapping", to: "/counselor/mapping", checkValue: "counselling:mapping" }
        ],
        open: false
    },
    {
        checkValue: "counselor:onboarding",
        name: "Onboarding",
        links: [
            { title: "Onboarding", to: "/counselor/onboarding", checkValue: "counselor:onboarding" }
        ],
        open: false
    },
    {
        checkValue: "setter:dashboard",
        name: "Dashboard",
        links: [
            { title: "Dashboard", to: "/setter/dashboard", checkValue: "setter:dashboard" }
        ],
        open: false
    },
    {
        checkValue: "setter:papers",
        name: "Papers",
        links: [
            { title: "Papers List", to: "/setter/view-papers", checkValue: "setter:papers" }
        ],
        open: false
    },
    {
        checkValue: "setter:mapping",
        name: "Mapping",
        links: [
            { title: "Mapping", to: "/setter/mapping", checkValue: "setter:mapping" },
        ],
        open: false
    },
    {
        checkValue: "setter:profile",
        name: "Profile",
        links: [
            { title: "Profile", to: "/setter/profile", checkValue: "setter:profile" }
        ],
        open: false
    },
    {
        checkValue: "setter:onboarding",
        name: "Onboarding",
        links: [
            { title: "Onboarding", to: "/setter/onboarding", checkValue: "setter:onboarding" }
        ],
        open: false
    },

    {
        checkValue: "reviewer:dashboard",
        name: "Dashboard",
        links: [
            { title: "Dashboard", to: "/reviewer/dashboard", checkValue: "reviewer:dashboard" }
        ],
        open: false
    },
    {
        checkValue: "reviewer:papers",
        name: "Papers",
        links: [
            { title: "Papers List", to: "/reviewer/view-papers", checkValue: "reviewer:papers" }
        ],
        open: false
    },
    {
        checkValue: "reviewer:mapping",
        name: "Mapping",
        links: [
            { title: "Mapping", to: "/reviewer/mapping", checkValue: "reviewer:mapping" },
        ],
        open: false
    },
    {
        checkValue: "reviewer:profile",
        name: "Profile",
        links: [
            { title: "Profile", to: "/reviewer/profile", checkValue: "reviewer:profile" }
        ],
        open: false
    },
    {
        checkValue: "reviewer:onboarding",
        name: "Onboarding",
        links: [
            { title: "Onboarding", to: "/reviewer/onboarding", checkValue: "reviewer:onboarding" }
        ],
        open: false
    },

    {
        checkValue: 'setters:view_all',
        name: "Setter",
        links: [
            { title: "Dashboard", to: "/admin/setter/dashboard", checkValue: "setters:view_all" },
            { title: "View Onboardings", to: "/admin/setter/onboarding", checkValue: "setters:view_all" },
            { title: "View Applications", to: "/admin/setter/onboarding/application", checkValue: "setters:view_all" },
            // { title: "View Availability", to: "/admin/setter/availability-count?available=true", checkValue: "counselings:view_all" },
            { title: "View Setters", to: "/admin/setter/view-all", checkValue: "setters:view_all" },
            { title: "View Papers", to: "/admin/setter/view-papers", checkValue: "setters:view_all" },
            { title: "View Setter Papers", to: "/admin/setter/papers", checkValue: "setters:view_all" },
            // { title: "View Counselings", to: "/admin/counselors/view-counseling", checkValue: "counselings:view_all" },
            // { title: "View Feedback", to: "/admin/setter/view-feedbacks", checkValue: "setterss:view_all" },
        ],
        open: false,
    },

    {
        checkValue: 'reviewers:view_all',
        name: "Reviewer",
        links: [
            { title: "Dashboard", to: "/admin/reviewer/dashboard", checkValue: "reviewers:view_all" },
            { title: "View Onboardings", to: "/admin/reviewer/onboarding", checkValue: "reviewers:view_all" },
            { title: "View Applications", to: "/admin/reviewer/onboarding/application", checkValue: "reviewers:view_all" },
            { title: "View Reviewers", to: "/admin/reviewer/view-all", checkValue: "reviewers:view_all" },
            { title: "View Papers", to: "/admin/reviewer/view-papers", checkValue: "reviewers:view_all" },
            { title: "View Setter Papers", to: "/admin/reviewer/papers", checkValue: "reviewers:view_all" },
        ],
        open: false,
    },

    // {
    //     checkValue: "all",
    //     name: "Logout",
    //     to: "/logout",
    //     open: false,
    // },
];