import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../apiCalls/rtkBaseQuery';

export const commonApiSlice = createApi({
  baseQuery,
  reducerPath: 'commonApiSlice',
  endpoints: (builder) => ({
    getReasons: builder.query({
      query: () => ({
        url: '/data/reasons',
        method: 'GET',
      }),
    }),
    getConfiguration: builder.query({
      query: () => ({
        url: '/data/configuration',
        method: 'GET',
      }),
    }),
    getCounselingPrices: builder.query({
      query: (id) => {
        if (id) {
          return {
            url: `${`/counselor/update-counseling-deduction/`}${id}`,
            method: 'GET',
          };
        }
        return undefined;
      },
    }),
    getDropdown: builder.mutation({
      query: (data) => {
        if (data) {
          return {
            url: `${`/data/dropdown/v2`}`,
            method: 'POST',
            body: data
          };
        }
        return undefined;
      },
    }),
    getSessions: builder.query({
      query: () => {
        return {
          url: `${`/data/dropdown/v2`}`,
          method: 'POST',
          body: { dropdown_code: 'sessions' }
        };
      }
    }),
    getLanguage: builder.query({
      query: (data) => {
        return {
          url: `/data/dropdown`,
          method: 'POST',
          body: data
        };
      },
    }),
    getReasonsByKey: builder.query({
      query: (key) => ({
        url: `/data/reasons/${key}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetReasonsQuery,
  useGetConfigurationQuery,
  useGetCounselingPricesQuery,
  useGetDropdownMutation,
  useGetReasonsByKeyQuery,
  useGetLanguageQuery,
  useGetSessionsQuery
} = commonApiSlice;

