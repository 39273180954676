import React, { useEffect, useRef, useState } from 'react'
import classes from "./styles.module.scss";
import logo from "../../../../assets/images/prepca-logo.png";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Avatar } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useGetAllQualityTestPaperQuery, useGetQualityTestPaperByIdQuery, useStartQualityTestMutation } from '../../../../pages/onBoardingUser/onBoardingUserApiSlice';
import StyledButton from '../../../../components/styledButton';
import { useNavigate, useSearchParams } from "react-router-dom";
import { actionNotifier } from '../../../../components/ui/toast';
import { throwError } from '../../../../shared/counsellorFunction';
import PrimaryButton from '../../../../components/buttons';

// const props?.allQualityTestPaperData?.questions = [
//     {
//         "text": "2 + 2 ?",
//         "type": "multiple",
//         "marks": 5,
//         "options": [
//             "4",
//             "5",
//             "9",
//             "1 + 3"
//         ]
//     },
//     {
//         "text": "5 = ?",
//         "type": "single",
//         "marks": 5,
//         "options": [
//             "10 - 5",
//             "10 - 2",
//             "15 - 10",
//             "15 - 2"
//         ]
//     },
//     {
//         "text": "5 = ?",
//         "type": "single",
//         "marks": 5,
//         "options": [
//             "1",
//             "12",
//             "10",
//             "999"
//         ]
//     },
// ];



const KnowledgeTest = (props) => {

    const { control, handleSubmit, watch, setValue } = useForm();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questionId, setQuestionId] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [markedForReview, setMarkedForReview] = useState([]);
    const navigate = useNavigate()
    const _watch = watch()

    const [searchParams, setSearchParams] = useSearchParams();
    const role = searchParams.get("role")
    const getSelectedOptions = (quesIndex, options, answers, type) => {


        const selectedOptions = [];
        if (type === "multiple") {
            for (let i = 0; i < options.length; i++) {
                const key = `question_${quesIndex}_${i}`;
                if (answers[key] === true) {
                    selectedOptions.push(options[i]);
                }
            }
        } else {
            const key = `question_${quesIndex}`;
            if (answers[key] !== true || answers[key] !== undefined) {
                selectedOptions.push(answers[key]);
            } else {
                alert("undefined")
            }
        }

        return selectedOptions;
    };

    const handleClearQuestion = () => {
        const currentQuestion = props?.allQualityTestPaperData?.questions[currentQuestionIndex];
        const questionType = currentQuestion?.type;
        const questionId = currentQuestion?.id;

        if (questionType === 'multiple') {
            currentQuestion?.options?.forEach((_, index) => {
                setValue(`question_${currentQuestionIndex}_${index}`, false);
            });
        } else {
            setValue(`question_${currentQuestionIndex}`, undefined);
        }

        const _answerData = {
            questionId: questionId,
            answer: []
        };

        props.saveTestAnswer({ data: _answerData, role }).unwrap().then(() => {
            actionNotifier.success('Question Answer Cleared');
        });
    };


    const handleNext = () => {
        const currentQuestion = props?.allQualityTestPaperData?.questions[currentQuestionIndex];
        const questionType = currentQuestion?.type;
        const questionId = currentQuestion?.id;

        const answer = getSelectedOptions(
            currentQuestionIndex,
            currentQuestion?.options,
            watch(),
            questionType
        );


        const isValidAnswer = Array.isArray(answer) && answer.length !== 0

        // if (isValidAnswer) {

        const _answerData = {
            questionId: questionId,
            answer: answer
        };

        props.saveTestAnswer({ data: _answerData, role }).unwrap().then(() => {
            setCurrentQuestionIndex(prevIndex => prevIndex + 1);
        });
        // } else {
        //     setCurrentQuestionIndex(prevIndex => prevIndex + 1);
        // }
    };

    useEffect(() => {
        const initializeSelectedOptions = () => {
            const question = props?.allQualityTestPaperData?.questions[currentQuestionIndex];
            const savedAnswer = props.allQualityTestPaperData?.answers
                ?.find(answer => answer.questionId === question?.id)?.answer;

            if (savedAnswer) {
                if (question?.type === 'multiple') {
                    // For multiple-choice questions
                    const initialSelection = question.options.reduce((acc, option, index) => {
                        acc[`question_${currentQuestionIndex}_${index}`] = savedAnswer.includes(option);
                        return acc;
                    }, {});
                    setSelectedOptions(initialSelection);
                } else {
                    // For single-choice questions
                    setSelectedOptions({ [`question_${currentQuestionIndex}`]: savedAnswer });
                }
            }
        };

        initializeSelectedOptions();
    }, [props.allQualityTestPaperData, currentQuestionIndex]);



    const isQuestionAttempted = (currentQuestionIndex, questionType) => {
        if (questionType == 'multiple') {
            for (let i = 0; i < 4; i++) {
                if (watch(`question_${currentQuestionIndex}_${i}`) !== undefined) {
                    return true;
                }
            }
            return false;
        } else {
            return watch(`question_${currentQuestionIndex}`) !== undefined;
        }
    }

    const isQuestionAnsweredAlready = (currentQuestionIndex, questionType, question_answer_index, questionId) => {
        const questionData = props?.allQualityTestPaperData?.questions[currentQuestionIndex];

        let answerIndex;
        let answer;
        if (questionType === "multiple") {
            [answerIndex] = question_answer_index?.split("_").slice(-1);
            answer = getSelectedOptions(
                currentQuestionIndex,
                questionData?.options,
                watch(),
                questionType
            );
        }


        if (questionType === "single") {
            [answerIndex] = question_answer_index?.split("_").slice(-1);
            answer = getSelectedOptions(
                currentQuestionIndex,
                questionData?.options,
                watch(),
                questionType
            );
        }

        const savedAnswer = props.allQualityTestPaperData?.answers
            ?.find(question => question.questionId === questionId)?.answer;

        if (questionType === 'multiple') {
            return savedAnswer?.includes(questionData?.options[answerIndex]);
        }
        else {
            return savedAnswer?.[0] === questionData?.options[answerIndex];
        }
    };

    const isQuestionAnsweredAlreadyDisableOptions = (currentQuestionIndex, questionType, questionId) => {

        if (props?.allQualityTestPaperData?.answers?.length === 0) {
            return false
        }
        const questionData = props?.allQualityTestPaperData?.questions[currentQuestionIndex];

        let answerIndex;
        let answer;
        if (questionType === "multiple") {
            answer = getSelectedOptions(
                currentQuestionIndex,
                questionData?.options,
                watch(),
                questionType
            );
        }


        if (questionType === "single") {
            answer = getSelectedOptions(
                currentQuestionIndex,
                questionData?.options,
                watch(),
                questionType
            );
        }

        const savedAnswer = props.allQualityTestPaperData?.answers
            ?.find(question => question.questionId === questionId);

        if (questionType === 'multiple') {
            return (savedAnswer?.answer.length !== 0 && savedAnswer?.answer) ? true : false;
        }
        else {
            return (savedAnswer?.answer.length !== 0 && savedAnswer?.answer) ? true : false;
        }
    };

    const handlePrevious = () => {
        setCurrentQuestionIndex(prevIndex => prevIndex - 1);
    };

    const handleMarkForReview = () => {
        if (!markedForReview.includes(currentQuestionIndex)) {
            setMarkedForReview([...markedForReview, currentQuestionIndex]);
        } else {
            setMarkedForReview(markedForReview.filter(index => index !== currentQuestionIndex));
        }
    };



    const onSubmit = async (data) => {
        try {
            const lastQuesIndex = props?.allQualityTestPaperData?.questions?.length - 1
            const answer = getSelectedOptions(lastQuesIndex, props?.allQualityTestPaperData?.questions[lastQuesIndex].options, watch(), props?.allQualityTestPaperData?.questions[lastQuesIndex].type)

            const _answerData = {
                "questionId": props?.allQualityTestPaperData?.questions[props?.allQualityTestPaperData?.questions?.length - 1].id,
                "answer": answer
            }
            const resSaveTest = await props.saveTestAnswer({ data: _answerData, role }).unwrap()

            if (resSaveTest) {
                actionNotifier.success('Test Answer Saved');
            }

            const _data = {
                "step": "test",
                "details": {
                    "test": "submit"
                }
            };

            const res = await props.submitTestAnswers({ data: _data, role }).unwrap();

            if (res) {
                actionNotifier.success('Test submitted successfully.');
                window.location.href = `/${role}/onboarding?stepId=5`;
            }
        } catch (error) {
            // actionNotifier.error('Error submitting the test. Please try again.');
            throwError(error, 'Error submitting the test. Please try again.')
            console.error('Error in onSubmit:', error);
        }
    };


    const [isFullscreen, setIsFullscreen] = useState(false);
    const appRef = useRef(null);

    const handleFullscreenChange = () => {
        setIsFullscreen(document.fullscreenElement !== null);
    };

    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        const preventExit = (event) => {
            if (event.keyCode === 27 && isFullscreen) {
                event.preventDefault();
            }
        };

        window.addEventListener('keydown', preventExit);

        return () => window.removeEventListener('keydown', preventExit);
    }, [isFullscreen]);

    const [timeLeft, setTimeLeft] = useState(60);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (!isSubmitted) { // Only start the timer if not submitted
            const timer = setInterval(() => {
                if (timeLeft > 0) {
                    setTimeLeft(prevTime => prevTime - 1);
                } else if (timeLeft === 0) {
                    setIsSubmitted(true);
                    handleSubmit(onSubmit)
                }
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [timeLeft, isSubmitted, handleSubmit]);


    useEffect(() => {
        if (props.allQualityTestPaperData) {
            setTimeLeft(Number(props?.allQualityTestPaperData?.timeLeftInSeconds))
            // setQuestionId(props?.allQualityTestPaperData?.questions?.[0]?.id)
        }

    }, [props.allQualityTestPaperData]);

    return (
        <div ref={appRef} className={classes.fullscreen}>
            <div className={classes.header}>
                <img src={logo} alt="logo" />

                <div className={classes.right}>
                    <AccessTimeIcon />
                    <h1>{Math.floor(timeLeft / 60).toString().padStart(2, '0')}:{(timeLeft % 60).toString().padStart(2, '0')}</h1>
                    <div className={classes.avatar}>
                        <Avatar />
                    </div>
                </div>
            </div>


            <div className={classes.test_main_container}>
                <form className={`${classes.test_container} ${classes.card}`} onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.heading}>
                        <h4>{props?.allQualityTestPaperData?.[0]?.name || "Knowledge Test"}</h4>
                        <div>{`Questions: ${currentQuestionIndex + 1 + "/" + props?.allQualityTestPaperData?.questions.length}`}</div>
                    </div>
                    <hr />
                    <div className={classes.ques_main_container}>
                        <div className={classes.qno_clearq_container}>
                            <div>{`Questions: ${currentQuestionIndex + 1}`}</div>
                            <div><PrimaryButton color={"blue"} type={"button"} padding={"6px 8px"} onClick={handleClearQuestion} buttonText={"reset answer"} /></div>
                        </div>
                        <div className={classes.q_title}>{props?.allQualityTestPaperData?.questions[currentQuestionIndex]?.text}</div>
                        {props?.allQualityTestPaperData?.questions[currentQuestionIndex]?.options?.map((option, index) => {

                            // For Disabling only answered options
                            const isAnswered = isQuestionAnsweredAlready(
                                currentQuestionIndex,
                                props?.allQualityTestPaperData?.questions[currentQuestionIndex]?.type,
                                `question_${currentQuestionIndex}_${index}`,
                                props?.allQualityTestPaperData?.questions[currentQuestionIndex]?.id
                            );

                            const isQuestionTypeMultiple = props?.allQualityTestPaperData?.questions[currentQuestionIndex]?.type === 'multiple';

                            // Check if the whole question is already answered
                            const isQuestionAnswered = isQuestionAnsweredAlreadyDisableOptions(
                                currentQuestionIndex,
                                isQuestionTypeMultiple ? 'multiple' : 'single',
                                props?.allQualityTestPaperData?.questions[currentQuestionIndex]?.id
                            );
                            return (
                                <div key={index}>
                                    <label className={classes.ques_paper_items}>
                                        <Controller
                                            control={control}
                                            name={props?.allQualityTestPaperData?.questions[currentQuestionIndex]?.type && props?.allQualityTestPaperData?.questions[currentQuestionIndex].type === 'multiple' ? `question_${currentQuestionIndex}_${index}` : `question_${currentQuestionIndex}`}
                                            render={({ field }) => (
                                                <input
                                                    type={props?.allQualityTestPaperData?.questions[currentQuestionIndex].type === 'multiple' ? "checkbox" : "radio"}
                                                    {...field}
                                                    value={option}
                                                    disabled={isQuestionAnswered}
                                                    // checked={props?.allQualityTestPaperData?.questions[currentQuestionIndex].type === 'multiple' ?
                                                    //     (watch(`question_${currentQuestionIndex}_${index}`) === true)
                                                    //     : watch(`question_${currentQuestionIndex}`) === option
                                                    // }
                                                    checked={
                                                        props?.allQualityTestPaperData?.questions[currentQuestionIndex].type === 'multiple'
                                                            ? (watch(`question_${currentQuestionIndex}_${index}`) === true) ||
                                                            isQuestionAnsweredAlready(
                                                                currentQuestionIndex,
                                                                'multiple',
                                                                `question_${currentQuestionIndex}_${index}`,
                                                                props?.allQualityTestPaperData?.questions[currentQuestionIndex]?.id
                                                            )
                                                            : watch(`question_${currentQuestionIndex}`) === option ||
                                                            isQuestionAnsweredAlready(
                                                                currentQuestionIndex,
                                                                'single',
                                                                `question_${currentQuestionIndex}_${index}`,
                                                                props?.allQualityTestPaperData?.questions[currentQuestionIndex]?.id
                                                            )
                                                    }
                                                    style={{ width: '20px', height: '20px' }}
                                                    onChange={(e) => {
                                                        const isChecked = e.target.checked;
                                                        const value = e.target.value;
                                                        if (props?.allQualityTestPaperData?.questions[currentQuestionIndex].type === 'single') {
                                                            setValue(`question_${currentQuestionIndex}`, isChecked ? value : undefined);
                                                        } else {
                                                            if (isChecked) {
                                                                setValue(`question_${currentQuestionIndex}_${index}`, true);
                                                            } else {
                                                                setValue(`question_${currentQuestionIndex}_${index}`, undefined);
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {option}
                                    </label>
                                </div>
                            );
                        })}

                        <div className={classes.btnContainer}>
                            <button type="button" onClick={handleMarkForReview}>
                                {markedForReview.includes(currentQuestionIndex) ? "Unmark for Review" : "Mark for Review"}
                            </button>
                            <div className={classes.prevnextContainer}>
                                {currentQuestionIndex > 0 && <button className={classes.btn_prev} type="button" onClick={handlePrevious}>Previous</button>}
                                {currentQuestionIndex < props?.allQualityTestPaperData?.questions.length - 1 && <button className={classes.btn_next} type="button" onClick={handleNext}>Next</button>}
                            </div>
                            {/* <button type="submit">Submit</button> */}
                        </div>
                    </div>
                </form>

                <div className={`${classes.Question_Pallete} ${classes.card}`}>
                    <div>Question Pallete</div>
                    <div className={classes.cardItems}>
                        <div className={classes.q_number_card}>

                            {props?.allQualityTestPaperData?.questions.map((ques, index) => {
                                return (
                                    <div onClick={() => { setCurrentQuestionIndex(index); setQuestionId(ques?.id) }}
                                        className={`${classes.q_num} 
                                    ${markedForReview.includes(index) ? classes.markedForReview : isQuestionAttempted(index, ques.type) ? classes.attempted : classes.notattempted} 
                                    ${currentQuestionIndex === index ? classes.currentQuestion : ''}`}
                                        key={index}>
                                        {index + 1}
                                    </div>

                                )
                            })}
                        </div>
                    </div>

                    <div className={classes.q_legend_container}>
                        <div className={classes.attempt}>
                            <div></div>
                            Attempt
                        </div>
                        <div className={classes.missed}>
                            <div></div>
                            Missed
                        </div>
                        <div className={classes.MarkForReview}>
                            <div></div>
                            Marked for Review
                        </div>

                    </div>
                    <div className={classes.btnContainer}>
                        <StyledButton onClick={onSubmit} width={"100%"} btnText={"Submit"}>Submit</StyledButton>
                    </div>
                </div>

            </div>


        </div>
    )
}

export default KnowledgeTest