import React, { useEffect, useState } from "react";
import classes from "./styles.module.scss";
import SideItem from "./sideItems";
// import { colors, navItems } from "./json";
import { connect, useDispatch } from "react-redux";
import { importAdminDataFromLocal, onLogout } from "../../../store/auth/actions";
import { useSelector } from "react-redux";
import logo from "../../../assets/images/prepca-logo.png";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { setSidebarOpen, toggleNavIsOpen } from "../../../store/nav/reducer";
import { useNavigate } from "react-router-dom";

const LeftBar = (props) => {

  const currentTheme = useSelector((state) => state.setCurrentTheme);

  useEffect(() => {
    props.importAdminDataFromLocal();
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const isOpen = useSelector(state => state.setIsSidebar.isOpen);

  const handleHamburgerClick = () => {
    dispatch(setSidebarOpen(!isOpen));
  };

  const setNavIsOpen = (data) => {
    dispatch(toggleNavIsOpen(data));
  }

  const handleLogout = () => {
    dispatch(onLogout(navigate))
  }

  const [showIcon, setShowIcons] = useState(false)




  return (
    <div className={classes.container} style={{ backgroundColor: currentTheme.bg2 }}>
      <div className={`${classes.sidebar} ${isOpen ? classes.sidebar_active : " "}`}>
        <div className={classes.card} style={{ backgroundColor: currentTheme.bg3 }}>
          <div className={classes.card_head}>
            <img src={logo} style={{ cursor: "pointer" }} onClick={() => navigate("/admin/view-orders")} alt="logo" />
            {isOpen &&
              <div className={classes.ham_open_icon}>
                <MenuOpenIcon onClick={handleHamburgerClick} fontSize="large" color="primary" />
              </div>
            }
          </div>
          <div className={classes.card_body}>
            <div className={classes.left_bar}>
              <div className={classes.content}>
                {props?.auth?.navItems?.map((navItem, index) => {
                  return (
                    <SideItem
                      type={props?.type}
                      key={index}
                      to={navItem.to}
                      items={navItem}
                      setNavIsOpen={setNavIsOpen}
                    />
                  );
                })}


                <p className={classes.logout_btn} onClick={handleLogout}
                  style={{
                    color: currentTheme.text2,
                    backgroundColor: currentTheme.bg6,
                    opacity: 1,
                    transition: "none",
                    marginLeft: "16px",
                    marginTop: "6px",
                    cursor: "pointer"
                  }}
                >
                  Logout
                </p>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.right_bar}>{props.children}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.Auth,
  };
};

const mapDispatchToProps = { importAdminDataFromLocal };

export default connect(mapStateToProps, mapDispatchToProps)(LeftBar);
