import * as actionTypes from './actionTypes'
const initialState = {
    filters: {
        "search": "",
        "filters": {
            "syllabusId": 1,
            courseId: 1,
            sessionId: Number(process.env.REACT_APP_CURRENT_SESSION) || undefined
        },
        "additionalFilters": {
            courseId: 1,
        },
        "sort": {
            "column": "id",
            "order": "asc"
        }
    },
    page: 1,
    count: 0,
    items: 10,
    allData: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_GROUP:
            state = {
                ...state,
                allData: action.payload.data,
                count: action.payload.count
            }
            break;
        case actionTypes.UPDATE_FILTER:
            state = {
                ...state,
                filters: action.payload,
            }
            break;
        case actionTypes.UPDATE_REDUCER:
            state = {
                ...state,
                [action.payload.item]: action.payload.data,
            }
            break;
        default:
            state = { ...state };
            break;

    }
    ;
    return state;
}

export default reducer;