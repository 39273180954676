import * as actionTypes from "./actionTypes";

const initialState = {
    courses: [],
}

const reducer = (state = initialState, action) => {
    switch (action.types) {
        case actionTypes.UPDATE_COURSE:
            state = {
                ...state,
                courses: action.payload,
            }
            break;
    }
    return state;
}

export default reducer;