import { createApi } from '@reduxjs/toolkit/query/react';
import * as API from '../../apiCalls/urls/onboardingUser';
import { baseQuery } from '../../apiCalls/rtkBaseQuery';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const onBoardingUserApi = createApi({
  baseQuery,
  reducerPath: 'boardingUserApi',
  tagTypes: ['boardingDetails', 'updateBoardingForm', 'qualityTestPaper', 'sop', 'signedContractLink', 'trailPaper'],
  endpoints: (builder) => ({
    updateBoardingForm: builder.mutation({
      query: (data) => ({
        url: API.UPDATE_BOARDING_FORM_URL(data.role),
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['boardingDetails']
    }),
    getBoardingDetails: builder.query({
      query: ({ data, role }) => ({
        url: API.GET_BOARDING_DETAILS(role),
        method: 'POST',
        body: data
      }),
      providesTags: (result) => result ? [{ type: 'boardingDetails', id: result.id }] : [],
      keepUnusedDataFor: 0
    }),
    getQualityTestPaperById: builder.query({
      query: ({ id, role }) => ({
        url: `${API.GET_QUALITY_TEST_PAPER_BY_ID(role)}/${id}`,
        method: 'GET',
      }),
      providesTags: ['qualityTestPaper'],
      keepUnusedDataFor: 0
    }),

    getTrialPapers: builder.query({
      query: (data) => ({
        url: API.GET_TRAIL_PAPERS,
        method: 'POST',
        body: data
      }),
      keepUnusedDataFor: 0
      // providesTags: [''],
    }),
    getAllQualityTestPaper: builder.query({
      query: (role) => ({
        url: API.GET_ALL_QUALITY_TEST_PAPER(role),
        method: 'GET',
      }),
      providesTags: ['qualityTestPaper'],
      keepUnusedDataFor: 0
    }),

    getSOP: builder.query({
      query: ({ type }) => ({
        url: API.GET_SOP(type),
        method: 'GET',
      }),
      providesTags: ['sop'],
    }),

    markAsCompletedSOP: builder.mutation({
      query: ({ id, role }) => ({
        url: API.MARK_AS_COMPLETED_SOP(role),
        method: 'POST',
        body: { "sopId": id }
      }),
      invalidatesTags: ['sop'],
    }),

    getSignedContractLink: builder.mutation({
      query: (file) => ({
        url: API.GET_SIGNED_CONTRACT_LINK,
        method: 'POST',
        body: file
      }),
      providesTags: ['signedContractLink'],
    }),

    startTrailPaper: builder.query({
      query: () => ({
        url: API.START_TRAIL_PAPER,
        method: 'POST',
      }),
      providesTags: ['trailPaper'],
    }),
    startQualityTest: builder.mutation({
      query: ({ id, role }) => ({
        url: API.START_QUALITY_TEST(role),
        method: 'POST',
        body: { id }
      }),
      invalidatesTags: ['qualityTestPaper']
    }),

    saveTestAnswer: builder.mutation({
      query: ({ data, role }) => ({
        url: API.SAVE_TEST_ANSWER(role),
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['qualityTestPaper']
    }),
    submitTestAnswers: builder.mutation({
      query: ({ data, role }) => ({
        url: API.SUBMIT_TEST_ANSWERS(role),
        method: 'POST',
        body: data
      })
    }),
    acceptContract: builder.mutation({
      query: (data) => ({
        url: API.ACCEPT_CONTRACT,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['boardingDetails']
    }),
    markAsCompletedCounselling: builder.mutation({
      query: ({ id, type }) => ({
        url: API.MARK_AS_COMPLETED_COUNSELLING(type) + id,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useUpdateBoardingFormMutation,
  useGetBoardingDetailsQuery,
  useGetTrialPapersQuery,
  useGetQualityTestPaperByIdQuery,
  useGetAllQualityTestPaperQuery,
  useGetSOPQuery,
  useMarkAsCompletedSOPMutation,
  useGetSignedContractLinkMutation,
  useStartTrailPaperQuery,
  useStartQualityTestMutation,
  useSaveTestAnswerMutation,
  useSubmitTestAnswersMutation,
  useAcceptContractMutation,
  useMarkAsCompletedCounsellingMutation
} = onBoardingUserApi;

