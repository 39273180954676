import { createSlice } from '@reduxjs/toolkit';

export const uploadSlice = createSlice({
    name: 'upload',
    initialState: {
        progress: 0,   // Progress percentage
        status: null,  // Status of the upload ('idle', 'uploading', 'completed', 'failed')
    },
    reducers: {
        setUploadProgress: (state, action) => {
            state.progress = action.payload;
            state.status = 'uploading';  // Set status to uploading
        },
        resetUploadProgress: (state) => {
            state.progress = 0;
            state.status = 'idle';       // Reset progress and status
        },
        setUploadComplete: (state) => {
            state.status = 'completed';  // Set status to completed
            state.progress = 0;
        },
        setUploadFailed: (state) => {
            state.status = 'failed'; // Set status to failed in case of error
            state.progress = 0;
        },
    },
});

export const { setUploadProgress, resetUploadProgress, setUploadComplete, setUploadFailed } = uploadSlice.actions;
export default uploadSlice.reducer;
