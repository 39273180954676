import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
// import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import classes from "./styles.module.scss";
import { NavLink, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./active.css";
import { setSidebarOpen } from "../../../../store/nav/reducer";
import usePath from "../../../../hooks/usePath";
const SideItem = ({ items, to, type, setNavIsOpen }) => {

    const currentTheme = useSelector((state) => state.setCurrentTheme);
    const [isShow, setIsShow] = useState(false);
    const navigate = useNavigate()
    const { name, links, open, icon } = items;
    // const [isOpen, setOpen] = useState(false);
    const [isHovering, setIsHovering] = useState(false);


    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const openSideNav = () => {
        if (to) {
            navigate("/logout")
        }
        // else {
        //     setOpen(!isOpen)
        // }
    }

    const sideBarIsAcData = useSelector(state => state.setIsSidebar.IsOpenActiveData);
    const subnavIsOpen = sideBarIsAcData.find((s) => s.name === name)?.open

    const dispatch = useDispatch()

    const isOpen = useSelector(state => state.setIsSidebar.isOpen);
    const { defaultMenu } = usePath();

    // useEffect(() => {
    //     setClickedLink(defaultMenu?.name)
    // }, [defaultMenu])

    const [clickedLink, setClickedLink] = useState(null);

    const handleSubMenuClick = (link) => {
        setClickedLink(link);
    };
    return (
        <div className={classes.sideBar}>
            <div>

                <div className={classes.menu_Container}>
                    <div className={classes.icon}>{icon}</div>
                    <p onClick={() => { openSideNav(); setNavIsOpen({ name, open }) }} className={classes.menu} style={{ color: currentTheme.text2, backgroundColor: isHovering ? currentTheme.bg4 : '' }} onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>

                        <span>{name}</span>
                        {links && (
                            <div
                                className={`rotate-icon ${subnavIsOpen ? 'rotate' : ''}`}
                            >
                                <KeyboardArrowRightRoundedIcon />
                            </div>
                        )}
                    </p>
                </div>
                <div className={`sub-menu ${subnavIsOpen ? 'show' : ''}`}>
                    {subnavIsOpen && links?.map((link, index) => {
                        const { title, to } = link;

                        return (
                            <div className={classes.sub_menu} key={index}>
                                {to?.startsWith("https://") ? (
                                    <a href={to} target="_blank" rel="noopener noreferrer">
                                        <p style={{ color: currentTheme.text2 }}>{title}</p>
                                    </a>
                                ) : (
                                    <NavLink onClick={() => handleSubMenuClick(title)} to={to}>
                                        {({ isActive, isPending, isTransitioning }) => (
                                            <p
                                                // className={isActive ? "active_submenu" : ""}
                                                style={{
                                                    color: currentTheme.text2,
                                                    // backgroundColor: (isActive && clickedLink === title) ? currentTheme.bg5 : currentTheme.bg6,
                                                    backgroundColor: (isActive && clickedLink === title) ? currentTheme.bg5 : currentTheme.bg6,
                                                    opacity: isPending ? 0.5 : 1,
                                                    transition: isTransitioning ? "backgroundColor 1s" : "none"
                                                }}
                                            >
                                                {title}
                                            </p>
                                        )}
                                        {/* <p style={{ color: currentTheme.text2 }} >{title}</p> */}
                                    </NavLink>

                                )}


                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}


export default SideItem;