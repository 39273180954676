import React from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toggleNavIsOpen } from '../store/nav/reducer';

const usePath = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate()
    const pathnames = location.pathname.split('/').filter((x) => x);

    const paths = pathnames
        .slice(1)
        .filter(value => value !== !isNaN(value))
        .map(value => {
            const transformedValue = value.replace(/-/g, ' ');
            const capitalizedValue = transformedValue.replace(/\b\w/g, match => match.toUpperCase());
            return capitalizedValue.trim();
        }).filter(value => value !== "New");;

    const role = pathnames[0] || ""

    const homePathData = [
        { name: "admin", path: '/admin/view-orders', defaultMenu: { name: 'Orders', open: true } },
        { name: "setter", path: '/setter/view-papers', defaultMenu: { name: 'Papers', open: true } },
        { name: "checker", path: '/checker/view-papers/pending', defaultMenu: { name: 'Papers', open: true } },
        { name: "counselor", path: '/counselor/dashboard', defaultMenu: { name: 'Dashboard', open: false } },
        { name: "reviewer", path: '/reviewer/view-papers', defaultMenu: { name: 'Papers', open: true } }
    ]
    const profilePathData = [
        { name: "admin", path: '/admin/view-orders', defaultMenu: { name: 'Orders', open: false } },
        { name: "setter", path: '/setter/profile', defaultMenu: { name: 'Papers', open: true } },
        { name: "checker", path: '/checker/view-papers/pending', defaultMenu: { name: 'Papers', open: true } },
        { name: "counselor", path: '/counselor/profile', defaultMenu: { name: 'Profile', open: false } }
    ]

    const homePath = role && homePathData?.find(h => h.name === role)?.path
    const defaultMenu = role && homePathData?.find(h => h.name === role)?.defaultMenu

    const profilePath = role && profilePathData?.find(p => p.name === role)?.path
    const profileDefaultMenu = role && profilePathData?.find(p => p.name === role)?.defaultMenu

    // const navigateByRoleHomepage = (role) => {
    //     const homePath = role && homePathData?.find(h => h.name === role)?.path
    //     const defaultMenu = role && homePathData?.find(h => h.name === role)?.defaultMenu
    //     dispatch(toggleNavIsOpen(defaultMenu))
    //     navigate(homePath)
    // }

    return {
        homePath,
        defaultMenu,
        pathnames,
        paths,
        profilePath,
        profileDefaultMenu,
        // navigateByRoleHomepage
    }
}

export default usePath