import { useLocation } from 'react-router-dom';

const useUserRole = () => {
    const { pathname } = useLocation();

    const roleRoutes = {
        admin: ['/admin'],
        counselor: ['/counselor', '/counselors'],
        setter: ['/setter'],
        reviewer: ['/reviewer'],
        checker: ['/checker'],
        counsellorAdmin: ['/admin/counselors'],

    };

    const getRole = () => {
        for (const role in roleRoutes) {
            if (roleRoutes[role].some(route => pathname.includes(route))) {
                return role;
            }
        }
        return null;
    };

    return getRole();
};

export default useUserRole;
