import React from "react";
import classes from "./styles.module.scss"

const PrimaryButton = ({ pdfDownload, href, buttonText, width, marginLeft, height, backgroundColor, color, padding, marginTop, margin, fontSize, border, icon, icon_two, fontWight, float, rightIcon, cursor, ...props }) => {
    ;
    return (
        <>
            {
                pdfDownload ?
                    <a href={href} download>
                        <button className={classes.button}
                            {...props}
                            style={{ width, height, backgroundColor, marginLeft, color, marginTop, margin, fontSize, border, padding, fontWight, float, cursor }}
                        >
                            {icon}
                            {icon_two}
                            {buttonText}
                            {rightIcon}
                        </button>
                    </a>
                    : <button className={classes.button}
                        {...props}
                        style={{ width, height, backgroundColor, marginLeft, color, marginTop, margin, fontSize, border, padding, fontWight, float, cursor }}
                    >
                        {icon}
                        {icon_two}
                        {buttonText}
                        {rightIcon}
                    </button>
            }


        </>
    )
}

export default PrimaryButton;