export const SETTER_LIST = '/admin/users/setter/list';
export const PAPER_LIST = '/admin/users/paper/list';
export const PAPER_DETAILS = '/admin/users/paper-details';
export const SETTER_PAPER_LIST = '/admin/users/setter-papers/list';
export const UPDATE_PAPER = '/admin/users/update-paper';
export const SETTER_PAPER_MAPPING = '/admin/users/mapping';
export const SETTER_DASHBOARD = '/admin/users/setter/dashboard';
export const UPDATE_SETTER = '/admin/users/setter/update';

export const GET_PAPER_SYLLABUS = '/admin/users/paper-syllabus';
export const GET_SETTER_PAPER_DETAILS = '/admin/users/setter-paper-details';
export const GET_ALL_ISSUES_BY_SETTER_PAPER_ID = '/admin/users/paper-issues';
export const GET_QUESTION_MAPPING_BY_PAPER_ID = '/admin/users/mapping';

export const GET_SETTER_PROFILE_BY_SETTER_ID = '/admin/users/setter/details';
export const GET_SETTER_MAPPING_BY_SETTER_ID = 'admin/users/setter/mapping';


// For Setter Profile
export const ADD_EXPERIENCE = '/admin/users/setter/experience';
export const UPDATE_EXPERIENCE = '/admin/users/setter/experience';
export const DELETE_EXPERIENCE = '/admin/users/setter/experience';

export const ADD_EDUCATION = '/admin/users/setter/education';
export const UPDATE_EDUCATION = '/admin/users/setter/education';
export const DELETE_EDUCATION = '/admin/users/setter/education';

export const UPDATE_PROFILE = '/admin/users/setter/update-profile';

export const GET_CERTIFICATE_LIST = '/admin/users/setter/certificate';
export const ADD_CERTIFICATE = '/admin/users/setter/certificate';
export const UPDATE_CERTIFICATE = '/admin/users/setter/certificate';
export const DELETE_CERTIFICATE = '/admin/users/setter/certificate';
export const GET_CERTIFICATE_LINK = '/admin/users/setter/get-certificate-link';

export const ADD_INTRODUCTORY_VIDEO = '/admin/users/setter/add-introductory-video';
export const DELETE_INTRODUCTORY_VIDEO = '/admin/users/setter/delete-introductory-video';

export const UPDATE_SOCIAL_LINK = '/admin/users/setter/social-link';

export const ASSIGN_PAPER_TO_SETTER = '/admin/users/setter/assign-paper';
