export const ALL_REFERRAL = `/data/referrals/`
export const ALL_CENTER = `/data/all-centres/`
export const ALL_SESSION = `/data/sessions/`
export const TEST_PREFERENCE = `/data/test-preferences/`
export const ALL_COURSE = `/data/courses/`
export const ALL_COURSE_SYLLABUS = `/data/course/`
export const ALL_SUBJECT_PORTION = `/data/syllabus/`
export const ALL_COUPON_DETAILS = `/cart/coupon`
export const ALL_STUDENT_DETAILS = `/admin/resource/dropdown`

export const STATE_DETAILS = `/data/states/`;
export const STATE_DISTRICT = `/data/state/`;
export const DISTRICT_DETAILS = `/data/district/`;
export const GET_SYLLABUS_BY_COURSE_SESSION_ID = `/admin/course/syllabus`;