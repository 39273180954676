import { createApi } from '@reduxjs/toolkit/query/react';
import * as API from '../../apiCalls/urls/reviewerUser';
import { baseQuery } from '../../apiCalls/rtkBaseQuery';

export const reviewerUserApi = createApi({
    baseQuery,
    reducerPath: 'reviewerUserApi',
    tagTypes: ['syllabus', 'paper', 'details', 'question', 'issues', 'profile', 'certificate', 'mapping'],
    endpoints: (builder) => ({

        // POST: Paper List
        getPaperList: builder.mutation({
            query: ({ filters, items = 10, page = 1 }) => ({
                url: `${API.PAPER_LIST}`,
                method: 'POST',
                body: filters,
            }),
            invalidatesTags: ['paper'],
            refetchOnMountOrArgChange: true,
            keepUnusedDataFor: 0
        }),

        // GET: Get Paper Syllabus By Paper Id And Setter Paper Id
        getPaperSyllabus: builder.query({
            query: ({ paperId, setterPaperId }) => ({
                url: `${API.GET_PAPER_SYLLABUS}/${paperId}/${setterPaperId}`,
                method: 'GET',
            }),
            providesTags: ['syllabus'],
        }),

        // POST: Update Syllabus Status By Setter Paper Id
        updateSyllabusStatus: builder.mutation({
            query: ({ setterPaperId, data }) => ({
                url: `${API.UPDATE_SYLLABUS_STATUS}/${setterPaperId}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['syllabus'],
        }),

        // GET: Get Paper Details By Paper Id And Setter Paper Id
        getPaperDetails: builder.query({
            query: ({ paperId, setterPaperId }) => ({
                url: `${API.GET_PAPER_DETAILS}/${paperId}/${setterPaperId}`,
                method: 'GET',
            }),
            providesTags: ['details'],
        }),

        getQuestionMappingByPaperId: builder.query({
            query: (paperId) => ({
                url: `${API.GET_QUESTION_MAPPING_BY_PAPER_ID}/${paperId}`,
                // url: `${API.GET_QUESTION_MAPPING_BY_PAPER_ID}${paperId}`,
                method: 'GET',
            }),
            providesTags: ['question'],
        }),

        // POST: Update Paper Status By Setter Paper Id
        updatePaperStatus: builder.mutation({
            query: ({ setterPaperId, status, data }) => ({
                url: `${API.UPDATE_PAPER_STATUS}/${setterPaperId}`,
                method: 'POST',
                body: { status, ...data },
            }),
            invalidatesTags: ['paper', 'question', 'details', 'syllabus'],
        }),

        getAllIssuesBySetterPaperId: builder.query({
            query: ({ setterPaperId, data }) => ({
                url: `${API.GET_ALL_ISSUES_BY_SETTER_PAPER_ID}${setterPaperId}`,
                method: 'POST',
                body: data,
            }),
            providesTags: ['issues'],
        }),

        // POST: Add Issue By Setter Paper Id
        addIssueBySetterPaperId: builder.mutation({
            query: ({ setterPaperId, issueData }) => ({
                url: `${API.ADD_ISSUE_BY_SETTER_PAPER_ID}${setterPaperId}`,
                method: 'POST',
                body: issueData,
            }),
            invalidatesTags: ['issues'],
        }),

        // POST: Update Issue By Issue Id
        updateIssueById: builder.mutation({
            query: ({ issueId, updatedIssueData }) => ({
                url: `${API.UPDATE_ISSUE_BY_ID}/${issueId}`,
                method: 'POST',
                body: updatedIssueData,
            }),
            invalidatesTags: ['issues'],
        }),

        // DELETE: Delete Issue By Issue Id
        deleteIssueById: builder.mutation({
            query: (issueId) => ({
                url: `${API.DELETE_ISSUE_BY_ID}${issueId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['issues'],
        }),

        // Query to get reviewer profile
        getProfile: builder.query({
            query: () => ({
                url: API.PROFILE,
                method: 'GET',
            }),
            providesTags: ['profile'],
        }),

        updateSocialLink: builder.mutation({
            query: (linkData) => ({
                url: API.UPDATE_SOCIAL_LINK,
                method: 'POST',
                body: linkData,
            }),
            invalidatesTags: ['profile'],
        }),

        updateProfile: builder.mutation({
            query: (profileData) => ({
                url: API.UPDATE_PROFILE,
                method: 'POST',
                body: profileData,
            }),
            invalidatesTags: ['profile'],
        }),

        // Query to get certificates list
        getCertificatesLists: builder.query({
            query: () => ({
                url: API.GET_CERTIFICATE_LIST,
                method: 'GET',
            }),
            providesTags: ['certificate'],
        }),

        // Query to get mapping lists
        getMappingLists: builder.query({
            query: ({ sessionId, portionId }) => ({
                url: API.GET_MAPPING_LIST,
                params: sessionId ? { sessionId, portionId } : {},
            }),
            providesTags: ['mapping'],
        }),

        // Mutation to get certificates link
        getCertificatesLink: builder.mutation({
            query: (certificate) => ({
                url: API.GET_CERTIFICATE_LINK,
                method: 'POST',
                body: certificate,
            }),
        }),
        // Mutation to add a certificate
        addCertificate: builder.mutation({
            query: (certificateData) => ({
                url: API.ADD_CERTIFICATE,
                method: 'POST',
                body: certificateData,
            }),
            invalidatesTags: ['certificate'],
        }),

        deleteCertificate: builder.mutation({
            query: (certificateId) => ({
                url: API.DELETE_CERTIFICATE,
                method: 'DELETE',
                body: certificateId
            }),
            invalidatesTags: ['certificate'],
        }),
        updateCertificate: builder.mutation({
            query: (newData) => ({
                url: API.UPDATE_CERTIFICATE,
                method: 'PUT',
                body: newData,
            }),
            invalidatesTags: ['certificate'],
        }),

        // Mutation to add education
        addEducation: builder.mutation({
            query: (educationData) => ({
                url: API.ADD_EDUCATION,
                method: 'POST',
                body: educationData,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to update education
        updateEducation: builder.mutation({
            query: (data) => ({
                url: API.UPDATE_EDUCATION,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to delete education
        deleteEducation: builder.mutation({
            query: (educationId) => ({
                url: API.DELETE_EDUCATION,
                method: 'DELETE',
                body: educationId,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to add experience
        addExperiance: builder.mutation({
            query: (data) => ({
                url: API.ADD_EXPERIENCE,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to update experience
        updateExperiance: builder.mutation({
            query: (data) => ({
                url: API.UPDATE_EXPERIENCE,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to delete experience
        deleteExperiance: builder.mutation({
            query: (expId) => ({
                url: API.DELETE_EXPERIENCE,
                method: 'DELETE',
                body: expId,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to add introductory video
        addIntroductoryVideo: builder.mutation({
            query: (file) => ({
                url: API.ADD_INTRODUCTORY_VIDEO,
                method: 'POST',
                body: file,
            }),
            invalidatesTags: ['profile'],
        }),

        // Mutation to delete introductory video
        deleteIntroductoryVideo: builder.mutation({
            query: (expId) => ({
                url: API.DELETE_INTRODUCTORY_VIDEO,
                method: 'DELETE',
                body: expId,
            }),
            invalidatesTags: ['profile'],
        }),

        getDashboard: builder.query({
            query: (data) => ({
                url: API.REVIEWER_DASHBOARD,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const {
    useGetPaperListMutation,
    useGetPaperSyllabusQuery,
    useUpdateSyllabusStatusMutation,
    useGetPaperDetailsQuery,
    useUpdatePaperStatusMutation,

    useGetProfileQuery,
    useUpdateSocialLinkMutation,
    useUpdateProfileMutation,
    useGetCertificatesListsQuery,
    useGetMappingListsQuery,
    useGetCertificatesLinkMutation,
    useAddCertificateMutation,
    useUpdateCertificateMutation,
    useDeleteCertificateMutation,
    useAddEducationMutation,
    useUpdateEducationMutation,
    useDeleteEducationMutation,
    useAddExperianceMutation,
    useUpdateExperianceMutation,
    useDeleteExperianceMutation,
    useAddIntroductoryVideoMutation,
    useDeleteIntroductoryVideoMutation,
    useGetQuestionMappingByPaperIdQuery,
    useGetAllIssuesBySetterPaperIdQuery,
    useAddIssueBySetterPaperIdMutation,
    useUpdateIssueByIdMutation,
    useDeleteIssueByIdMutation,
    useGetDashboardQuery
} = reviewerUserApi;

