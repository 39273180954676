export const PAPER_LIST = '/reviewer/paper/list';
export const GET_PAPER_SYLLABUS = '/reviewer/paper/syllabus';
export const UPDATE_SYLLABUS_STATUS = '/reviewer/paper/syllabus-status';
export const GET_PAPER_DETAILS = '/reviewer/paper/details';
export const UPDATE_PAPER_STATUS = '/reviewer/paper/paper-status';

export const GET_SELECTED_SYLLABUS_BY_PAPER_ID = '/reviewer/paper/selected-syllabus';
export const GET_QUESTION_MAPPING_BY_PAPER_ID = '/reviewer/paper/mapping';

export const GET_ALL_ISSUES_BY_SETTER_PAPER_ID = '/reviewer/paper/issues/';
export const ADD_ISSUE_BY_SETTER_PAPER_ID = '/reviewer/paper/create-issues/';
export const UPDATE_ISSUE_BY_ID = '/reviewer/paper/update-issues/';
export const DELETE_ISSUE_BY_ID = '/reviewer/paper/issues/';

export const PROFILE = '/reviewer/auth/profile';
export const UPDATE_SOCIAL_LINK = '/reviewer/auth/social-link';
export const UPDATE_PROFILE = '/reviewer/auth/update-profile';

export const GET_CERTIFICATE_LINK = '/reviewer/auth/get-certificate-link';
export const ADD_CERTIFICATE = '/reviewer/auth/certificate';
export const ADD_EDUCATION = '/reviewer/auth/education';
export const UPDATE_EDUCATION = '/reviewer/auth/education';
export const DELETE_EDUCATION = '/reviewer/auth/education';
export const UPDATE_CERTIFICATE = '/reviewer/auth/certificate';
export const DELETE_CERTIFICATE = '/reviewer/auth/certificate';

export const ADD_INTRODUCTORY_VIDEO = '/reviewer/auth/add-introductory-video';
export const DELETE_INTRODUCTORY_VIDEO = '/reviewer/auth/delete-introductory-video';

export const GET_CERTIFICATE_LIST = '/reviewer/auth/certificate';

export const ADD_EXPERIENCE = '/reviewer/auth/experience';
export const UPDATE_EXPERIENCE = '/reviewer/auth/experience';
export const DELETE_EXPERIENCE = '/reviewer/auth/experience';

export const GET_MAPPING_LIST = '/reviewer/auth/mapping';
export const REVIEWER_DASHBOARD = '/reviewer/paper/dashboard';