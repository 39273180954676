import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getDataFromStore } from '../store/getStore';

export const getToken = () => {
    let auth = getDataFromStore("Auth");
    return auth?.authDetails?.token?.access_token
  };
   

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
        headers.set('Authorization', `Bearer ${getToken()}`);
        headers.set('institutionId', process.env.REACT_APP_INSTITUTION_ID);
        return headers;
    },

    transformRequest: (body, headers) => {
        const contentType = headers.get('Content-Type');
        if (contentType === 'application/json') {
            return JSON.stringify(body);
        } 
        return body;
    },
});

export { baseQuery };
