import * as actionTypes from './actionTypes';
import { apiFunction } from "../../apiCalls/functions"
import { ALL_COUPON_DETAILS, ALL_STUDENT_DETAILS, ALL_CENTER, ALL_REFERRAL, ALL_SESSION, TEST_PREFERENCE, ALL_COURSE, ALL_COURSE_SYLLABUS, ALL_SUBJECT_PORTION } from "../../apiCalls/urls/publicData"
import { STATE_DETAILS, STATE_DISTRICT, DISTRICT_DETAILS } from "../../apiCalls/urls/publicData"
import { apiLoading, apiLoadingEnd } from "../notification/action";
import { actionNotifier } from "../../components/ui/toast";
import { useNavigate } from 'react-router-dom';

//AllAPIS
export function onPublicDataLoad() {
    return async (dispatch) => {
        dispatch(onFetchCenter("centerDetails"))
        dispatch(onFetchCourse("courseDetails"))
        dispatch(onFetchReferral("referralDetails"))
        // dispatch(onFetchSession("sessionDetails"))
        dispatch(onFetchTestPreference("testPreferenceDetails"))
        //dispatch(onFetchStudent("studentDetails"))
        dispatch(onFetchCoupon("couponDetails"))
        dispatch(onFetchState("stateDetails"))
    }
}
export const fetchState = async () => {
    return await apiFunction(STATE_DETAILS, 'get', {}, false, true)
}
export const fetchStateDistrict = async (stID) => {
    return await apiFunction(STATE_DISTRICT + stID + '/districts', 'get', {}, false, true)
}
export const fetchDistrictCity = async (diID) => {
    return await apiFunction(DISTRICT_DETAILS + diID + '/cities', 'get', {}, false, true)
}

export const fetchDropdown = async (data) => {
    return await apiFunction(ALL_STUDENT_DETAILS, 'post', data, true, false)
}
export const fetchReferral = async () => {
    return await apiFunction(ALL_REFERRAL, 'get', {}, false, false)
}
export const fetchCenter = async () => {
    return await apiFunction(ALL_CENTER, 'get', {}, false, false)
}
export const fetchSession = async (id) => {
    return await apiFunction(ALL_SESSION + id, 'get', {}, false, false)
}
export const fetchTestPreference = async () => {
    return await apiFunction(TEST_PREFERENCE, 'get', {}, false, false)
}
export const fetchCourse = async () => {
    return await apiFunction(ALL_COURSE, 'get', {}, false, false)
}
export const fetchCourseSyllabus = async (id) => {
    return await apiFunction(ALL_COURSE_SYLLABUS + id + '/syllabus', 'get', {}, false, false)
}
export const fetchSubjectPortion = async (syllabusId, sessionId) => {
    return await apiFunction(`/data/syllabus/${syllabusId}/groups/` + sessionId, 'get', {}, false, false)
}
export function onFetchState(fileName) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchState();
        if (result.status) {
            dispatch(updatePublicData(result.data, fileName))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchDistrict(fileName, id) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchStateDistrict(id);
        if (result.status) {
            dispatch(updatePublicData(result.data, fileName))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchStudent(fileName, search) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let data = {
            "dropdownCode": "STUDENT",
            "search": search,
            "key": [
                process.env.REACT_APP_INSTITUTION_ID
            ]
        }
        let result = await fetchDropdown(data);
        if (result.status) {
            dispatch(updatePublicData(result.data, fileName))
        }
        else {
            // actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchCoupon(fileName) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let data = {
            "dropdownCode": "COUPONS",
            "search": "",
            "key": [
                process.env.REACT_APP_INSTITUTION_ID
            ]
        }
        let result = await fetchDropdown(data);
        if (result.status) {
            dispatch(updatePublicData(result.data, fileName))
        }
        else {
            // actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchReferral(fileName) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchReferral();
        if (result.status) {
            dispatch(updatePublicData(result.data, fileName))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchCenter(fileName) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchCenter();
        if (result.status) {
            dispatch(updatePublicData(result.data, fileName))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchSession(fileName, id) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchSession(id);
        if (result.status) {
            dispatch(updatePublicData(result.data, fileName))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchTestPreference(fileName) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchTestPreference();
        if (result.status) {
            dispatch(updatePublicData(result.data, fileName))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchCourse(fileName) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchCourse();
        if (result.status) {
            dispatch(updatePublicData(result.data, fileName))
        }
        else {

        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchCourseSyllabus(fileName, id) {
    return async (dispatch) => {
        ;
        dispatch(apiLoading())
        let result = await fetchCourseSyllabus(Number(id));
        if (result.status) {
            dispatch(updatePublicData(result.data, fileName))
        }
        else {
            //actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchSubjectPortion(fileName, id, sessionId) {
    return async (dispatch) => {
        dispatch(apiLoading())
        if (id) {
            let result = await fetchSubjectPortion(Number(id), Number(sessionId));
            if (result.status) {
                dispatch(updatePublicData(result.data, fileName))
            }
            else {
                //actionNotifier.error(result.message)
            }
        }
        dispatch(apiLoadingEnd())
    }
}

export const updatePublicData = (data, name) => {
    return {
        type: actionTypes.UPDATE_PUBLIC_DATA,
        payload: { data, name }
    };
}