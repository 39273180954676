import { createApi } from '@reduxjs/toolkit/query/react';
import * as API from '../../apiCalls/urls/reviewerAdmin';
import { baseQuery } from '../../apiCalls/rtkBaseQuery';

export const reviewerAdminApi = createApi({
    baseQuery,
    reducerPath: 'reviewerAdminApi',
    tagTypes: ['reviewer', 'syllabus', 'Paper', 'question', 'profile', 'certificate', 'mapping', 'Issues', 'dashboard', 'filters'],
    endpoints: (builder) => ({
        getReviewerList: builder.query({
            query: ({ page = 1, items = 10, filters }) => ({
                url: `${API.REVIEWER_LIST}?page=${page}&items=${items}`,
                method: 'POST',
                body: filters
            }),
            providesTags: ['reviewer'],
        }),
        getPaperList: builder.query({
            query: ({ page = 1, items = 10, filters }) => ({
                url: `${API.PAPER_LIST}?page=${page}&items=${items}`,
                method: 'POST',
                body: filters
            }),
            providesTags: ['Paper'],
        }),

        getPaperDetails: builder.query({
            query: (paperId) => ({
                url: `${API.PAPER_DETAILS}/${paperId}`,
                method: 'GET',
            }),
            providesTags: ['Paper'],
        }),

        getSetterPaperList: builder.query({
            query: ({ page = 1, items = 10, filters }) => ({
                url: `${API.REVIEWER_PAPER_LIST}?page=${page}&items=${items}`,
                method: 'POST',
                body: filters
            }),
            invalidatesTags: ['Paper'],
        }),

        // getSetterPaperDetails: builder.query({
        //     query: (paperId) => ({
        //         url: `${API.REVIEWER_PAPER_DETAILS}/${paperId}`,
        //         method: 'GET',
        //     }),
        //     providesTags: ['Paper'],
        // }),

        updatePaper: builder.mutation({
            query: ({ paperId, data }) => ({
                url: API.UPDATE_PAPER,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }),
            invalidatesTags: ['Paper'],
        }),

        getSetterPaperMapping: builder.query({
            query: (paperId) => ({
                url: `${API.REVIEWER_PAPER_MAPPING}/${paperId}`,
                method: 'GET',
            }),
            providesTags: ['mapping'],
        }),

        getSetterDashboard: builder.query({
            query: (data) => ({
                url: API.REVIEWER_DASHBOARD,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['syllabus', 'Paper', 'mapping'],
        }),

        updateSetter: builder.mutation({
            query: (data) => ({
                url: API.UPDATE_REVIEWER,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['reviewer'],
        }),

        getPaperDetailsBySetterPaperId: builder.query({
            query: ({ setterPaperId }) => ({
                url: `${API.GET_REVIEWER_PAPER_DETAILS}/${setterPaperId}`,
                method: 'GET',
            }),
            providesTags: ['details'],
        }),
        getQuestionMappingByPaperId: builder.query({
            query: (paperId) => ({
                url: `${API.GET_QUESTION_MAPPING_BY_PAPER_ID}/${paperId}`,
                // url: `${API.GET_QUESTION_MAPPING_BY_PAPER_ID}${paperId}`,
                method: 'GET',
            }),
            providesTags: ['question'],
        }),
        getAllIssuesBySetterPaperId: builder.query({
            query: ({ setterPaperId, data }) => ({
                url: `${API.GET_ALL_ISSUES_BY_REVIEWER_PAPER_ID}/${setterPaperId}`,
                method: 'GET',
                body: data,
            }),
            providesTags: ['issues'],
        }),
        getPaperSyllabusBySetterPaperId: builder.query({
            query: ({ setterPaperId }) => ({
                url: `${API.GET_PAPER_SYLLABUS}/${setterPaperId}`,
                method: 'GET',
            }),
            providesTags: ['syllabus'],
        }),
        getSetterProfileBySetterId: builder.query({
            query: ({ id }) => ({
                url: `${API.GET_REVIEWER_PROFILE_BY_REVIEWER_ID}/${id}`,
                method: 'GET',
            }),
            providesTags: ['setterDetailsAdmin'],
        }),
        getReviewerMappingBySetterId: builder.query({
            query: ({ setterId, portionId = 0, sessionId }) => ({
                url: API.GET_REVIEWER_MAPPING_BY_REVIEWER_ID + `/${setterId}?sessionId=${sessionId}&portionId=${portionId}`,
                method: 'GET',
            }),
            providesTags: ['mapping'],
        }),

        // For setter Profile CRUD
        addExperiance: builder.mutation({
            query: ({ data, cId }) => ({
                url: API.ADD_EXPERIENCE + "/" + cId,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['setterDetailsAdmin'],
        }),

        updateExperiance: builder.mutation({
            query: ({ data, cId }) => ({
                url: API.UPDATE_EXPERIENCE + "/" + cId,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['setterDetailsAdmin'],
        }),

        deleteExperiance: builder.mutation({
            query: ({ expId, cId }) => ({
                url: API.DELETE_EXPERIENCE + "/" + cId,
                method: 'DELETE',
                body: expId
            }),
            invalidatesTags: ['setterDetailsAdmin'],
        }),

        addEducation: builder.mutation({
            query: ({ data, cId }) => ({
                url: API.ADD_EDUCATION + "/" + cId,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['setterDetailsAdmin'],
        }),

        updateEducation: builder.mutation({
            query: ({ data, cId }) => ({
                url: API.UPDATE_EDUCATION + "/" + cId,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['setterDetailsAdmin'],
        }),

        deleteEducation: builder.mutation({
            query: ({ eduId, cId }) => ({
                url: API.DELETE_EDUCATION + "/" + cId,
                method: 'DELETE',
                body: eduId
            }),
            invalidatesTags: ['setterDetailsAdmin'],
        }),

        updateSetterProfile: builder.mutation({
            query: ({ preference, data, cId }) => {

                return {
                    url: API.UPDATE_PROFILE + "/" + cId,
                    method: 'POST',
                    body: !data ? { preference: preference } : data,
                }
            },
            invalidatesTags: ['setterDetailsAdmin'],
        }),

        getCertificatesLists: builder.query({
            query: (cId) => ({
                url: API.GET_CERTIFICATE_LIST + "/" + cId,
                method: 'GET',
            }),
            providesTags: ['certificate'],
        }),

        deleteCertificate: builder.mutation({
            query: ({ certiId, cId }) => ({
                url: API.DELETE_CERTIFICATE + "/" + cId,
                method: 'DELETE',
                body: certiId
            }),
            invalidatesTags: ['certificate'],
        }),

        addCertificate: builder.mutation({
            query: ({ data, cId }) => ({
                url: API.ADD_CERTIFICATE + "/" + cId,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['certificate'],
        }),
        updateCertificate: builder.mutation({
            query: ({ data, cId }) => ({
                url: API.UPDATE_CERTIFICATE + "/" + cId,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['certificate'],
        }),

        getCertificatesLink: builder.mutation({
            query: ({ formData, id }) => ({
                url: API.GET_CERTIFICATE_LINK + "/" + id,
                method: 'POST',
                body: formData
            }),
        }),
        addIntroductoryVideo: builder.mutation({
            query: ({ file, cId }) => ({
                url: API.ADD_INTRODUCTORY_VIDEO + "/" + cId,
                method: 'POST',
                body: file,
            }),
            invalidatesTags: ['setterDetailsAdmin'],
        }),
        deleteIntroductoryVideo: builder.mutation({
            query: ({ cId }) => ({
                url: API.DELETE_INTRODUCTORY_VIDEO + "/" + cId,
                method: 'DELETE',
                // body: introVId
            }),
            invalidatesTags: ['setterDetailsAdmin'],
        }),
        updateSocialLink: builder.mutation({
            query: ({ linkData, cId }) => ({
                url: API.UPDATE_SOCIAL_LINK + "/" + cId,
                method: 'POST',
                body: linkData,
            }),
            invalidatesTags: ['setterDetailsAdmin'],
        }),
        assignPaperToReviewer: builder.mutation({
            query: (data) => ({
                url: API.ASSIGN_PAPER_TO_REVIEWER,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Paper'],
        }),
        updateSetterPaperBySetterPaperIdData: builder.mutation({
            query: (data) => ({
                url: API.UPDATE_SETTER_PAPER,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Paper'],
        }),
    }),
});

export const {
    useGetReviewerListQuery,
    useGetPaperListQuery,
    useGetPaperDetailsQuery,
    useGetSetterPaperListQuery,
    useGetSetterPaperDetailsQuery,
    useUpdatePaperMutation,
    useGetSetterPaperMappingQuery,
    useGetSetterDashboardQuery,
    useUpdateSetterMutation,
    useGetPaperDetailsBySetterPaperIdQuery,
    useGetQuestionMappingByPaperIdQuery,
    useGetAllIssuesBySetterPaperIdQuery,
    useGetPaperSyllabusBySetterPaperIdQuery,
    useGetSetterProfileBySetterIdQuery,
    useGetReviewerMappingBySetterIdQuery,

    // Hooks for Setter Profile CRUD
    useAddExperianceMutation,
    useUpdateExperianceMutation,
    useDeleteExperianceMutation,
    useAddEducationMutation,
    useUpdateEducationMutation,
    useDeleteEducationMutation,
    useUpdateSetterProfileMutation,

    // Hooks for Certificates
    useGetCertificatesListsQuery,
    useDeleteCertificateMutation,
    useAddCertificateMutation,
    useUpdateCertificateMutation,
    useGetCertificatesLinkMutation,

    // Hooks for Introductory Video
    useAddIntroductoryVideoMutation,
    useDeleteIntroductoryVideoMutation,

    // Hooks for Social Links
    useUpdateSocialLinkMutation,
    useAssignPaperToReviewerMutation,

    useUpdateSetterPaperBySetterPaperIdDataMutation
} = reviewerAdminApi;
