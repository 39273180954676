export const COUNSELOR_LIST = '/admin/counselor/list';
export const COUNSELOR_DETAILS = (type = "counselor") => `/admin/${type}/details`;
export const UPDATE_COUNSELOR = '/admin/counselor/update-counselor';
export const CREATE_COUNSELOR = '/admin/counselor/create-counselor';
export const UPDATE_COUNSELOR_CONTRACT = '/admin/counselor/update-counselor-contract';
export const ADD_COUNSELOR_MAPPING = '/admin/counselor/update-mapping';
export const REMOVE_COUNSELOR_MAPPING = '/admin/counselor/remove-mapping';
export const GET_GRADES = '/admin/counselor/grades';
export const COUNSELOR_MAPPING = '/admin/counselor/mapping';
export const COUNSELING_LIST = '/admin/counselor/counseling/list';
export const GET_COUNSELING_DETAILS = '/admin/counselor/counseling-details';

export const ADD_EXPERIENCE = '/admin/counselor/experience';
export const UPDATE_EXPERIENCE = '/admin/counselor/experience';
export const DELETE_EXPERIENCE = '/admin/counselor/experience';

export const ADD_EDUCATION = '/admin/counselor/education';
export const UPDATE_EDUCATION = '/admin/counselor/education';
export const DELETE_EDUCATION = '/admin/counselor/education';

export const UPDATE_COUNSELOR_PROFILE = '/admin/counselor/update-profile';

export const GET_CERTIFICATE_LIST = '/admin/counselor/certificate';
export const ADD_CERTIFICATE = '/admin/counselor/certificate';
export const UPDATE_CERTIFICATE = '/admin/counselor/certificate';
export const DELETE_CERTIFICATE = '/admin/counselor/certificate';
export const GET_CERTIFICATE_LINK = '/admin/counselor/get-certificate-link';

export const ADD_INTRODUCTORY_VIDEO = '/admin/counselor/add-introductory-video';
export const DELETE_INTRODUCTORY_VIDEO = '/admin/counselor/delete-introductory-video';

export const UPDATE_SOCIAL_LINK = '/admin/counselor/social-link';

export const GET_COUNSELLOR_COUNT = '/admin/counselor/counseling/count';

// export const UPDATE_AVAILABILITY = '/admin/counselor/availability/update';
export const ADD_AVAILABILITY = '/admin/counselor/availability/update';
export const COUNSELLOR_AVAILABILITY = '/admin/counselor/availability/list';

export const GET_COUNSELLOR_DROPDOWN = '/admin/counselor/dropdown';
export const GET_COUNSELLOR_AVAILABILITY_SLOT = '/admin/counselor/slots';

export const UPDATE_COUNSELLING = '/admin/counselor/update-counseling';
export const CANCEL_COUNSELLING = '/admin/counselor/update-counseling';
export const ASSIGN_COUNSELLING = '/admin/counselor/assign-counseling';
export const ACCEPT_RESCHEDULED_COUNSELING = '/admin/counselor/accept-reschedule-counseling/';

export const GET_AVAILABILITIES_COUNTS = '/admin/counselor/availabilities/list';
export const GET_AVAILABILITIES_SLOTS_LIST = '/admin/counselor/availabilities-slots/list';


export const GET_DASHBOARD = '/admin/counselor/dashboard';
export const EXPORT_CSV = '/admin/counselor/counseling/export-to-csv';
