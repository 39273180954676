import React from 'react'
import KnowledgeTest from '../../../container/onBoardingUserContainer/Stages/knowledgeTest'
import { useGetAllQualityTestPaperQuery, useGetQualityTestPaperByIdQuery, useSaveTestAnswerMutation, useSubmitTestAnswersMutation } from '../onBoardingUserApiSlice';
import useApiLoadingOverlay from '../../../hooks/useApiLoadingOverlay';
import useApiTopLoading from '../../../hooks/useApiTopLoading';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

const KnowledgeTestPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const role = searchParams.get("role")

    const { data: allQualityTestPaperData, isFetching: isQualityTestPaperLoading, isLoading: isGetAllQualityTestPaperLoading } = useGetAllQualityTestPaperQuery(role);

    const [saveTestAnswer, { isLoading: isLoadingSaveTest }] = useSaveTestAnswerMutation() // Save Question On Next
    const [submitTestAnswers, { isLoading: isLoadingsubmitTestAnswers }] = useSubmitTestAnswersMutation() // Submit Questions

    const dispatch = useDispatch()
    const loadingActions = [isLoadingSaveTest, isLoadingsubmitTestAnswers]
    useApiLoadingOverlay(loadingActions, dispatch)
    const loadingDatas = [isQualityTestPaperLoading]
    useApiTopLoading(loadingDatas, dispatch)
    return (
        <>
            <KnowledgeTest
                // qualityTestPaperByIdData={qualityTestPaperByIdData}
                allQualityTestPaperData={allQualityTestPaperData}
                saveTestAnswer={saveTestAnswer}
                submitTestAnswers={submitTestAnswers}
            />
        </>
    )
}

export default KnowledgeTestPage