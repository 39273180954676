import * as actionTypes from './actionTypes';

const initialState = {
    orderDetails: [],
    allComments: []
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.UPDATE_SINGLE_ORDER:
            state = {
                ...state,
                orderDetails: action.payload,
            }
            break;
        case actionTypes.UPDATE_COMMENT:
            state = {
                ...state,
                allComments: action.payload,
            }
            break;
        case actionTypes.CLEAR_ORDERDETAILS:
            state = {
                ...state,
                orderDetails: [],
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default reducer;