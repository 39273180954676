export const UPDATE_BOARDING_FORM_URL = (role = 'counselor') => `/${role}/boarding/update-form`;
export const GET_BOARDING_DETAILS = (role = 'counselor') => `/${role}/boarding/`;
export const GET_QUALITY_TEST_PAPER_BY_ID = (role = 'counselor') => `/${role}/boarding/quality-test`;
export const GET_ALL_QUALITY_TEST_PAPER = (role = 'counselor') => `/${role}/boarding/quality-test`;
export const GET_SOP = (role = 'counselor') => `/${role}/boarding/sop`;
export const GET_SIGNED_CONTRACT_LINK = (role = 'counselor') => `/${role}/boarding/get-signed-contract-link`;
export const START_TRAIL_PAPER = (role = 'counselor') => `/${role}/boarding/start-trail-paper`;
export const GET_TRAIL_PAPERS = (role = 'counselor') => `/${role}/list?page=1&items=100000`;
export const START_QUALITY_TEST = (role = 'counselor') => `/${role}/boarding/start-quality-test`;
export const SAVE_TEST_ANSWER = (role = 'counselor') => `/${role}/boarding/save-test-answer`;
export const SUBMIT_TEST_ANSWERS = (role = 'counselor') => `/${role}/boarding/update-form`;
export const MARK_AS_COMPLETED_SOP = (role = 'counselor') => `/${role}/boarding/mark-completed-sop`;
export const ACCEPT_CONTRACT = (role = 'admin') => `/${role}/boarding/accept-signed-contract`;
export const MARK_AS_COMPLETED_COUNSELLING = (role = 'counselor') => `/${role}/complete-counseling/`;

