import * as actionTypes from './actionTypes';

const initialState = {
    filters: {
        "filters": {
            // "subjectId": 3,
            // "portionId": 2,
            // "sessionId": 2
        },
        "rangeFilters": {
            // "startDateBetween": ["2022-09-13T17:19:47.019Z", "2022-09-14T07:35:34.282Z"],
            // "endDateBetween": ["2022-09-13T17:19:47.019Z", "2022-09-14T07:35:34.282Z"],
            // "addedBetween": ["2022-09-13T17:19:47.019Z", "2022-09-14T07:35:34.282Z"]
        },
        "sort": {
            "column": "id",
            "order": "asc"
        }, "additionalFilters": {
            // "courseId": 1,
            // "syllabusId": 1
        }
    },
    page: 1,
    items: 10,
    allData: [],
    count: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_QUESTIONS:
            state = {
                ...state,
                allData: action.payload.data,
                count: action.payload.count
            }
            break;
        case actionTypes.UPDATE_FILTER:
            state = {
                ...state,
                filters: action.payload,
            }
            break;
        case actionTypes.UPDATE_REDUCER:
            state = {
                ...state,
                [action.payload.item]: action.payload.data,
            }
            break;
        default:
            state = { ...state };
            break;

    }
    ;
    return state;
}

export default reducer;