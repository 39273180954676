import React, { useEffect, useState } from 'react';
import classes from "./styles.module.scss";
import { Box, Button, ClickAwayListener, Grid, IconButton, Tooltip } from '@mui/material';
import CheckBoxText from '../checkBoxText';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { useDispatch, useSelector } from 'react-redux';
import { onFetchSubjectPortion, onPublicDataLoad } from '../../store/publicData/actions';
import { useGetDropdownMutation } from '../../store/common/commonApiSlice';
import { isUndefined } from '../../shared/isNullEmptyUndefined';
import { useGetBoardingStepsByTypeQuery, useGetBoardingStepsQuery } from '../../pages/onBoardingAdmin/onBoardingAdminApiSlice';
import MultiselectReactDropdown from '../multiselectReactDropdown';
import { useGetCounselorListQuery } from '../../pages/counsellorAdminPage/counsellorAdminApiSlice';
import { CounsellingTypeList } from '../../constants/CounsellingTypeList';
import PrimaryButton from '../buttons';
import MultiselectBasicDropdown from './MultiSelectBasic';
import useUserRole from '../../hooks/useUserRole';
import useAdminSubRole from '../../hooks/useAdminSubRole';

const Filter = ({ close, open, reset, filterState, updateRecordFilter, handleApplyFilter, questionData }) => {


    const selectedCount = filterState?.cId?.length || 0;
    const placeholderText = selectedCount > 0 ? `${selectedCount} Selected` : "Select Counselor";

    const adminSubRole = useAdminSubRole();
    const { data: counselorListData, isFetching: counselorListIsFetching } = useGetCounselorListQuery({
        page: 1,
        items: 100000,
    },
        { skip: isUndefined(filterState?.cId) }
    )

    const getDropdownPayload = (type, id = [], orderBy = {}) => {
        switch (type) {
            case 'courses':
                return { dropdown_code: 'courses' };
            case 'sessions':
                return {
                    dropdown_code: 'sessions',
                };
            case 'syllabuses':
                return {
                    dropdown_code: 'syllabuses',
                    courseId: id.length > 0 ? id : [filterState.courseId] || undefined,
                };
            case 'groups':
                return {
                    dropdown_code: 'groups',
                    syllabusId: id.length > 0 ? id : [filterState.syllabusId] || undefined,
                }
            case 'subjects':
                return {
                    dropdown_code: 'subjects',
                    groupId: id.length > 0 ? id : [filterState.groupId] || undefined,
                }
            case 'portions':
                return {
                    dropdown_code: 'portions',
                }
            default:
                return { dropdown_code: type };
        }
    };

    const [dropdownData, setDropdownData] = useState({
        courses: [],
        sessions: [],
        syllabuses: [],
        groups: [],
        subjects: [],
        portions: [],
        boardingSteps: [],
        statuses: [
            { id: 'New', name: "New" },
            { id: 'Hold', name: "Hold" },
            { id: 'Rejected', name: "Rejected" }
        ],
        counselors: counselorListData?.rows,
        counsellingTypeList: CounsellingTypeList
    });

    useEffect(() => {
        setDropdownData(prevData => ({
            ...prevData,
            counselors: counselorListData?.rows || []
        }));
    }, [counselorListData])


    const [getDropdown, { data, error, isLoading }] = useGetDropdownMutation();

    // const { data: boardingStepsData, error: boardingStepsError, isLoading: boardingStepsLoading, isFetching: boardingStepsFetching } = useGetBoardingStepsQuery(undefined, {
    //     skip: isUndefined(filterState?.boardingStepId),
    // });
    const { data: boardingStepsData, error: boardingStepsError, isLoading: boardingStepsLoading, isFetching: boardingStepsFetching } = useGetBoardingStepsByTypeQuery(adminSubRole, {
        skip: isUndefined(filterState?.boardingStepId),
    });


    useEffect(() => {
        const fetchDropdownData = async () => {

            try {
                if (!dropdownData?.courses.length) {
                    const coursesPayload = getDropdownPayload("courses");
                    const coursesData = await getDropdown(coursesPayload).unwrap();
                    setDropdownData(prevData => ({
                        ...prevData,
                        courses: coursesData?.data || []
                    }));
                }

                if (filterState?.courseId) {
                    const syllabusesPayload = getDropdownPayload("syllabuses", [filterState.courseId]);
                    const syllabusesData = await getDropdown(syllabusesPayload).unwrap();


                    setDropdownData(prevData => ({
                        ...prevData,
                        syllabuses: syllabusesData?.data || [],

                    }));
                }

                if (!isUndefined(filterState?.sessionId)) {
                    const sessionsPayload = getDropdownPayload("sessions");
                    const sessionsData = await getDropdown(sessionsPayload).unwrap();

                    setDropdownData(prevData => ({
                        ...prevData,
                        sessions: sessionsData?.data || []
                    }));
                }

                if (filterState?.syllabusId) {
                    const groupsPayload = getDropdownPayload("groups", [filterState.syllabusId]);
                    const groupsData = await getDropdown(groupsPayload).unwrap();

                    setDropdownData(prevData => ({
                        ...prevData,
                        groups: groupsData?.data || []
                    }));
                }

                if (filterState?.groupId) {
                    const subjectsPayload = getDropdownPayload("subjects", [filterState.groupId]);
                    const subjectData = await getDropdown(subjectsPayload).unwrap();

                    setDropdownData(prevData => ({
                        ...prevData,
                        subjects: subjectData?.data || []
                    }));
                }

                if (!isUndefined(filterState?.portionId)) {
                    const portionsPayload = getDropdownPayload("portions", [filterState.portionId]);
                    const portionsData = await getDropdown(portionsPayload).unwrap();

                    setDropdownData(prevData => ({
                        ...prevData,
                        portions: portionsData?.data || []
                    }));
                }

                if (!isUndefined(filterState?.boardingStepId)) {
                    setDropdownData(prevData => ({
                        ...prevData,
                        boardingSteps: boardingStepsData || []
                    }));
                }
            } catch (err) {
                console.error("Failed to fetch dropdown data: ", err);
            }
        };

        fetchDropdownData();
    }, [filterState, getDropdown]);


    const renderOptions = (data) => {
        if (data.length === 0) {
            return ""
        }
        return data?.map((item) => (
            <option key={item.id} value={item.id}>
                {item.name}
            </option>
        ));
    };

    const renderQuestionData = (data) => {
        if (data.length === 0) {
            return ""
        }
        return data?.options?.map((item) => (
            <option key={data.id} value={data.id}>
                {item}
            </option>
        ));
    };

    const renderBoardingStepOptions = (data) => {
        if (data?.length === 0) {
            return ""
        }
        return data?.map((item) => (
            <option key={item.id} value={item.id}>
                {item.description}
            </option>
        ));
    };


    const [selectedValues, setSelectedValues] = useState([]);

    const handleSelect = (selectedList) => {
        const selectedIds = selectedList.map(item => item.id);
        setSelectedValues(selectedIds);
        updateRecordFilter(selectedIds, 'cId');
    };

    const handleRemove = (selectedList) => {
        const selectedIds = selectedList.map(item => item.id);
        setSelectedValues(selectedIds);
        updateRecordFilter(selectedIds, 'cId');
    };

    const [flags, setFlags] = useState([])

    const updateFlags = (questionId, options) => {
        setFlags(prevFlags => {
            const existingFlag = prevFlags.find(flag => flag.questionId === questionId);
            if (existingFlag) {
                return prevFlags.map(flag =>
                    flag.questionId === questionId
                        ? { ...flag, options }
                        : flag
                );
            } else {
                return [...prevFlags, { questionId, options }];
            }
        });
    };

    const renderQuestionField = (question) => {
        const currentFlag = flags.find(flag => flag.questionId === question.id);
        const currentOptions = currentFlag ? currentFlag.options : [];

        switch (question.questionType) {
            case 'TEXT-DUMMY':
                return (
                    <>
                        <label htmlFor={question.id}>{question.question}</label>
                        <input
                            type="text"
                            id={question.id}
                            value={filterState[question.id] || ''}
                            onChange={(e) => {
                                const value = e.target.value;
                                updateFlags(question.id, [value]);
                            }}
                            className={classes.select}
                        />
                    </>
                );
            case 'MULTIPLE_CHOICE':
                return (
                    <>
                        <label htmlFor={question.id}>{question.question}</label>
                        <MultiselectBasicDropdown
                            options={question.options}
                            onSelect={(selectedList) => {
                                const selectedValues = selectedList.map(option => option.name);
                                updateFlags(question.id, selectedValues);
                            }}
                            onRemove={(selectedList) => {
                                const selectedValues = selectedList.map(option => option.name);
                                updateFlags(question.id, selectedValues);
                            }}
                            value={currentOptions}
                            onSearch={() => { }}
                            placeholder={currentOptions[0] || question.question}
                            isMulti={false}
                        />
                    </>
                );
            case 'LIST':
            case 'CHECKBOX':
                return (
                    <>
                        <label htmlFor={question.id}>{question.question}</label>

                        <MultiselectBasicDropdown
                            options={question.options}
                            onSelect={(selectedList) => {
                                const selectedValues = selectedList.map(option => option.name);
                                updateFlags(question.id, selectedValues);
                            }}
                            onRemove={(selectedList) => {
                                const selectedValues = selectedList.map(option => option.name);
                                updateFlags(question.id, selectedValues);
                            }}
                            value={currentOptions}
                            onSearch={() => { }}
                            placeholder={question.question}
                            isMulti={true}
                        />
                    </>
                );
            default:
                return null;
        }
    };


    return (
        <Box className={`${classes.filters_container} ${open ? classes.open : ""}`} style={{ paddingBottom: "100px" }}>
            <div className={classes.head_container}>
                <div className={classes.head_container_item}>
                    <div className={classes.title}>Filter</div>
                </div>
                <Tooltip title="Clear Filters">
                    <IconButton
                        className={classes.clear}
                        onClick={() => {
                            reset();
                            setFlags([]);
                        }}
                    >

                        <FilterAltOffIcon />
                    </IconButton>
                </Tooltip>
                <IconButton onClick={close} aria-label="cancel" color="primary">
                    <CancelIcon style={{ color: '#9096AC' }} />
                </IconButton>
            </div>
            <hr />
            <div className={classes.items}>

                <div className={classes.filter}>

                    <Grid container spacing={2}>
                        {!isUndefined(filterState?.available) && <Grid item xs={12} sm={12}>
                            <span>
                                <label htmlFor="available">Availability</label>
                                <select
                                    id="available"
                                    className={classes.select}
                                    value={filterState?.available}
                                    onChange={e => updateRecordFilter(e.target.value, "available")}
                                >
                                    <option value="true">Available</option>
                                    <option value="false">Booked</option>
                                </select>
                            </span>
                        </Grid>}

                        {!isUndefined(filterState?.cId) && (
                            <Grid item xs={12} sm={12}>
                                <span>
                                    <label htmlFor="available">Select Counselor</label>
                                    <MultiselectReactDropdown
                                        options={dropdownData.counselors}
                                        onSelect={handleSelect}
                                        onRemove={handleRemove}
                                        value={filterState?.cId || []}
                                        onSearch={() => { }}
                                        // placeholder="Select Counselor"
                                        placeholder={placeholderText}

                                    />
                                </span>
                            </Grid>
                        )}

                        {!isUndefined(filterState?.counsellingType) && <Grid item xs={12} sm={12}>
                            <span>
                                <label htmlFor="courseId">Counseling Type</label>
                                <select
                                    id="counsellingType"
                                    className={classes.select}
                                    value={filterState?.counsellingType}
                                    onChange={e => updateRecordFilter(e.target.value, "counsellingType")}
                                >
                                    <option value="">Select Counseling Type</option>
                                    {renderOptions(dropdownData.counsellingTypeList)}
                                </ select>
                            </span>
                        </Grid>
                        }


                        {!isUndefined(filterState?.courseId) && <Grid item xs={12} sm={12}>
                            <span>
                                <label htmlFor="courseId">Course</label>
                                <select
                                    id="courseId"
                                    className={classes.select}
                                    value={filterState?.courseId}
                                    onChange={e => updateRecordFilter(e.target.value, "courseId")}
                                >
                                    <option value="">Select Course</option>
                                    {renderOptions(dropdownData.courses)}
                                </ select>
                            </span>
                        </Grid>
                        }
                        {!isUndefined(filterState?.syllabusId) && <Grid item xs={12} sm={12}>
                            <span>
                                <label htmlFor="syllabusId">Syllabus</label>
                                <select
                                    id="syllabusId"
                                    className={classes.select}
                                    value={filterState?.syllabusId}
                                    onChange={e => updateRecordFilter(e.target.value, "syllabusId")}
                                >
                                    <option value="">Select Syllabus</option>
                                    {renderOptions(dropdownData.syllabuses)}
                                </ select>
                            </span>
                        </Grid>}
                        {!isUndefined(filterState?.sessionId) && <Grid item xs={12} sm={12}>
                            <span>
                                <label htmlFor="sessionId">Session</label>
                                <select
                                    id="sessionId"
                                    className={classes.select}
                                    value={filterState?.sessionId}
                                    onChange={e => updateRecordFilter(e.target.value, "sessionId")}

                                >
                                    <option value="">Select Session</option>
                                    {renderOptions(dropdownData.sessions)}
                                </ select>
                            </span>
                        </Grid>}

                        {!isUndefined(filterState?.syllabusId) && <Grid item xs={12} sm={12}>
                            <span>
                                <label htmlFor="groupId">Group</label>
                                <select
                                    id="groupId"
                                    className={classes.select}
                                    value={filterState?.groupId}
                                    onChange={e => updateRecordFilter(e.target.value, "groupId")}

                                >
                                    <option value="">Select Group</option>
                                    {/* {
                                                props?.publicData?.subjectDetails?.map((o, i) => (
                                                    o.Subjects?.map((a, ia) => (
                                                        <option key={i} value={a.id}>{a.name}</option>
                                                    ))
                                                ))
                                            } */}
                                    {renderOptions(dropdownData.groups)}
                                </ select>
                            </span>
                        </Grid>}

                        {!isUndefined(filterState?.syllabusId) && <Grid item xs={12} sm={12}>
                            <span>
                                <label htmlFor="subjectId">Subject</label>
                                <select
                                    id="subjectId"
                                    className={classes.select}
                                    value={filterState?.subjectId}
                                    onChange={e => updateRecordFilter(e.target.value, "subjectId")}

                                >
                                    <option value="">Select Subject</option>
                                    {renderOptions(dropdownData.subjects)}
                                </ select>
                            </span>
                        </Grid>}
                        {!isUndefined(filterState?.portionId) && <Grid item xs={12} sm={12}>
                            <span>
                                <label htmlFor="portionId">Portion</label>
                                <select
                                    id="portionId"
                                    className={classes.select}
                                    value={filterState?.portionId}
                                    onChange={e => updateRecordFilter(e.target.value, "portionId")}

                                >
                                    <option value="">Select Portion</option>
                                    {renderOptions(dropdownData.portions)}
                                </ select>
                            </span>
                        </Grid>}

                        {!isUndefined(filterState?.boardingStepId) && (
                            <Grid item xs={12} sm={12}>
                                <span>
                                    <label htmlFor="boardingStepId">Boarding Step</label>
                                    <select
                                        id="boardingStepId"
                                        className={classes.select}
                                        value={filterState?.boardingStepId}
                                        onChange={e => updateRecordFilter(e.target.value, "boardingStepId")}
                                    >
                                        <option value="">Select Boarding Step</option>
                                        {renderBoardingStepOptions(boardingStepsData)}
                                    </select>
                                </span>
                            </Grid>
                        )}

                        {/* {!isUndefined(filterState?.type) && (
                            <Grid item xs={12} sm={12}>
                                <span>
                                    <label htmlFor="type">Type</label>
                                    <select
                                        id="type"
                                        className={classes.select}
                                        value={filterState?.type}
                                        onChange={e => updateRecordFilter(e.target.value, "type")}
                                    >
                                        <option value="">Select Type</option>
                                    </select>
                                </span>
                            </Grid>
                        )} */}

                        {!isUndefined(filterState?.status) && (
                            <Grid item xs={12} sm={12}>
                                <span>
                                    <label htmlFor="status">Status</label>
                                    <select
                                        id="status"
                                        className={classes.select}
                                        value={filterState?.status}
                                        onChange={e => updateRecordFilter(e.target.value, "status")}
                                    >
                                        <option value="">Select Status</option>
                                        {renderOptions(dropdownData?.statuses)}
                                    </select>
                                </span>
                            </Grid>
                        )}

                        {questionData?.filter((q) => q?.options?.length > 0).map((question) => (
                            <Grid item xs={12} sm={12} key={question.id}>
                                <span className="abc">
                                    {renderQuestionField(question)}
                                </span>
                            </Grid>
                        ))}
                    </Grid>
                </div>
                {handleApplyFilter && <PrimaryButton onClick={() => handleApplyFilter(flags)} width={"100%"} backgroundColor={"blue"} buttonText={"Apply Filter"} />}


            </div>
        </Box>
    );
};

export default Filter;
