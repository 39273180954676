import { Link } from 'react-router-dom';
import classes from "./styles.module.scss";

const StyledButton = ({ onClick, to, bgcolor, children, btnText, className, padding, width }) => {

    // "#005CA8" blue color hex 
    
    const buttonStyle = {
        backgroundColor: bgcolor || 'rgb(60, 90, 20)',
        color: '#fff',
        padding: padding || '14px 56px',
        borderRadius: '4px',
        border: 'none',
        fontSize: '16px',
        textAlign: 'center',
        display: 'inline-block',
        cursor: 'pointer',
        width:width

    };

    if (to) {
        return (
            <div className={classes.btnContainer}>
                <Link to={to} style={buttonStyle}>{btnText}</Link>
            </div>
        );
    } else {
        return (
            <div className={classes.btnContainer}>
                <button onClick={onClick} style={buttonStyle}>{btnText}</button>
            </div>
        );
    }
}


export default StyledButton;
