import { createApi } from '@reduxjs/toolkit/query/react';
import * as API from '../../apiCalls/urls/counsellor';
import { baseQuery } from '../../apiCalls/rtkBaseQuery';



export const counsellorApi = createApi({
  baseQuery,
  reducerPath: 'counsellorApi',
  tagTypes: ['counselling', 'profile', 'availability', 'certificate', 'mapping', 'counsellingCount'],
  endpoints: (builder) => ({
    getCounsellingList: builder.query({
      query: ({ page = 1, items = 10, filters }) => ({
        url: `${API.COUNSELLOR_LIST}?page=${page}&items=${items}`,
        method: 'POST',
        body: filters || {}
      }),
      providesTags: (result, error, { page, items, filters }) => [
        { type: 'counselling', page, items, filters },
      ],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0
    }),

    getCounsellorProfile: builder.query({
      query: () => ({
        url: API.COUNSELLOR_PROFILE,
        method: 'GET',
      }),
      providesTags: ['profile'],
      keepUnusedDataFor: 0
    }),

    getCounsellorAvailability: builder.query({
      query: () => ({
        url: API.COUNSELLOR_AVAILABILITY,
        method: 'POST',
      }),
      providesTags: ['availability'],
      keepUnusedDataFor: 0
    }),
    getCounselingDetails: builder.query({
      query: (id) => ({
        url: `${API.GET_COUNSELING_DETAILS}${id}`,
        method: 'GET',
      }),
    }),

    getCertificatesLists: builder.query({
      query: () => ({
        url: API.GET_CERTIFICATE_LIST,
        method: 'GET',
      }),
      providesTags: ['certificate'],


    }),
    getMappingLists: builder.query({
      query: ({ sessionId }) => ({
        url: API.GET_MAPPING_LIST,
        method: 'GET',
        params: sessionId !== null && sessionId !== undefined ? { sessionId } : {},
      }),
      providesTags: ['mapping'],
    }),
    getCertificatesLink: builder.mutation({
      query: (certificate) => ({
        url: API.GET_CERTIFICATE_LINK,
        method: 'POST',
        body: certificate
      }),
    }),

    updateSocialLink: builder.mutation({
      query: (linkData) => ({
        url: API.UPDATE_SOCIAL_LINK,
        method: 'POST',
        body: linkData,
      }),
      invalidatesTags: ['profile'],
    }),

    updateCounselorProfile: builder.mutation({
      query: (profileData) => ({
        url: API.UPDATE_COUNSELOR_PROFILE,
        method: 'POST',
        body: profileData,
      }),
      invalidatesTags: ['profile'],
    }),

    addAvailability: builder.mutation({
      query: (data) => ({
        url: API.ADD_AVAILABILITY,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['availability'],
    }),

    addCertificate: builder.mutation({
      query: (certificateData) => ({
        url: API.ADD_CERTIFICATE,
        method: 'POST',
        body: certificateData,
      }),
      invalidatesTags: ['certificate'],
    }),

    addEducation: builder.mutation({
      query: (educationData) => ({
        url: API.ADD_EDUCATION,
        method: 'POST',
        body: educationData,
      }),
      invalidatesTags: ['profile'],
    }),

    updateEducation: builder.mutation({
      query: (data) => ({
        url: API.UPDATE_EDUCATION,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['profile'],
    }),

    deleteEducation: builder.mutation({
      query: (educationId) => ({
        url: API.DELETE_EDUCATION,
        method: 'DELETE',
        body: educationId
      }),
      invalidatesTags: ['profile'],
    }),

    addExperiance: builder.mutation({
      query: (data) => ({
        url: API.ADD_EXPERIENCE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['profile'],
    }),

    updateExperiance: builder.mutation({
      query: (data) => ({
        url: API.UPDATE_EXPERIENCE,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['profile'],
    }),

    deleteExperiance: builder.mutation({
      query: (expId) => ({
        url: API.DELETE_EXPERIENCE,
        method: 'DELETE',
        body: expId
      }),
      invalidatesTags: ['profile'],
    }),

    updateCertificate: builder.mutation({
      query: (newData) => ({
        url: API.UPDATE_CERTIFICATE,
        method: 'PUT',
        body: newData,
      }),
      invalidatesTags: ['certificate'],
    }),

    deleteCertificate: builder.mutation({
      query: (certificateId) => ({
        url: API.DELETE_CERTIFICATE,
        method: 'DELETE',
        body: certificateId
      }),
      invalidatesTags: ['certificate'],
    }),



    rejectCounseling: builder.mutation({
      query: (counselingId) => ({
        url: API.REJECT_COUNSELING + counselingId,
        method: 'POST',
      }),
      invalidatesTags: ['counselling', "counsellingCount"],
    }),

    acceptCounseling: builder.mutation({
      query: (counselingId) => ({
        url: API.ACCEPT_COUNSELING + counselingId,
        method: 'POST',
      }),
      invalidatesTags: ['counselling', "counsellingCount"],
    }),

    rescheduleCounseling: builder.mutation({
      query: ({ data }) => ({
        url: API.RESCHEDULE_COUNSELING,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['counselling', "counsellingCount"],
    }),
    cancelCounseling: builder.mutation({
      query: ({ id, data }) => ({
        url: API.CANCEL_COUNSELING + id,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['counselling', "counsellingCount"],
    }),

    updateAvailability: builder.mutation({
      query: (availabilityData) => ({
        url: API.UPDATE_AVAILABILITY,
        method: 'POST',
        body: availabilityData,
      }),
    }),
    addIntroductoryVideo: builder.mutation({
      query: (file) => ({
        url: API.ADD_INTRODUCTORY_VIDEO,
        method: 'POST',
        body: file,
      }),
      invalidatesTags: ['profile'],
    }),
    deleteIntroductoryVideo: builder.mutation({
      query: (expId) => ({
        url: API.DELETE_INTRODUCTORY_VIDEO,
        method: 'DELETE',
        body: expId
      }),
      invalidatesTags: ['profile'],
    }),

    getCounsellingCount: builder.query({
      query: () => ({
        url: API.GET_COUNSELLOR_COUNT,
        method: 'GET',
      }),
      providesTags: ['counsellingCount'],
      keepUnusedDataFor: 0
    }),

    getDashboard: builder.query({
      query: (filter) => ({
        url: API.GET_DASHBOARD,
        method: 'POST',
        body: filter
      }),
      keepUnusedDataFor: 0
    }),
    getStudentList: builder.query({
      query: ({ page = 1, items = 10, filters }) => ({
        url: API.GET_STUDENT_LIST,
        method: 'POST',
        body: filters
      }),
      keepUnusedDataFor: 0
    }),
    getStudentCounsellingListByOrderId: builder.query({
      query: ({ page = 1, items = 10, filters, orderId }) => ({
        url: API.GET_STUDENT_COUNSELING_LIST_BY_ORDERID + "/" + orderId + `?page=${page}&items=${items}`,
        method: 'POST',
        body: filters
      }),
      keepUnusedDataFor: 0
    }),
    getStudentPendingCounsellingCountOrderId: builder.query({
      query: ({ page = 1, items = 10, filters, orderId }) => ({
        url: API.GET_STUDENT_COUNSELING_LIST_COUNT_BY_ORDERID + "/" + orderId + `?page=${page}&items=${items}`,
        method: 'POST',
        body: filters
      }),
      keepUnusedDataFor: 0
    }),
  }),
});

export const {
  useGetCounsellingListQuery,
  useGetCounsellorProfileQuery,
  useGetCounsellorAvailabilityQuery,
  useAddAvailabilityMutation,
  useUpdateSocialLinkMutation,
  useUpdateCounselorProfileMutation,
  useGetCertificatesLinkMutation,
  useAddCertificateMutation,
  useAddEducationMutation,
  useUpdateEducationMutation,
  useDeleteEducationMutation,
  useUpdateCertificateMutation,
  useDeleteCertificateMutation,
  useRejectCounselingMutation,
  useAcceptCounselingMutation,
  useRescheduleCounselingMutation,
  useUpdateAvailabilityMutation,
  useAddIntroductoryVideoMutation,
  useGetCertificatesListsQuery,
  useGetCounselingDetailsQuery,
  useGetMappingListsQuery,
  useDeleteExperianceMutation,
  useAddExperianceMutation,
  useUpdateExperianceMutation,
  useDeleteIntroductoryVideoMutation,
  useGetCounsellingCountQuery,
  useCancelCounselingMutation,
  useGetDashboardQuery,
  useGetStudentListQuery,
  useGetStudentCounsellingListByOrderIdQuery,
  useGetStudentPendingCounsellingCountOrderIdQuery
} = counsellorApi;
